export class FolhaMensalistaFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getFolhasMensalista(filter) {
    return this.instance.get('/v1/folha-pagamento', {
      params: filter || null,
    });
  }

  getFolhaMensalista(id) {
    return this.instance.get(`/v1/folha-pagamento/${id}`);
  }

  insertFolha(data) {
    return this.instance.post(`/v1/folha-pagamento/`, data);
  }

  editFolha(id, data) {
    return this.instance.put(`/v1/folha-pagamento/${id}`, data);
  }

  removeFolha(id) {
    return this.instance.delete(`/v1/folha-pagamento/${id}`);
  }

  abrirFolhaMensalista(id) {
    return this.instance.patch(`/v1/folha-pagamento/abrir/${id}`);
  }

  fecharFolhaMensalista(id, data) {
    return this.instance.put(`/v1/folha-pagamento/trancar/${id}`, data);
  }

  getFolhaDetails(id, filter) {
    return this.instance.get(`/v1/colaborador-pagamentos/${id}`, {
      params: filter || null,
    });
  }

  setPagamentoMensalista(idFolha, idColaborador, data) {
    return this.instance.post(
      `/v1/colaborador-pagamentos/${idFolha}/${idColaborador}`,
      data
    );
  }

  editFolhaDetail(id, data, colaborador) {
    return this.instance.put(
      `/v1/colaborador-pagamentos/${id}`,
      { valor: data.valor },
      {
        params:
          {
            colaborador,
          } || null,
      }
    );
  }
}

export default (...params) => new FolhaMensalistaFactory(...params);
