import React, { useState, useContext, createContext } from 'react';

const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
  const [expandedItem, setExpandedItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedSubItem, setSelectedSubItem] = useState(0);

  const [openMenu, setOpenMenu] = useState(true);

  const handleMenu = () => {
    setOpenMenu((old) => !old);
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };

  return (
    <MenuContext.Provider
      value={{
        openMenu,
        handleMenu,
        closeMenu,
        setOpenMenu,
        setExpandedItem,
        expandedItem,
        setSelectedItem,
        selectedItem,
        setSelectedSubItem,
        selectedSubItem,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export function useMenu() {
  const context = useContext(MenuContext);

  if (!context) throw new Error('useMenu must be used within an ToastProvider');

  return context;
}
