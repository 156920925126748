import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { useNewToast } from 'hooks/newToast';
import { appWarnings } from 'utils/appWarnings';

const RegInsumosContext = createContext({});

const success = {
  type: 'success',
};

export const RegInsumosProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [regInsumos, setRegInsumos] = useState([]);
  const [regInsumosLoading, setRegInsumosLoading] = useState(false);
  const [regInsumo, setRegInsumo] = useState({});
  const [regInsumoLoading, setRegInsumoLoading] = useState(false);
  const [isRemoveRegInsumo, setIsRemoveRegInsumo] = useState(false);

  const getRegInsumos = useCallback(async (activityId) => {
    try {
      setRegInsumosLoading(true);
      const response = await api.regAtividade().getRegInsumos(activityId);
      setRegInsumos(response);
    } catch (errors) {
    } finally {
      setRegInsumosLoading(false);
    }
  }, []);

  const getRegInsumo = useCallback(async (registroId, id) => {
    try {
      setRegInsumoLoading(true);
      const response = await api.regAtividade().getRegInsumo(registroId, id);
      setRegInsumo(response);
      setRegInsumoLoading(false);
    } catch (errors) {
      setRegInsumoLoading(false);
    }
  }, []);

  const insertRegInsumos = useCallback(
    async (registroId, data) => {
      try {
        setRegInsumoLoading(true);
        const formattedData = {
          insumos: data?.map((item) => ({
            id: item?.inputId,
            insumo: item?.insumo?.id,
            qtd: item?.quantidade,
          })),
        };
        await api.regAtividade().insertRegInsumos(registroId, formattedData);
        getRegInsumos(registroId);
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setRegInsumoLoading(false);
      }
    },
    [showToast, getRegInsumos],
  );

  const removeRegInsumo = useCallback(
    async (activityId, inputId) => {
      try {
        setIsRemoveRegInsumo(true);
        await api.regAtividade().removeRegInsumo(activityId, inputId);
        showToast({
          ...success,
          message: 'Aplicação de insumo excluída com sucesso!',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setIsRemoveRegInsumo(false);
      }
    },
    [showToast],
  );

  return (
    <RegInsumosContext.Provider
      value={{
        getRegInsumos,
        regInsumos,
        regInsumosLoading,
        regInsumo,
        regInsumoLoading,
        getRegInsumo,
        removeRegInsumo,
        isRemoveRegInsumo,
        insertRegInsumos,
        setRegInsumoLoading,
        setRegInsumos,
      }}
    >
      {children}
    </RegInsumosContext.Provider>
  );
};

export function useRegInsumo() {
  const context = useContext(RegInsumosContext);

  if (!context)
    throw new Error('useRegAtividade must be used within an RegAtividades');

  return context;
}
