import React, { useCallback, useState, useContext, createContext } from 'react';
import api from 'services';
import { useNewToast } from 'hooks/newToast';
import { appWarnings } from 'utils/appWarnings';

const RegMdobrasContext = createContext({});

const success = {
  type: 'success',
};

export const RegMdobrasProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [regMdobras, setRegMdobras] = useState([]);
  const [regMdobrasLoading, setRegMdobrasLoading] = useState(false);
  const [regMdobra, setRegMdobra] = useState({});
  const [regMdobraLoading, setRegMdobraLoading] = useState(false);
  const [isRemoveRegMdobra, setIsRemoveRegMdobra] = useState(false);

  const getRegMdobras = useCallback(async (registroId) => {
    try {
      setRegMdobrasLoading(true);
      const response = await api.regAtividade().getRegMdobras(registroId);

      setRegMdobras(response);
      setRegMdobrasLoading(false);
    } catch (errors) {
      setRegMdobrasLoading(false);
    }
  }, []);

  const getRegMdobra = useCallback(async (registroId, id) => {
    try {
      setRegMdobraLoading(true);
      const response = await api.regAtividade().getRegMdobra(registroId, id);

      setRegMdobra(response);
      setRegMdobraLoading(false);
    } catch (errors) {
      setRegMdobraLoading(false);
    }
  }, []);

  const insertRegMaodeobra = useCallback(
    async (registroId, data) => {
      try {
        setRegMdobraLoading(true);
        const formattedData = {
          colaboradores: data?.map((item) => ({
            id: item?.laborId,
            gloVinculoId: item?.collaboratorId,
            tipo: item?.medicao?.id,
            quantidade: item?.quantidade,
            minutos: item?.minutos,
            valor: item?.valor,
            extra: item?.quantidade_extra,
            valorExtra: item?.valor_extra,
          })),
        };
        const response = await api
          .regAtividade()
          .insertRegMdobra(registroId, formattedData);
        setRegMdobra(response);
        getRegMdobras(registroId);
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setRegMdobraLoading(false);
      }
    },
    [showToast, getRegMdobras],
  );

  const removeRegMdobra = useCallback(
    async (registro, id) => {
      try {
        setIsRemoveRegMdobra(true);
        await api.regAtividade().removeRegMdobra(registro, id);
        if (id) {
          showToast({
            ...success,
            message: 'Colaborador excluído com sucesso!',
          });
        } else {
          showToast({
            ...success,
            message: 'Custo de mão de obra excluído com sucesso!',
          });
        }
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setIsRemoveRegMdobra(false);
      }
    },
    [showToast],
  );

  return (
    <RegMdobrasContext.Provider
      value={{
        getRegMdobras,
        regMdobras,
        regMdobrasLoading,
        regMdobra,
        regMdobraLoading,
        getRegMdobra,
        removeRegMdobra,
        insertRegMaodeobra,
        setRegMdobraLoading,
        isRemoveRegMdobra,
        setRegMdobras,
      }}
    >
      {children}
    </RegMdobrasContext.Provider>
  );
};

export function useRegMdobra() {
  const context = useContext(RegMdobrasContext);

  if (!context)
    throw new Error('useRegMdobra must be used within an useRegMdobra');

  return context;
}
