import { useNewToast } from 'hooks/newToast';
import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { appWarnings } from 'utils/appWarnings';

const FluxoContext = createContext({});

const MOSTRAR_SALDO = {
  saldo_normal: 0,
  saldo_movimentos: 0,
  saldo_transferencias: 0,
  total: 0,
};

export const FluxoProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [fluxo, setFluxo] = useState([]);
  const [fluxos, setFluxos] = useState([]);
  const [mostrarSaldo, setMostrarSaldo] = useState(MOSTRAR_SALDO);
  const [mostrarSaldoLoading, setMostrarSaldoLoading] = useState(false);
  const [movimentacoes, setMovimentacoes] = React.useState([]);
  const [fluxoLoading, setFluxoLoading] = React.useState(false);
  const [fluxosLoading, setFluxosLoading] = React.useState(false);
  const [addFluxoLoading, setAddFluxoLoading] = React.useState(false);
  const [editFluxoLoading, setEditFluxoLoading] = React.useState(false);
  const [deleteFluxoLoading, setDeleteFluxoLoading] = React.useState(false);
  const [movimentacoesLoading, setMovimentacoesLoading] = React.useState(false);

  const [movimentacao, setMovimentacao] = React.useState();
  const [createMovimentacaoLoading, setCreateMovimentacaoLoading] = React.useState(false);
  const [updateMovimentacaoLoading, setUpdateMovimentacaoLoading] = React.useState(false);
  const [removeMovimentacaoLoading, setRemoveMovimentacaoLoading] = React.useState(false);
  const [showMovimentacaoLoading, setShowMovimentacaoLoading] = React.useState(false);
  const [updateStatusPagamentoLoading, setUpdateStatusPagamentoLoading] = useState(false);

  const getFluxos = useCallback(async filter => {
    try {
      setFluxosLoading(true);
      const response = await api.fluxo().getFluxos(filter);
      setFluxos(response);
      setFluxosLoading(false);
    } catch (errors) {
      setFluxosLoading(false);
    }
  }, []);

  const getFluxo = useCallback(async id => {
    try {
      setFluxoLoading(true);
      const response = await api.fluxo().getFluxo(id);
      setFluxo(response);
      setFluxoLoading(false);
    } catch (errors) {
      setFluxoLoading(false);
    }
  }, []);

  const addFluxo = useCallback(
    async data => {
      const { descricao, conta, agencia } = data;

      const newData = {
        descricao,
        conta,
        agencia,
      };

      try {
        setAddFluxoLoading(true);
        const response = await api.fluxo().addFluxo(newData);
        setFluxos(old => [...old, response]);
        setAddFluxoLoading(false);
        showToast({
          type: 'success',
          message: 'Fluxo adicionado com sucesso!',
        });
        return response;
      } catch (err) {
        showToast({
          type: 'error',
          message: 'Não foi possível adicionar o fluxo!',
        });
        setAddFluxoLoading(false);
        throw new Error(err);
      }
    },
    [showToast]
  );

  const editFluxo = useCallback(
    async (data, id) => {
      const { descricao, conta, agencia } = data;

      const newData = {
        descricao,
        conta,
        agencia,
      };
      try {
        setEditFluxoLoading(true);
        const response = await api.fluxo().editFluxo(newData, id);
        setFluxos(old => [...old.map(item => (item.id === id ? response : item))]);
        showToast({
          type: 'success',
          message: 'Fluxo editado com sucesso!',
        });
        setEditFluxoLoading(false);
        return response;
      } catch (err) {
        showToast({
          type: 'error',
          message: 'Não foi possível editar o fluxo!',
        });
        setEditFluxoLoading(false);
        throw new Error(err);
      }
    },
    [showToast]
  );

  const deleteFluxo = useCallback(
    async (id, fluxoToMove) => {
      try {
        setDeleteFluxoLoading(true);
        await api.fluxo().deleteFluxo({ id, fluxo_to_move: fluxoToMove });

        setFluxos(fluxos.filter(item => item.id !== id));
        setDeleteFluxoLoading(false);
        showToast({
          type: 'success',
          message: 'Fluxo excluído com sucesso!',
        });
      } catch (err) {
        showToast({
          type: 'error',
          message: 'Não foi possível excluir o fluxo!',
        });
        setDeleteFluxoLoading(false);
      }
    },
    [fluxos, showToast]
  );

  const getSaldosSoma = useCallback(async data => {
    try {
      setMostrarSaldoLoading(true);
      const response = await api.fluxo().mostrarSaldo(data);
      setMostrarSaldo(response);
      setTimeout(() => setMostrarSaldoLoading(null), 1000);
      return response;
    } catch (errors) {
      setMostrarSaldoLoading(null);
      setMostrarSaldoLoading(false);
    }
  }, []);

  const getMovimentacoes = useCallback(async id => {
    try {
      setMovimentacoesLoading(true);
      const response = await api.fluxo().getMovimentacoes(id);

      setMovimentacoes(response);
      setMovimentacoesLoading(false);
    } catch (errors) {
      setMovimentacoesLoading(false);
    }
  }, []);

  const createMovimentacao = useCallback(
    async (fluxo_id, data) => {
      try {
        const dados = {
          descricao: data.descricao,
          valor: data.valor,
          data_vencimento: data.data_vencimento,
          tipo: data?.tipo?.value,
        };

        setCreateMovimentacaoLoading(true);
        const response = await api.fluxo().createMovimentacao(fluxo_id, dados);
        setMovimentacao(response);
        setCreateMovimentacaoLoading(false);
        showToast({
          type: 'success',
          message: 'Movimentação adicionada com sucesso!',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error('');
      } finally {
        setCreateMovimentacaoLoading(false);
      }
    },
    [showToast]
  );

  const updateMovimentacao = useCallback(
    async (fluxo_id, id, data) => {
      try {
        const dados = {
          descricao: data.descricao,
          valor: data.valor,
          data_vencimento: data.data_vencimento,
          tipo: data?.tipo?.value,
        };

        setUpdateMovimentacaoLoading(true);
        const response = await api.fluxo().updateMovimentacao(fluxo_id, id, dados);
        setMovimentacao(response);
        setUpdateMovimentacaoLoading(false);
        showToast({
          type: 'success',
          message: 'Movimentação editada com sucesso!',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error('');
      } finally {
        setUpdateMovimentacaoLoading(false);
      }
    },
    [showToast]
  );

  const removeMovimentacao = useCallback(
    async (fluxo_id, id) => {
      try {
        setRemoveMovimentacaoLoading(true);
        await api.fluxo().removeMovimentacao(fluxo_id, id);
        setRemoveMovimentacaoLoading(false);
        showToast({
          type: 'success',
          message: 'Movimentação removida com sucesso!',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error('');
      } finally {
        setRemoveMovimentacaoLoading(false);
      }
    },
    [showToast]
  );

  const showMovimentacao = useCallback(
    async (fluxo_id, id) => {
      try {
        setShowMovimentacaoLoading(true);
        const response = await api.fluxo().showMovimentacao(fluxo_id, id);

        setMovimentacao(response);
        setShowMovimentacaoLoading(false);
      } catch (err) {
        setShowMovimentacaoLoading(false);
        showToast(appWarnings.Danger(err));
      }
    },
    [showToast]
  );

  const updateStatusPagamento = useCallback(
    async (fluxo_id, id, { status }) => {
      try {
        setUpdateStatusPagamentoLoading(true);
        await api.fluxo().updateStatusPagamento(fluxo_id, id, status);
      } catch (err) {
        showToast(appWarnings.Danger(err));
      } finally {
        setUpdateStatusPagamentoLoading(false);
      }
    },
    [showToast]
  );

  return (
    <FluxoContext.Provider
      value={{
        fluxos,
        getFluxos,
        fluxosLoading,
        mostrarSaldo,
        setMostrarSaldo,
        addFluxo,
        addFluxoLoading,
        deleteFluxo,
        mostrarSaldoLoading,
        deleteFluxoLoading,
        editFluxo,
        editFluxoLoading,
        getSaldosSoma,
        getMovimentacoes,
        movimentacoes,
        movimentacoesLoading,
        getFluxo,
        fluxo,
        fluxoLoading,
        createMovimentacao,
        movimentacao,
        createMovimentacaoLoading,
        updateMovimentacao,
        updateMovimentacaoLoading,
        removeMovimentacao,
        removeMovimentacaoLoading,
        showMovimentacao,
        showMovimentacaoLoading,
        updateStatusPagamento,
        updatePagamentoLoading: updateStatusPagamentoLoading,
      }}>
      {children}
    </FluxoContext.Provider>
  );
};

export function useFluxo() {
  const context = useContext(FluxoContext);

  if (!context) throw new Error('useFluxo must be used within an AuthProvider');

  return context;
}
