import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { useFazenda } from 'hooks/providers/cadastros/fazendas';
import { toDate } from 'utils/converters';
import { useNewToast } from 'hooks/newToast';
import { appWarnings } from 'utils/appWarnings';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';

const ReceitaDespesaContext = createContext({});

export const ReceitaDespesaProvider = ({ children }) => {
  const [receitasDespesas, setReceitasDespesas] = useState([]);
  const [receitaDespesa, setReceitaDespesa] = useState({});
  const [valoresFinanceiro, setValoresFinanceiro] = React.useState([]);

  const [receitasDespesasLoading, setReceitasDespesasLoading] = useState(false);
  const [receitaDespesaLoading, setReceitaDespesaLoading] =
    React.useState(false);
  const [updatePagamentoLoading, setUpdatePagamentoLoading] = useState(false);

  const [editReceitaDespesaLoading, setEditReceitaDespesaLoading] =
    React.useState(false);
  const [insertReceitaDespesaLoading, setInsertReceitaDespesaLoading] =
    useState(false);
  const [addPagamentoLoading, setAddPagamentoLoading] = useState(false);
  const [changeStatusPagamentoLoading, setChangeStatusPagamentoLoading] =
    React.useState(false);

  const [resumo, setResumo] = React.useState({});
  const [resumoItem, setResumoItem] = React.useState([]);
  const [resumoLoading, setResumoLoading] = React.useState([]);

  const [deleteReceitaDespesaLoading, setDeleteReceitaDespesaLoading] =
    React.useState(false);

  const [deletePagamentoLoading, setDeletePagamentoLoading] =
    React.useState(false);
  const [pagamentoItemLoading, setPagamentoItemLoading] = React.useState(false);
  const [pagamentoItem, setPagamentoItem] = React.useState({});

  const [resumoTresMeses, setResumoTresMeses] = React.useState([]);
  const [resumoTresMesesLoading, setResumoTresMesesLoading] =
    React.useState(false);

  const { showToast } = useNewToast();

  const { fazendaAtiva } = useFazenda();

  const getReceitasDespesas = useCallback(async (filter) => {
    try {
      setReceitasDespesasLoading(true);
      const response = await api.financeiro().getReceitasDespesas(filter);
      setValoresFinanceiro(response);
      setReceitasDespesas(response?.container);
      setReceitasDespesasLoading(false);
    } catch (_) {
      setReceitasDespesasLoading(false);
    }
  }, []);

  const getReceitaDespesa = useCallback(async (filter) => {
    try {
      setReceitaDespesaLoading(true);
      const response = await api.financeiro().getReceitaDespesa(filter);
      setReceitaDespesa(response);
      setReceitaDespesaLoading(false);
    } catch (errors) {
      setReceitaDespesaLoading(false);
    }
  }, []);

  const getPagamentoItem = useCallback(async (id) => {
    try {
      setPagamentoItemLoading(true);
      const response = await api.financeiro().getPagamentoItem(id);
      setPagamentoItem(response);
      setPagamentoItemLoading(false);
    } catch (_) {
      setPagamentoItemLoading(false);
    }
  }, []);

  const insertReceitaDespesa = useCallback(
    async (value, movi) => {
      try {
        setInsertReceitaDespesaLoading(true);
        const {
          descricao,
          categoria,
          status,
          valor,
          tipoPagamento,
          safra,
          fornecedor,
          notafiscal,
          data,
          juros,
          desconto,
          dataPagamento,
          boleto,
          obsCheque,
          fluxo,
          fluxoSaida,
          numeroCheque,
          pagParcelas,
        } = value.dados;

        const newData = {
          descricao,
          tipo: movi,
          categoria: categoria?.id,
          status,
          juros: juros,
          desconto: desconto,
          valor: valor,
          tipoPagamento,
          safra: safra?.id,
          fornecedor: fornecedor?.id,
          notafiscal: notafiscal,
          boleto,
          numeroCheque,
          data: toDate(data),
          dataPagamento: dataPagamento ? toDate(dataPagamento) : toDate(data),
          fluxo: fluxo?.id,
          fluxoSaida: fluxoSaida?.id,
          pagParcelas: Number(pagParcelas?.parcela),
          fazenda: fazendaAtiva?.id,
          obsCheque,
          files: value?.files,
          obs: value?.obs || '',
        };

        await api.financeiro().insertReceitaDespesa(newData);
        showToast({
          type: 'success',
          message: 'Lançamento adicionado com sucesso!',
        });
        setInsertReceitaDespesaLoading(false);
      } catch (err) {
        setInsertReceitaDespesaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [fazendaAtiva, showToast],
  );

  const editReceitaDespesa = useCallback(
    async (id, value) => {
      try {
        setEditReceitaDespesaLoading(true);
        const {
          tipo,
          categoria,
          safra,
          fornecedor,
          notafiscal,
          boleto,
          descricao,
        } = value.dados;

        const newData = {
          descricao,
          tipo: tipo?.id,
          categoria: categoria?.id,
          valor: 0.1,
          safra: safra?.id,
          fornecedor: fornecedor?.id,
          notafiscal: notafiscal,
          obs: value?.obs || '',
          files: value?.files,
          boleto,
        };
        await api.financeiro().editReceitaDespesa(id, newData);
        showToast({
          type: 'success',
          message: 'Lançamento editado com sucesso!',
        });
        setEditReceitaDespesaLoading(false);
      } catch (err) {
        setEditReceitaDespesaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast],
  );

  const updatePagamento = useCallback(
    async (idMovimentacao, value) => {
      try {
        setUpdatePagamentoLoading(true);
        const {
          fluxo,
          fluxoSaida,
          status,
          juros,
          desconto,
          valor,
          data,
          numeroCheque,
          notafiscal,
          dataPagamento,
          reajuste,
          checkCheque,
        } = value.pagamento;

        const newData = {
          fluxo: fluxo?.id || 0,
          fluxoSaida: fluxoSaida?.id || 0,
          status,
          juros: juros,
          desconto: desconto,
          valor: valor,
          notafiscal: notafiscal,
          data: toDate(data),
          file_id: value?.file_id,
          numeroCheque: checkCheque ? numeroCheque : '0',
          obs: value?.obs || '',
          dataPagamento: toDate(dataPagamento),
          reajuste: !reajuste?.id ? 0 : reajuste?.id,
        };
        const response = await api
          .financeiro()
          .updatePagamento(idMovimentacao, newData);
        getReceitaDespesa(response.gloFinanceiroId);
        setReceitasDespesas((old) =>
          old.map((item) =>
            item.id === value.pagamento.id
              ? {
                  ...response,
                  id: response.id,
                  descricao: item.descricao,
                  atual: response.atual,
                  categoria_descricao: item.categoria_descricao,
                  categoria_id: 1,
                  notafiscal: notafiscal,
                  colaborador: response.gloVinculoId,
                  data_formatada: response.dataPagamento || response.data,
                  valor: response.valor,
                  tipo: item.tipo,
                  juros: Number(response.juros),
                  glo_financeiro_id: response.gloFinanceiroId,
                  referencia_tipo: '-',
                }
              : item,
          ),
        );
        showToast({
          type: 'success',
          message: 'Pagamento editado com sucesso!',
        });
        setUpdatePagamentoLoading(false);
        return response;
      } catch (err) {
        setUpdatePagamentoLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [getReceitaDespesa, showToast],
  );

  const addPagamento = useCallback(
    async (id) => {
      setAddPagamentoLoading(true);
      try {
        const response = await api
          .financeiro()
          .addPagamento({ financeiroId: id });
        getReceitaDespesa(id);
        showToast({
          type: 'success',
          message: 'Pagamento adicionado com sucesso!',
        });
        setAddPagamentoLoading(false);
        return response;
      } catch (err) {
        setAddPagamentoLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast, getReceitaDespesa],
  );

  const getResumo = useCallback(async (filter) => {
    try {
      setResumoLoading(true);
      const response = await api.financeiro().getResumo(filter);
      setResumoItem(response.content);
      setResumo(response);
      setResumoLoading(false);
      return response;
    } catch (errors) {
      setResumoLoading(false);
    }
  }, []);

  const getResumoTresMeses = useCallback(async (filter) => {
    try {
      setResumoTresMesesLoading(true);
      const response = await api.financeiro().getResumoTresMeses(filter);

      setResumoTresMeses(response);
      setResumoTresMesesLoading(false);
      return response;
    } catch (errors) {
      setResumoTresMesesLoading(false);
    }
  }, []);

  const changeStatusPagamento = useCallback(
    async (id, value) => {
      const { status } = value;

      setChangeStatusPagamentoLoading(id);
      try {
        const response = await api.financeiro().changeStatus(id, { status });
        setReceitasDespesas((old) =>
          old.map((item) => (item.id === id ? { ...item, ...response } : item)),
        );
        setTimeout(() => setChangeStatusPagamentoLoading(null), 1000);
        showToast({
          type: 'success',
          message: 'Pagamento alterado com sucesso!',
        });
      } catch (err) {
        setChangeStatusPagamentoLoading(null);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast],
  );

  const deleteReceitaDespesa = useCallback(
    async (id) => {
      try {
        setDeleteReceitaDespesaLoading(true);
        await api.financeiro().deleteReceitaDespesa(id);
        showToast({
          type: 'success',
          message: 'Movimentação apagada com sucesso!',
        });
        setDeleteReceitaDespesaLoading(false);
      } catch (err) {
        setDeleteReceitaDespesaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast],
  );

  const deletePagamento = useCallback(
    async (idPagamento, idLancamento) => {
      try {
        setDeletePagamentoLoading(true);
        await api.financeiro().deletePagamento(idPagamento);
        getReceitaDespesa(idLancamento);
        showToast({
          type: 'success',
          message: 'Parcela apagada com sucesso!',
        });
      } catch (err) {
        setDeletePagamentoLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast, getReceitaDespesa],
  );

  const [downloadCsvFinancialLoading, setDownloadCsvFinancialLoading] =
    useState(false);

  const downloadCsvFinancial = useCallback(
    async (filters) => {
      try {
        setDownloadCsvFinancialLoading(true);
        const fileData = await api.financeiro().downloadCsvFinancial(filters);

        const blob = new Blob([fileData], { type: fileData.type });

        const now = new Date();
        const formattedDate = format(now, 'dd-MM-yyyy-HH-mm-ss');

        const _type = {
          CSV: 'csv',
          XLSX: 'xlsx',
        };

        const fileName = `financeiro-${formattedDate}-${_type[filters?.type]}`;

        saveAs(blob, fileName);
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setDownloadCsvFinancialLoading(false);
      }
    },
    [showToast],
  );

  return (
    <ReceitaDespesaContext.Provider
      value={{
        receitasDespesasLoading,
        getReceitasDespesas,
        receitasDespesas,
        insertReceitaDespesa,
        receitaDespesaLoading,
        insertReceitaDespesaLoading,
        editReceitaDespesaLoading,
        editReceitaDespesa,
        updatePagamento,
        updatePagamentoLoading,
        valoresFinanceiro,
        getReceitaDespesa,
        receitaDespesa,
        resumoLoading,
        resumoItem,
        resumo,
        getResumo,
        pagamentoItem,
        setPagamentoItem,
        getPagamentoItem,
        pagamentoItemLoading,
        changeStatusPagamento,
        changeStatusPagamentoLoading,
        deletePagamentoLoading,
        deletePagamento,
        addPagamento,
        addPagamentoLoading,
        deleteReceitaDespesa,
        deleteReceitaDespesaLoading,
        getResumoTresMeses,
        resumoTresMeses,
        resumoTresMesesLoading,
        downloadCsvFinancial,
        downloadCsvFinancialLoading,
      }}
    >
      {children}
    </ReceitaDespesaContext.Provider>
  );
};

export function useReceitaDespesa() {
  const context = useContext(ReceitaDespesaContext);

  if (!context)
    throw new Error('useReceitaDespesa must be used within an AuthProvider');

  return context;
}
