export class VendaFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getVendas(filter) {
    return this.instance.get('/v1/vendas', {
      params: filter || null,
    });
  }

  getVenda(id, filter) {
    return this.instance.get(`/v1/vendas/${id}`, {
      params: filter || null,
    });
  }

  addVenda(data) {
    return this.instance.post(`/v1/vendas`, data);
  }

  editVenda(id, data) {
    return this.instance.put(`/v1/vendas/${id}`, data);
  }

  deleteVenda(id) {
    return this.instance.delete(`/v1/vendas/${id}`);
  }

  getItensPorVenda(filter) {
    return this.instance.get('/v1/vendas/produtos/itens/', {
      params: filter || null,
    });
  }

  getLotesVendas(filter) {
    return this.instance.get('/v1/vendas/produtos/pesquisar/', {
      params: filter || null,
    });
  }

  removeLotesVendas(id) {
    return this.instance.delete(`/v1/vendas/produtos/${id}`);
  }

  getControleEntregas(id) {
    return this.instance.get(`/v1/vendas/${id}/entregas`);
  }

  getHistoricoEntregas(id) {
    return this.instance.get(`/v1/vendas/${id}/historico`);
  }

  addHistoricoEntregas(id, data) {
    return this.instance.post(`/v1/vendas/${id}/entregas`, data);
  }

  deleteHistoricoEntregas(idVenda, idHis) {
    return this.instance.delete(`/v1/vendas/${idVenda}/entregas/${idHis}`);
  }

  finalizarVenda(id, data) {
    return this.instance.put(`/v1/vendas/${id}/finalizar`, data);
  }

  abrirVenda(id) {
    return this.instance.patch(`/v1/vendas/${id}/abrir`);
  }
}

export default (...params) => new VendaFactory(...params);
