export class CadTerreiroFactory {
  constructor(instance) {
    this.instance = instance;
  }
  getTerreiros(filter) {
    return this.instance.get('/v1/cad-terreiros/', {
      params: filter || null,
    });
  }

  getTerreiro(filter, id) {
    return this.instance.get(`/v1/cad-terreiros/${id}`, {
      params: filter || null,
    });
  }

  addTerreiro(data) {
    return this.instance.post(`/v1/cad-terreiros/`, data);
  }

  editTerreiro(id, data) {
    return this.instance.put(`/v1/cad-terreiros/${id}`, data);
  }

  deleteTerreiro(id) {
    return this.instance.delete(`/v1/cad-terreiros/${id}`);
  }
}

export default (...params) => new CadTerreiroFactory(...params);
