import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { toPriceInt } from 'utils/converters';
import { useNewToast } from 'hooks/newToast';
import { appWarnings } from 'utils/appWarnings';

const folhaList = [
  {
    id: 'mensalista',
    descricao: 'Mão de Obra',
  },
  {
    id: 'colheita',
    descricao: 'Colheita',
  },
];

const pagamentoList = [
  {
    id: 1,
    descricao: 'Pagamento Único',
  },
  {
    id: 2,
    descricao: 'Pagamento Parcelado',
  },
];

const FolhaColheitaContext = createContext({});

export const FolhaColheitaProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [folhaColheitaDetails, setFolhaColheitaDetails] = useState([]);
  const [folhaColheitaDetailsLoading, setFolhaColheitaDetailsLoading] = useState(false);

  const [folhasColheita, setFolhasColheita] = useState([]);
  const [folhasColheitaLoading, setFolhasColheitaLoading] = useState(false);

  const [folhaColheita, setFolhaColheita] = useState({});
  const [folhaColheitaLoading, setFolhaColheitaLoading] = useState(false);
  const [fecharFolhaColheitaLoading, setFecharFolhaColheitaLoading] = useState(false);
  const [abrirFolhaColheitaLoading, setAbrirFolhaColheitaLoading] = useState(false);
  const [deleteFolhaColheitaLoading, setDeleteFolhaColheitaLoading] = useState(false);
  const [changeFolhaColheitaLoading, setChangeFolhaColheitaLoading] = useState(false);
  const [insertFolhaColheitaLoading, setInsertColheitaLoading] = useState(false);
  const [setPagamentoColheitaLoading, setSetPagamentoColheitaLoading] = useState(false);

  const [folhasColheitaLimite, setFolhasColheitaLimite] = useState([]);
  const [folhasColheitaLimiteLoading, setFolhasColheitaLimiteLoading] = useState(false);

  const getFolhasColheita = useCallback(async filter => {
    try {
      setFolhasColheitaLoading(true);
      const response = await api.folhaColheita().getFolhasColheita(filter);
      setFolhasColheita(response);
      setFolhasColheitaLoading(false);
    } catch (errors) {
      setFolhasColheitaLoading(false);
    }
  }, []);

  const getFolhasColheitaLimite = useCallback(async filter => {
    try {
      setFolhasColheitaLimiteLoading(true);
      const response = await api.folhaColheita().getFolhasColheita(filter);
      setFolhasColheitaLimite(response);
      setFolhasColheitaLimiteLoading(false);
    } catch (errors) {
      setFolhasColheitaLimiteLoading(false);
    }
  }, []);

  const getFolhaColheita = useCallback(async id => {
    try {
      setFolhaColheitaLoading(true);
      const response = await api.folhaColheita().getFolhaColheita(id);
      setFolhaColheita(response);
      setFolhaColheitaLoading(false);
    } catch (errors) {
      setFolhaColheitaLoading(false);
    }
  }, []);

  const insertFolhaColheita = useCallback(
    async data => {
      try {
        setInsertColheitaLoading(true);
        const { descricao, valorDia, valorExtra, periodo, fluxo, regra, data: date, categoria } = data.sobre;

        const dataInicio = periodo?.substr(0, 10);
        const dataFim = periodo?.substr(13, 21);
        const selectedColabs = data.colaboradores.map(colaborador => colaborador.id);
        const talhao = {
          filtrar_talhoes: data?.talhao?.filtrar_talhoes?.id || 0,
          talhoes: Array.isArray(data?.talhao?.talhoes) ? data?.talhao?.talhoes.map(item => Number(item?.id)) : [],
        };

        const newData = {
          descricao,
          inicio: dataInicio,
          colaboradores: selectedColabs,
          fim: dataFim,
          data: date,
          fluxo: fluxo?.id,
          categoria: categoria?.id,
          regra: regra?.id,
          talhao,
          valorDia: toPriceInt(valorDia),
          valorExtra: toPriceInt(valorExtra),
        };
        const response = await api.folhaColheita().insertFolhaColheita(newData);
        setInsertColheitaLoading(false);
        showToast({
          type: 'success',
          message: 'Folha adicionada com sucesso!',
        });
        return response;
      } catch (err) {
        setInsertColheitaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast]
  );

  const changeFolhaColheita = useCallback(
    async (id, data) => {
      try {
        setChangeFolhaColheitaLoading(true);
        const {
          descricao,
          periodo,
          fluxo,
          conta,
          valorDia,
          valorExtra,
          data: date,
          regra,
          pagamento,
          categoria,
        } = data.sobre;

        const dataInicio = periodo?.substr(0, 10);
        const dataFim = periodo?.substr(13, 21);
        const selectedColabs = data.colaboradores.map(colaborador => colaborador.id);
        const talhao = {
          filtrar_talhoes: data?.talhao?.filtrar_talhoes?.id || 0,
          talhoes: Array.isArray(data?.talhao?.talhoes) ? data?.talhao?.talhoes.map(item => Number(item.id)) : [],
        };

        const newData = {
          descricao,
          colaboradores: selectedColabs,
          inicio: dataInicio,
          fim: dataFim,
          data: date,
          fluxo: fluxo?.id,
          regra: regra?.id,
          conta: conta?.id,
          categoria: categoria?.id,
          pagamento: pagamento?.id,
          valorDia: toPriceInt(valorDia),
          valorExtra: toPriceInt(valorExtra),
          talhao,
        };
        await api.folhaColheita().editFolhaColheita(id, newData);
        getFolhaColheita(id);
        showToast({
          type: 'success',
          message: 'Folha editada com sucesso!',
        });
        setChangeFolhaColheitaLoading(false);
      } catch (err) {
        setChangeFolhaColheitaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast, getFolhaColheita]
  );

  const removeFolhaColheita = useCallback(
    async id => {
      try {
        setDeleteFolhaColheitaLoading(true);
        await api.folhaColheita().removeFolhaColheita(id);
        setFolhasColheita(folhasColheita.filter(item => item.id !== id));
        setDeleteFolhaColheitaLoading(false);
        showToast({
          type: 'success',
          message: 'Folha deletada com sucesso!',
        });
      } catch (err) {
        setDeleteFolhaColheitaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast, folhasColheita]
  );

  const getFolhaColheitaDetails = useCallback(async (id, filter) => {
    try {
      setFolhaColheitaDetailsLoading(true);
      const response = await api.folhaColheita().getFolhaColheitaDetails(id, filter);
      setFolhaColheitaDetails(response);
      setFolhaColheitaDetailsLoading(false);
    } catch (errors) {
      setFolhaColheitaDetailsLoading(false);
    }
  }, []);

  const setPagamentoColheita = useCallback(
    async (idFolha, idColaborador, data) => {
      try {
        setSetPagamentoColheitaLoading(true);
        const { valor } = data;

        const newData = {
          valor: toPriceInt(valor),
        };
        await api.folhaColheita().setPagamentoColheita(idFolha, idColaborador, newData);
        showToast({
          type: 'success',
          message: 'Valor reajustando com sucesso',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setSetPagamentoColheitaLoading(false);
      }
    },
    [showToast]
  );

  const editFolhaColheitaDetail = useCallback(
    async (id, data, colaborador) => {
      try {
        setFolhaColheitaDetailsLoading(true);
        const { valor } = data;
        const newData = {
          valor: toPriceInt(valor),
        };
        await api.folhaColheita().editFolhaColheitaDetail(id, newData, colaborador);
        getFolhaColheitaDetails(id);
      } catch (err) {
        setFolhaColheitaDetailsLoading(false);
      }
    },
    [getFolhaColheitaDetails]
  );

  const resetFolhaColheita = useCallback(() => {
    setFolhaColheita({});
  }, []);

  const resetFolhaColheitaDetail = useCallback(() => {
    setFolhaColheitaDetails([]);
  }, []);

  const abrirFolhaColheita = useCallback(
    async id => {
      try {
        setAbrirFolhaColheitaLoading(true);
        await api.folhaColheita().abrirFolhaColheita(id);
        await getFolhaColheita(id);
        await getFolhaColheitaDetails(id);
        showToast({
          type: 'success',
          message: 'Folha aberta com sucesso!',
        });
        setAbrirFolhaColheitaLoading(false);
      } catch (err) {
        setAbrirFolhaColheitaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast, getFolhaColheita, getFolhaColheitaDetails]
  );

  const fecharFolhaColheita = useCallback(
    async id => {
      try {
        setFecharFolhaColheitaLoading(true);
        await api.folhaColheita().fecharFolhaColheita(id);
        await getFolhaColheita(id);
        await getFolhaColheitaDetails(id);

        showToast({
          type: 'success',
          message: 'Folha fechada com sucesso!',
        });
        setFecharFolhaColheitaLoading(false);
      } catch (err) {
        setFecharFolhaColheitaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast, getFolhaColheita, getFolhaColheitaDetails]
  );

  return (
    <FolhaColheitaContext.Provider
      value={{
        getFolhaColheita,
        getFolhasColheita,
        getFolhaColheitaDetails,
        editFolhaColheitaDetail,
        changeFolhaColheita,
        insertFolhaColheita,
        removeFolhaColheita,
        resetFolhaColheita,
        resetFolhaColheitaDetail,
        abrirFolhaColheita,
        abrirFolhaColheitaLoading,
        fecharFolhaColheita,
        fecharFolhaColheitaLoading,
        folhaColheita,
        folhaColheitaDetails,
        folhasColheita,
        folhaColheitaDetailsLoading,
        insertFolhaColheitaLoading,
        changeFolhaColheitaLoading,
        deleteFolhaColheitaLoading,
        folhasColheitaLoading,
        folhaColheitaLoading,
        folhaList,
        pagamentoList,
        setPagamentoColheita,
        setPagamentoColheitaLoading,
        getFolhasColheitaLimite,
        folhasColheitaLimite,
        folhasColheitaLimiteLoading,
      }}>
      {children}
    </FolhaColheitaContext.Provider>
  );
};

export function useFolhaColheita() {
  const context = useContext(FolhaColheitaContext);

  if (!context) throw new Error('useFolhaColheita must be used within an AuthProvider');

  return context;
}
