import React, { useState, useContext, createContext } from 'react';

const DashContext = createContext({});

export const DashProvider = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(true);
  const [isVisibleMenu, setIsVisibleMenu] = useState(true);
  const [tabAtual, setTabAtual] = useState(0);

  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const hiddenMenu = React.useCallback(() => {
    setIsVisibleMenu(false);
  }, []);

  const showMenu = React.useCallback(() => {
    setIsVisibleMenu(true);
  }, []);

  const changeTab = React.useCallback(tab => {
    setTabAtual(tab);
  }, []);

  return (
    <DashContext.Provider
      value={{
        openMenu,
        handleMenu,
        changeTab,
        tabAtual,
        showMenu,
        hiddenMenu,
        isVisibleMenu,
      }}>
      {children}
    </DashContext.Provider>
  );
};

export function useDash() {
  const context = useContext(DashContext);

  if (!context) throw new Error('useDash must be used within an ToastProvider');

  return context;
}
