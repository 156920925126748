export class DespesaFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getCustos(filter) {
    return this.instance.get('/v1/cat-cc', {
      params: filter || null,
    });
  }

}

export default (...params) => new DespesaFactory(...params);
