import config from './config';
import colaboradorFactory from './colaboradores';
import Colaborador from './colaborador';
import safraFactory from './configuracoes/safra';
import parametrosContaFactory from './configuracoes/parametrosConta';
import authFactory from './auth';
import favoritesFactory from './favorites';
import logsFactory from './logs';
import caldaFactory from './calda';
import atividadeFactory from './configuracoes/atividade';
import regAtividadeFactory from './regAtividades';
import regInsumoFactory from './regAtividades';
import regMdobraFactory from './regAtividades';
import insumoFactory from './insumo';
import MaquinaFactory from './maquina';
import talhaoFactory from './talhao';
import EstoqueProdFactory from './estoqueProd';
import CatLancamentosFactory from './configuracoes/catLancamentos';
import turmaFactory from './turma';
import fazendaFactory from './fazenda';
import implementoFactory from './implemento';
import historicoFactory from './insumo/historico';
import folhaMensalistaFactory from './folhaMensalista';
import folhaColheitaFactory from './folhaColheita';
import fluxoFactory from './financeiro/fluxo';
import pagamentoFactory from './configuracoes/pagamento';
import estoqueFactory from './configuracoes/estoque';
import funcaoFactory from './configuracoes/funcao';
import apontamentoFactory from './configuracoes/apontamento';
import qualidadeCafeFactory from './configuracoes/qualidadeCafe';
import usuarioFactory from './configuracoes/usuario';
import centroCustoFactory from './configuracoes/centroCusto';
import acompanhamentoFactory from './colheita/acompanhamento';
import ColheitaFactory from './colheita';
import financeiroFactory from './financeiro';
import fornecedorFactory from './fornecedores';
import clienteFactory from './clientes';
import cadEstoqueFactory from './cadEstoque';
import tutorialFactory from './tutorial';
import dadosCadastroFactory from './dadosCadastro';
import configGeraisFactory from './gerais';
import vendaFactory from './vendas';
import despolpadorFactory from './rastreabilidade/despolpador';
import terreiroFactory from './rastreabilidade/terreiro';
import secadorFactory from './rastreabilidade/secador';
import armazemFactory from './rastreabilidade/armazem';
import lotesFactory from './rastreabilidade/lotes';

import CadFermentadorFactory from './cadRastreabilidade/cadFermentadores';
import cadArmazemFactory from './cadRastreabilidade/cadArmazens';
import CadPilhasFactory from './cadRastreabilidade/cadPilhas';
import cadDespolpadorFactory from './cadRastreabilidade/cadDespolpadores';
import cadSecadorFactory from './cadRastreabilidade/cadSecadores';
import cadTerreiroFactory from './cadRastreabilidade/cadTerreiros';
import BeneficioFactory from './rastreabilidade/beneficio';
import RebeneficioFactory from './rastreabilidade/rebeneficio';
import AbastecimentoFactory from './abastecimento';
import ManutencaoFactory from './manutencao';
import contratosParceriasFactory from './contratosParcerias';
import RelInsumosFactory from './relatorios/insumos';
import PermissionsFactory from './permissions';
import RelTalhoesFactory from './relatorios/talhoes';
import RelMensalFactory from './relatorios/mensal';
import RelAnualFactory from './relatorios/anual';
import RelIndiretosFactory from './relatorios/indiretos';
import RelFinanceiroFactory from './relatorios/financeiro';
import cadInventario from './inventario';
import CompraFactory from './compras';
import RelDreFactory from './relatorios/dre';
import PlanejamentoFactory from './planejamento';
import EstimativaFactory from './estimativa';
import ReciboFactory from './recibos';
import RelColaboradoresFactory from './relatorios/colaboradores';
import RelatoriosSafrasFactory from './relatorios/safras';
import ordemServicosFactory from './ordemServicos';
import ServicosFactory from './configuracoes/servicos';
import InvoiceFactory from './invoice';
import FileFactory from './file';
import DashboardFactory from './dashboard';
import AgronomistFactory from './agronomist';

export class Api {
  constructor(params) {
    this.instance = config(params, true).instance();
  }

  agronomist() {
    return AgronomistFactory(this.instance);
  }

  despolpador() {
    return despolpadorFactory(this.instance);
  }

  file() {
    return FileFactory(this.instance);
  }

  relatoriosColaboradores() {
    return RelColaboradoresFactory(this.instance);
  }

  dashboard() {
    return DashboardFactory(this.instance);
  }

  permissions() {
    return PermissionsFactory(this.instance);
  }

  invoice() {
    return InvoiceFactory(this.instance);
  }

  estimativa() {
    return EstimativaFactory(this.instance);
  }

  planejamento() {
    return PlanejamentoFactory(this.instance);
  }

  relAnual() {
    return RelAnualFactory(this.instance);
  }

  relIndiretos() {
    return RelIndiretosFactory(this.instance);
  }

  contratosParcerias() {
    return contratosParceriasFactory(this.instance);
  }

  estoqueProd() {
    return EstoqueProdFactory(this.instance);
  }

  compras() {
    return CompraFactory(this.instance);
  }

  colheita() {
    return ColheitaFactory(this.instance);
  }

  cadDespolpador() {
    return cadDespolpadorFactory(this.instance);
  }

  terreiro() {
    return terreiroFactory(this.instance);
  }

  abastecimento() {
    return AbastecimentoFactory(this.instance);
  }

  manutencao() {
    return ManutencaoFactory(this.instance);
  }

  cadTerreiro() {
    return cadTerreiroFactory(this.instance);
  }

  secador() {
    return secadorFactory(this.instance);
  }

  cadSecador() {
    return cadSecadorFactory(this.instance);
  }

  armazem() {
    return armazemFactory(this.instance);
  }

  cadFermentador() {
    return CadFermentadorFactory(this.instance);
  }

  cadArmazem() {
    return cadArmazemFactory(this.instance);
  }

  pilha() {
    return CadPilhasFactory(this.instance);
  }

  beneficio() {
    return BeneficioFactory(this.instance);
  }

  rebeneficio() {
    return RebeneficioFactory(this.instance);
  }

  lotes() {
    return lotesFactory(this.instance);
  }

  dadosCadastro() {
    return dadosCadastroFactory(this.instance);
  }

  gerais() {
    return configGeraisFactory(this.instance);
  }

  atividade() {
    return atividadeFactory(this.instance);
  }

  tutorial() {
    return tutorialFactory(this.instance);
  }

  cadEstoque() {
    return cadEstoqueFactory(this.instance);
  }

  cliente() {
    return clienteFactory(this.instance);
  }

  fornecedor() {
    return fornecedorFactory(this.instance);
  }

  acompanhamento() {
    return acompanhamentoFactory(this.instance);
  }

  pagamento() {
    return pagamentoFactory(this.instance);
  }

  centroCusto() {
    return centroCustoFactory(this.instance);
  }

  usuario() {
    return usuarioFactory(this.instance);
  }

  qualidade_cafe() {
    return qualidadeCafeFactory(this.instance);
  }

  apontamento() {
    return apontamentoFactory(this.instance);
  }

  estoque() {
    return estoqueFactory(this.instance);
  }

  colaborador() {
    return colaboradorFactory(this.instance);
  }

  colaboradores() {
    return Colaborador(this.instance);
  }

  folhaMensalista() {
    return folhaMensalistaFactory(this.instance);
  }

  folhaColheita() {
    return folhaColheitaFactory(this.instance);
  }

  calda() {
    return caldaFactory(this.instance);
  }

  vendas() {
    return vendaFactory(this.instance);
  }

  fluxo() {
    return fluxoFactory(this.instance);
  }

  insumo() {
    return insumoFactory(this.instance);
  }

  historico() {
    return historicoFactory(this.instance);
  }

  talhao() {
    return talhaoFactory(this.instance);
  }

  implemento() {
    return implementoFactory(this.instance);
  }

  financeiro() {
    return financeiroFactory(this.instance);
  }

  turma() {
    return turmaFactory(this.instance);
  }

  funcao() {
    return funcaoFactory(this.instance);
  }

  fazenda() {
    return fazendaFactory(this.instance);
  }

  catLancamentos() {
    return CatLancamentosFactory(this.instance);
  }

  regAtividade() {
    return regAtividadeFactory(this.instance);
  }

  regInsumo() {
    return regInsumoFactory(this.instance);
  }

  regMdobra() {
    return regMdobraFactory(this.instance);
  }

  maquina() {
    return MaquinaFactory(this.instance);
  }

  auth() {
    return authFactory(this.instance);
  }

  favorites() {
    return favoritesFactory(this.instance);
  }

  logs() {
    return logsFactory(this.instance);
  }

  safras() {
    return safraFactory(this.instance);
  }

  relInsumos() {
    return RelInsumosFactory(this.instance);
  }

  relTalhoes() {
    return RelTalhoesFactory(this.instance);
  }

  relMensal() {
    return RelMensalFactory(this.instance);
  }

  parametrosConta() {
    return parametrosContaFactory(this.instance);
  }

  inventario() {
    return cadInventario(this.instance);
  }

  relDreFactory() {
    return RelDreFactory(this.instance);
  }

  relFinanceiro() {
    return RelFinanceiroFactory(this.instance);
  }

  relatoriosSafras() {
    return RelatoriosSafrasFactory(this.instance);
  }

  recibos() {
    return ReciboFactory(this.instance);
  }

  servicosFactory() {
    return ServicosFactory(this.instance);
  }

  ordemServicos() {
    return ordemServicosFactory(this.instance);
  }
}

export default (params) => new Api(params);
