export class CatLancamentosFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getCatLancamentos(filter) {
    return this.instance.get('/v1/cat-contas', {
      params: filter || null,
    });
  }

  getCatLancamento(id) {
    return this.instance.get(`/v1/cat-contas/${id}`);
  }

  addDespesa(data) {
    return this.instance.post('/v1/cat-contas', data);
  }

  editDespesa(id, data) {
    return this.instance.put(`/v1/cat-contas/${id}`, data);
  }

  deleteDespesa(id, categoryToMove) {
    return this.instance.delete(`/v1/cat-contas/${id}?category_to_move=${categoryToMove}`);
  }

  addReceita(data) {
    return this.instance.post('/v1/cat-contas', data);
  }

  editReceita(id, data) {
    return this.instance.put(`/v1/cat-contas/${id}`, data);
  }

  deleteReceita(id, categoryToMove) {
    return this.instance.delete(`/v1/cat-contas/${id}?category_to_move=${categoryToMove}`);
  }
}

export default (...params) => new CatLancamentosFactory(...params);

