/* eslint-disable camelcase */
import axios from 'axios';

const noop = () => null;

export class Config {
  constructor({ api_url = 'http://192.168.0.100:3333', has_user_header = false }) {
    if (!api_url) throw new Error('Missing api_url');
    this.axios = this.create(api_url, has_user_header);

    this.intercept('request');
    this.intercept('response', ({ data }) => data);
  }

  create(apiUrl, has_user_header) {
    return axios.create({
      baseURL: apiUrl,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
        // Authorization: api_key,
      },
    });
  }


  intercept(hook, successFn = noop, errFn = console.error) {
    const allowedHooks = new Set(['request', 'response']);
    if (!allowedHooks.has(hook))
      throw new Error('Just request and response are allowed to be intercepted');
    this.axios.interceptors[hook].use(
      async request => {
        const data = successFn(request);
        if (hook === 'response' && !!data) {
          return successFn(request);
        }
        return request;
      },
      error => {
        errFn(error);
        return Promise.reject(error);
      }
    );
  }

  instance() {
    return this.axios;
  }
}

export default config => new Config(config);
