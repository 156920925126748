export class RelInsumosFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getRelInsumos(filter) {
    return this.instance.get('/v1/relatorios/insumos', {
      params: filter || null,
    });
  }

  getRelInsumosByAtividade(id, filter) {
    return this.instance.get(`/v1/relatorios/insumos/${id}`, {
      params: filter || null,
    });
  }
}

export default (...params) => new RelInsumosFactory(...params);
