import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';

import moment from 'moment';

import { safraConverter } from 'utils/converters';
import { appWarnings } from 'utils/appWarnings';

import { useAuth } from 'hooks/auth';
import { useNewToast } from 'hooks/newToast';

const SafraContext = createContext({});

export const SafraProvider = ({ children }) => {
  const { isAuth, token } = useAuth();

  const { showToast } = useNewToast();

  const [safras, setSafras] = useState([]);
  const [safrasLoading, setSafrasLoading] = useState(false);

  const [safra, setSafra] = useState({});
  const [safraLoading, setSafraLoading] = useState(false);

  const [insertSafraTutorialLoading, setInsertSafraTutorialLoading] = useState(false);
  const [insertSafraLoading, setInsertSafraLoading] = useState(false);
  const [safraAtual, setSafraAtual] = useState({});
  const [editSafraLoading, setEditSafraLoading] = useState(false);
  const [deleteSafraLoading, setDeleteSafraLoading] = useState(false);

  const getSafraAtual = useCallback(async () => {
    try {
      const response = await api.safras().getSafraAtual();
      setSafraAtual(response);
    } catch {}
  }, []);

  const getSafras = useCallback(async filter => {
    try {
      setSafrasLoading(true);
      const response = await api.safras().getSafras(filter);
      setSafras(response);
      setSafrasLoading(false);
    } catch (errors) {
      setSafrasLoading(false);
    }
  }, []);

  const getSafra = useCallback(async id => {
    try {
      setSafraLoading(true);
      const response = await api.safras().getSafra(id);
      setSafra(response);
      setSafraLoading(false);
    } catch (errors) {
      setSafraLoading(false);
    }
  }, []);

  const insertSafraTutorial = useCallback(
    async data => {
      try {
        setInsertSafraTutorialLoading(true);
        const { inicio, fim } = data;

        const newData = {
          inicio,
          fim,
          produto: 100,
          descricao: safraConverter(data),
        };
        const response = await api.safras().insertSafraTutorial(newData);
        setSafras(old => [...old, response]);
        setSafraAtual(response);
      } catch (err) {
        setInsertSafraTutorialLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast]
  );

  const insertSafra = useCallback(
    async data => {
      try {
        setInsertSafraLoading(true);
        const { inicio, fim, talhoes, safraAtual } = data;
        const tempInicio = moment(inicio).format('YYYY-MM-DD');
        const tempFim = moment(fim).format('YYYY-MM-DD');
        const newData = {
          inicio: tempInicio,
          fim: tempFim,
          safraAtual: !!safraAtual ? 1 : 0,
          produto: 100,
          talhoes: talhoes ? talhoes?.filter(item => item.selecionado === 1) : [],
          descricao: `SAFRA ${tempInicio.split('-')[0]}/${tempFim.split('-')[0]}`,
        };
        await api.safras().insertSafra(newData);
        getSafras();
        showToast({
          type: 'success',
          message: 'Safra adicionada com sucesso!',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setInsertSafraLoading(false);
      }
    },
    [showToast, getSafras]
  );

  const editSafra = useCallback(
    async (id, data) => {
      try {
        setEditSafraLoading(true);
        const { inicio, fim, talhoes, safraAtual } = data;

        const tempInicio = moment(inicio).format('YYYY-MM-DD');
        const tempFim = moment(fim).format('YYYY-MM-DD');

        const newData = {
          inicio: tempInicio,
          fim: tempFim,
          safraAtual: !!safraAtual ? 1 : 0,
          produto: 100,
          talhoes: talhoes.filter(item => item.selecionado === 1),
          descricao: `SAFRA ${tempInicio.split('-')[0]}/${tempFim.split('-')[0]}`,
        };
        const response = await api.safras().editSafra(id, newData);

        showToast({
          type: 'success',
          message: 'Safra editada com sucesso!',
        });

        const tempResponse = {
          data_inicio: response.dataInicio,
          data_fim: response.dataFim,
          produto: 'CAFÉ',
        };

        setSafras(old => [...old.filter(item => item.id !== id), { ...response, ...tempResponse }]);
        setEditSafraLoading(false);
      } catch (err) {
        setEditSafraLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast]
  );

  const deleteSafra = useCallback(
    async id => {
      try {
        setDeleteSafraLoading(true);
        await api.safras().deleteSafra(id);
        setSafras(safras.filter(item => item.id !== id));
        showToast({
          type: 'success',
          message: 'Safra removida com sucesso!',
        });
        setDeleteSafraLoading(false);
      } catch (err) {
        setDeleteSafraLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [safras, showToast]
  );

  const init = useCallback(async () => {
    getSafras();
    getSafraAtual();
  }, [getSafras, getSafraAtual]);

  const down = useCallback(async () => {
    setSafras([]);
    setSafraAtual({});
  }, []);

  React.useEffect(() => {
    if (!!isAuth && token !== 'initial') {
      init();
    } else {
      down();
    }
  }, [isAuth, init, down, token]);

  return (
    <SafraContext.Provider
      value={{
        getSafras,
        safras,
        safrasLoading,
        insertSafraTutorialLoading,
        insertSafraTutorial,
        insertSafra,
        insertSafraLoading,
        editSafraLoading,
        editSafra,
        deleteSafraLoading,
        deleteSafra,
        getSafra,
        safra,
        safraLoading,
        setSafraAtual,
        safraAtual,
        getSafraAtual,
      }}>
      {children}
    </SafraContext.Provider>
  );
};

export function useSafra() {
  const context = useContext(SafraContext);

  if (!context) throw new Error('useSafra must be used within an AuthProvider');

  return context;
}
