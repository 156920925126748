var loteTerreiroList = [
      {
        id: 1,
        codigo: "D12-0609A-Boinha",
        terreiro: 50,
        entrada: "2021-03-15",
        saida: "2021-03-17",
        hora_saida: "19:30",
        hora_entrada: "17:30",
        responsavel: "Jorge",
        quantidade: "2000"
      },
      {
        id: 10,
        codigo: "D12-0609A-Verde",
        terreiro: 50,
        entrada: "2021-03-15",
        saida: "2021-03-17",
        hora_saida: "19:30",
        hora_entrada: "17:30",
        responsavel: "Jorge",
        quantidade: "2000"
      },
      {
        id: 11,
        codigo: "D12-0609A-Verde",
        terreiro: 50,
        entrada: "2021-03-15",
        saida: "2021-03-17",
        hora_saida: "19:30",
        hora_entrada: "17:30",
        responsavel: "Jorge",
        quantidade: "2000"
      },
      {
        id: 2,
        codigo: "D12-0609A-Cereja2",
        terreiro: 43,
        entrada: "2021-03-15",
        saida: "2021-03-17",
        hora_saida: "19:30",
        hora_entrada: "17:30",
        responsavel: "Jorge",
        quantidade: "2000"
      },
      {
        id: 55,
        codigo: "D12-0609A-Cereja2",
        terreiro: 43,
        entrada: "2021-03-15",
        saida: "2021-03-17",
        hora_saida: "19:30",
        hora_entrada: "17:30",
        responsavel: "Jorge",
        quantidade: "2000"
      },
      {
        id: 3,
        codigo: "D12-0609A-Natural",
        entrada: "2021-03-15",
        terreiro: 26,
        saida: "2021-03-17",
        hora_saida: "19:30",
        hora_entrada: "17:30",
        responsavel: "Jorge",
        quantidade: "2000"
      },
];

export function db_loteTerreiroList() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(loteTerreiroList);
    }, 1000);
  });
}

var escolherTerreiro = [
  {
    id: 1,
    codigo: "D1-0403A-Verde",
    quantidade: "1000.00",
  },
  {
    id: 2,
    codigo: "D1-0403A-Cereja",
    quantidade: "1000.00",
  },
  {
    id: 3,
    codigo: "D1-0403A-Boinha",
    quantidade: "0.00",
  },
  {
    id: 4,
    codigo: "D1-0405A-Boinha",
    quantidade: "20.00",
  },
  {
    id: 5,
    codigo: "D1-0405A-Verde",
    quantidade: "1000.00",
  },
  {
    id: 6,
    codigo: "D1-0405A-Cereja",
    quantidade: "0.00",
  }
]

export function db_escolherTerreiroList() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(escolherTerreiro);
    }, 1000);
  });
}
