export class RelTalhoesFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getTalhoesProducao(filter) {
    return this.instance.post('/v1/relatorios/talhoes/producao-por-talhao', filter);
  }
}

export default (...params) => new RelTalhoesFactory(...params);
