import React from 'react';

import { TalhaoProvider } from 'hooks/providers/cadastros/talhoes';
import { FazendaProvider } from 'hooks/providers/cadastros/fazendas';
import { SafraProvider } from 'hooks/providers/configuracoes/safras';

const CadastrosProvider = ({ children }) => (
  <FazendaProvider>
    <SafraProvider>
      <TalhaoProvider>{children}</TalhaoProvider>
    </SafraProvider>
  </FazendaProvider>
);

export { CadastrosProvider };
