import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;    
    border: 0;
    
  }
  

  
  /* .MuiDivider-root {
    height: 0;
  } */ 

  .jAhGyi:hover {
    background: #c7c3c3 !important;
  }
  .eKkdRO {
    height: 0px !important;
    display: none !important;
    position: none !important;
    top: none !important;
    padding: none !important;
    z-index: none !important;
    overflow: none !important;
  }

  .marker-talhao {
    color: #fff !important;
    font-weight: bold;
    font-size: 10px !important;
    text-shadow:
      -1px -1px 0px #000,
      -1px 1px 0px #000,
      1px -1px 0px #000,
      1px 0px 0px #000;
    
  }

  .marker-farm {
    margin-top: 50px;
    color: #fff !important;
    font-weight: bold;
    font-size: 10px !important;
    text-transform: uppercase;
    text-shadow:
      -1px -1px 0px #000,
      -1px 1px 0px #000,
      1px -1px 0px #000,
      1px 0px 0px #000;
  }
  
  .MuiTableCell-root {
    padding: 8px 5px !important;
  }

  .back-button {
    padding: 0 3px !important;
  }
  
  .translate-quite {
    transform: translate(-50%, -50%);
  }
  
  body {
    background: #EEEEEE;
    color: #000;
    -webkit-font-smoothing: antialiased;
    padding-right: 0px !important;
    /* overflow: hidden; */
    
    ::-webkit-scrollbar {
      width: 5px !important;
    };

    ::-webkit-scrollbar-thumb {
      background: #8B8E93 !important;
      border-radius: 20px ;
    };
  }

  body, input, button {
    font: 16px "Roboto", "Segoe UI";
  }

  /* Open Sans','Segoe UI */

  button {
    cursor: pointer;
    /* max-width: 1000px; 
    height: 40px; 
    width: 100%; */
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  svg{
    margin: 0px;
  }

  header {
    padding-right: 0px !important;
  }

  .sel {
    width: 100%;
  }

  .react-select__menu {
    z-index: 1000 !important
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #368467 !important;
  }

  .bWoXLz {
    display: none !important;
    height: 0;
  }

  input[type='number'] {
    font-family: "Roboto", "Segoe UI"
  }


  .checkbox-white {
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: #FFF !important;
    }
  }

  .MuiIconButton-colorSecondary:hover {
    background-color: transparent !important;
  }

  .ListDefault:last-child {
    border-bottom: none;
  }

	.max-length-exceeded {
  	color: red !important;  
	}

  /* .gm-style {
    border-radius: 12px !important ;
  } */

  .MuiPickersDay-current {
    color: #368467 !important
  }

  .MuiPickersToolbar-toolbar {
    background-color: #368467 !important
  }

  .MuiPickersDay-daySelected {
    background-color: #368467 !important;
    color: #fff !important
  }
  .MuiButton-textPrimary {
    color: #368467 !important
  }

  .MuiToolbar-root.MuiToolbar-regular.sc-fznJRM.dmFEhZ.MuiToolbar-gutters {
    height: 45px !important;
  }  
  
  .RoundedDefaultTable {
    box-shadow: 0 0 0 1.5px #e6e6e6 !important;   
    border-radius: 2px !important;
  }

  .RoundedDefaultTableV2 {
    box-shadow: 0 0 0 1px #e6e6e6 !important;
    border-radius: 2px !important;
  }
  .tab-overflow {
    ::-webkit-scrollbar {
      width: 5px !important;
    };

    ::-webkit-scrollbar-thumb {
      background: #8B8E93 !important;
      border-radius: 20px ;
    };
  }

  

  /* . css-2613qy-menu {
    z-index: 2000010 !important;
  } */

  
  #app-wrapper {
    margin: 0;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    background-color: red;
  }

  .MTableToolbar-searchField-17{
    padding-left: 0px !important;
  }

  .MuiIconButton-root {
    padding: 5px !important;
  }
  
  .MuiTouchRipple-root {
    display: none !important;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart {
    height: 40px;
    width: 140px;
  }
  .PrivateNotchedOutline-legend-14 {
    display: none;
  }

  .MuiFormControl-marginNormal {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .bx--date-picker.bx--date-picker--single .bx--date-picker__input {
    width: 100% ;
  }

  .numInputWrapper {
    bottom: 0.3px;
  }
  
  .table-lotes-rastreabilidade {
      th {
      background: #368467 !important;
      color: '#fff' !important;
    }
  }

  .bx--date-picker {
    width: 100%;
  }

  .bx--date-picker-container {
    width: 100% !important;
  }
  .bx--date-picker--range .bx--date-picker__input {
    width: 100% !important;
  }

  .bx--date-picker__input {
    padding: 0px 0px 0px 15px;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    font-family: "Roboto", "Segoe UI"
  }

  .bx--date-picker__input:focus, .bx--date-picker__input.bx--focused {
    outline: none !important;
  }

  .bx--label {
    font-size: 12px;
    top: 0px;
    background: white;
    transform: translateY(-50%);
    position: absolute;
    z-index: 1;
    left: 10px;
    padding-left: 5px;
    padding-right: 5px;
    color: rgba(0, 0, 0, 0.54);
  }

  .MuiAutocomplete-popper {
    position: block !important;
  }

  .PrivateTabIndicator-colorSecondary-11 {
  background: rgb(77, 173, 74) !important;
}

.MuiPaper-elevation4 {
  box-shadow: 0px 0px 0px 0px !important;
}

.barra_scrollbar {
  ::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #8b8e93 !important;
    border-radius: 20px !important;
  }
}

.MuiAppBar-positionFixed {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%) !important;
}

.MTableToolbar-root-17 {
  margin-left: -40px !important;
}

.ddBwdT {
  background: transparent;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-marginDense.MuiInputLabel-outlined {
  color: black;
  padding-right: 6px;
  transform: translate(14px, -6px) scale(0.75);
  background: white;
  padding-left: 6px;
}


.select-no-border {
  .select__control {
    border: 0px !important
  }
}



.react-select__menu {
  z-index: 1000,
}


.tab-center {
   .MuiTabs-flexContainer {
      display: flex;
      justify-content: center;
    }
}

.noLastLine tr:last-child {
        border: none;
      }

  svg{
    .SvgjsSvg1006 {
      height: 160px;
    }
  }

  .apexcharts-tooltip {
    background: #000000B2 ;
    color: white;
    font-weight: 100;
    border-radius: 2px;
  }

  .MuiSlider-root {
    color: #368467 !important
  }

  .MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorPrimary.sc-hKFxyN.kiddOD.makeStyles-appBar-32.makeStyles-appBarShift-33.mui-fixed.MuiPaper-elevation4 {
    z-index: 1000 !important;
    width: 100% !important
  }

  .MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorPrimary.sc-hKFxyN.kiddOD.makeStyles-appBar-2.mui-fixed.MuiPaper-elevation4 {
    padding: 0 !important;
  }

  .MuiDrawer-paperAnchorLeft {
    left: 0 !important;
    right: auto !important;
  }
  
  .MuiAppBar-positionFixed {
    right: auto !important;
  }

  .MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorPrimary.sc-hYRTwp.gIHQrJ.makeStyles-appBar-29.makeStyles-appBarShift-30.mui-fixed.MuiPaper-elevation4 {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .MuiBackdrop-root {
    background-color: rgba(0,0,0,0.2) !important
  }
  
  .MuiDrawer-paper {
    position: relative !important;
  }

  .fc .fc-toolbar-title{
    text-transform: capitalize !important;
  }
  
  .MuiIconButton-root:hover {
    background-color: transparent !important;
  }

  .no-padding {
    padding: 0 !important;
  }
  
  .MuiPaper-elevation8 {
    box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 2%) !important;
  }

  .table-fixed-header {
    table {      
      border-collapse: separate !important;
      border-spacing: 0 !important;
      table-layout: fixed !important;      
    }

    table td {
      border: none !important
    },

    table tr {
      border: none !important
    },

    th {
      vertical-align: bottom;
      background-color: white;
      color: #666;
    }

    th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2;
    }

    th[scope='row'] {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 1;
    }

    th[scope='row'] {
      vertical-align: top;
      color: inherit;
      background-color: inherit;
      background: linear-gradient(
        90deg,
        transparent 0%,
        transparent calc(100% - 0.05em),
        #d6d6d6 calc(100% - 0.05em),
        #d6d6d6 100%
      );
    }
  }  

  .styles-scroll {
    ::-webkit-scrollbar {
    width: 5px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #8b8e93 !important;
    border-radius: 20px !important;
  }
  }

  .fixed-header {
    th {
      vertical-align: bottom;
      background-color: white;
    }

    th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2 !important;
    }

    th[scope='row'] {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 1;
    }

    th[scope='row'] {
      vertical-align: top;
      color: inherit;     
    }
  }


  .fixed-header-2 {
    th {
      vertical-align: bottom;
      background-color: #368467;  
    }

    th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2;
    }

    th[scope='row'] {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 1;
    }

    th[scope='row'] {
      vertical-align: top;
      color: inherit;     
    }
  }

  .fade-in-text {
    animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    -o-animation: fadeIn 3s;
    -ms-animation: fadeIn 3s;
  }


/* .MuiCollapse-container {
    width: 0px !important;
    transition-property: width !important;
  }

  .MuiCollapse-entered {
    width: 100% !important
  }

  .MuiCollapse-hidden {
    width: 0px !important
  } */

@keyframes fadeIn {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@-o-keyframes fadeIn {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

`;
