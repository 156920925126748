import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 0;
  flex-direction: column;
  display: flex;
  position: fixed;
  top: 110px;
  z-index: 1000;
  overflow: hidden;
`;
