import React, { useCallback, useContext, createContext } from 'react';
import api from 'services';
import { useNewToast } from 'hooks/newToast';
import { appWarnings } from 'utils/appWarnings';

const RegExtrasContext = createContext({});

const success = {
  type: 'success',
};

export const RegExtrasProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [regExtras, setRegExtras] = React.useState([]);
  const [regExtrasLoading, setRegExtrasLoading] = React.useState(false);
  const [regExtra, setRegExtra] = React.useState({});
  const [regExtraLoading, setRegExtraLoading] = React.useState(false);
  const [isRemoveRegExtra, setRemoveRegExtra] = React.useState(false);

  const getRegExtras = useCallback(async (activityId) => {
    try {
      setRegExtrasLoading(true);
      const response = await api.regAtividade().getRegExtras(activityId);
      setRegExtras(response);
    } catch (errors) {
    } finally {
      setRegExtrasLoading(false);
    }
  }, []);

  const getRegExtra = useCallback(async (registroId, id) => {
    try {
      setRegExtraLoading(true);
      const response = await api.regAtividade().getRegExtra(registroId, id);
      setRegExtra(response);
      setRegExtraLoading(false);
    } catch (errors) {
      setRegExtraLoading(false);
    }
  }, []);

  const insertRegExtra = useCallback(
    async (registroId, data) => {
      try {
        setRegExtraLoading(true);
        const formattedData = {
          extras: data?.map((item) => ({
            id: item?.extraId,
            descricao: item?.descricao,
            obs: item?.obs,
            quantidade: item?.quantidade,
            total: item?.total,
            valor: item?.valor,
          })),
        };
        await api.regAtividade().insertRegExtra(registroId, formattedData);
        getRegExtras(registroId);
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setRegExtraLoading(false);
      }
    },
    [showToast, getRegExtras],
  );

  const removeRegExtra = useCallback(
    async (activityId, extraId) => {
      try {
        setRemoveRegExtra(true);
        await api.regAtividade().removeRegExtra(activityId, extraId);
        showToast({ ...success, message: 'Custo extra excluído com sucesso!' });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setRemoveRegExtra(false);
      }
    },
    [showToast],
  );

  return (
    <RegExtrasContext.Provider
      value={{
        getRegExtras,
        regExtras,
        regExtrasLoading,
        regExtra,
        regExtraLoading,
        getRegExtra,
        insertRegExtra,
        removeRegExtra,
        isRemoveRegExtra,
        setRegExtras,
        setRegExtraLoading,
      }}
    >
      {children}
    </RegExtrasContext.Provider>
  );
};

export function useRegExtra() {
  const context = useContext(RegExtrasContext);

  if (!context)
    throw new Error('useRegExtra must be used within an useRegExtra');

  return context;
}
