import React from 'react';

import { AuthProvider } from './auth';
import { FavoritesProvider } from './favorites';
import { TabsProvider } from './tabs';
import { DashProvider } from './dashboard';
import { ToastProvider } from './toast';
import { NewToastProvider } from './newToast';
import { PrintProvider } from './PrintHook';
import { MenuProvider } from 'hooks/menu';
import { ColaboradoresProvider } from 'hooks/contexts/colaboradores';
import { ApontamentoProvider } from 'hooks/contexts/apontamento';
import { LoteProvider } from 'hooks/providers/rastreabilidade/mock';
import { CadastrosProvider } from './providers/cadastros/index';
import { ControleProvider } from './providers/controles/index';
import FinanceiroProvider from './providers/financeiro/index';
import RelatoriosProvider from './providers/relatorios';
import ModaisProvider from 'hooks/modais';

import { SelectQuantidadeProvider } from 'components/SelectWithManagement/SelectQualidadeCafe/context/selectQualidade';

const AppProvider = ({ children }) => (
  <ToastProvider>
    <NewToastProvider>
      <AuthProvider>
        <FavoritesProvider>
          <MenuProvider>
            <ModaisProvider>
              <DashProvider>
                <SelectQuantidadeProvider>
                  <CadastrosProvider>
                    <FinanceiroProvider>
                      <ColaboradoresProvider>
                        <ApontamentoProvider>
                          <ControleProvider>
                            <TabsProvider>
                              <LoteProvider>
                                <PrintProvider>
                                  <RelatoriosProvider>
                                    {children}
                                  </RelatoriosProvider>
                                </PrintProvider>
                              </LoteProvider>
                            </TabsProvider>
                          </ControleProvider>
                        </ApontamentoProvider>
                      </ColaboradoresProvider>
                    </FinanceiroProvider>
                  </CadastrosProvider>
                </SelectQuantidadeProvider>
              </DashProvider>
            </ModaisProvider>
          </MenuProvider>
        </FavoritesProvider>
      </AuthProvider>
    </NewToastProvider>
  </ToastProvider>
);

export default AppProvider;
