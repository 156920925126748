export class RebeneficioFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getRebeneficios(filter) {
    return this.instance.get(`v1/rastreabilidade/rebeneficio`, {
      params: filter || null,
    });
  }

  getRebeneficio(id) {
    return this.instance.get(`v1/rastreabilidade/rebeneficio/${id}`);
  }

  gerarRebeneficio(data) {
    return this.instance.post(`v1/rastreabilidade/rebeneficio`, data);
  }

  editRebeneficio(id, data) {
    return this.instance.put(`v1/rastreabilidade/rebeneficio/${id}`, data);
  }

  deleteRebeneficio(id) {
    return this.instance.delete(`v1/rastreabilidade/rebeneficio/${id}`);
  }

  deleteEscolha(idRebeneficio, id) {
    return this.instance.delete(
      `v1/rastreabilidade/rebeneficio/${idRebeneficio}/escolha/${id}`
    );
  }

  deleteAdicional(idRebeneficio, id) {
    return this.instance.delete(
      `v1/rastreabilidade/rebeneficio/${idRebeneficio}/adicional/${id}`
    );
  }
}

export default (...params) => new RebeneficioFactory(...params);
