import React, { useState, useContext, createContext, useCallback } from 'react';
const PrintHandleContext = createContext({});

export const PrintProvider = ({ children }) => {
  const [printState, setPrintState] = useState({
    id: '',
  });

  const printHandle = useCallback(value => {
    setPrintState(() => ({ id: value }));
  }, []);

  const resetPrint = useCallback(() => {
    setPrintState({ id: '' });
  }, []);

  return (
    <PrintHandleContext.Provider
      value={{
        printState,
        printHandle,
        resetPrint,
      }}>
      {children}
    </PrintHandleContext.Provider>
  );
};

export function usePrint() {
  const context = useContext(PrintHandleContext);
  return context;
}
