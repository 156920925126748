export class RelatoriosSafrasFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getCustosTalhoesSafra(filter) {
    return this.instance.get('/v1/relatorios/talhoes/safras', {
      params: filter || null,
    });
  }

  getCustosTalhoesSafraById(id, filter) {
    return this.instance.get(`/v1/relatorios/talhoes/safras/${id}`, {
      params: filter || null,
    });
  }

  getColhidoTalhoesSafra(filter) {
    return this.instance.get('/v1/relatorios/talhoes/colhido', {
      params: filter || null,
    });
  }

  getColhidoTalhoesSafraById(id, filter) {
    return this.instance.get(`/v1/relatorios/talhoes/colhido/${id}`, {
      params: filter || null,
    });
  }

  getAtividadesTalhoesSafra(id, filter) {
    return this.instance.get(`/v1/relatorios/talhoes/atividades/${id}`, {
      params: filter || null,
    });
  }

  getRelAtividades(filter) {
    return this.instance.get(`/v1/relatorios/talhoes/atividades/`, {
      params: filter || null,
    });
  }
}

export default (...params) => new RelatoriosSafrasFactory(...params);
