export class CaldaFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getCaldas(filter) {
    return this.instance.get('/v1/cad-caldas/', {
      params: filter || null,
    });
  }

  getCalda(id, filter) {
    return this.instance.get(`/v1/cad-caldas/${id}`, {
      params: filter || null,
    });
  }

  addCalda(data) {
    return this.instance.post('/v1/cad-caldas', data);
  }

  patchCalda(id, data) {
    return this.instance.patch(`/v1/cad-caldas/${id}`, data);
  }

  editCalda(id, data) {
    return this.instance.put(`/v1/cad-caldas/${id}`, data);
  }

  getCaldaComposicao(id, filter) {
    return this.instance.get(`/v1/cad-caldas/${id}/composicoes`, {
      params: filter || null,
    });
  }

  getCaldaHistorico(id, filter) {
    return this.instance.get(`/v1/cad-caldas/${id}/utilizacao`, {
      params: filter || null,
    });
  }

  delCalda(id) {
    return this.instance.delete(`/v1/cad-caldas/${id}`);
  }

  delInsumoInCalda(idCalda, idInsumo) {
    return this.instance.delete(`/v1/cad-caldas/${idCalda}/composicoes/${idInsumo}`);
  }

  getInsumosByCalda(id, filter) {
    return this.instance.get(`/v1/cad-caldas/${id}/composicoes`, {
      params: filter || null,
    });
  }
}

export default (...params) => new CaldaFactory(...params);
