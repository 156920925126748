export class Colaborador {
  constructor(instance) {
    this.instance = instance;
  }

  idColaboradores(id) {
    return this.instance.get(`v1/cad-colaboradores/${id}`, {
      params: id,
    });
  }
}

export default (...params) => new Colaborador(...params);
