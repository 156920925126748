import React, { useCallback, useState, useContext, createContext } from 'react';

const ModalFuncaoContext = createContext({});

export const ModalFuncaoProvider = ({ children }) => {
  const [showModalFuncao, setShowModalFuncao] = useState(false);

  const openModalFuncao = useCallback(() => {
    setShowModalFuncao(true);
  }, []);

  const closeModalFuncao = useCallback(() => {
    setShowModalFuncao(false);
  }, []);

  return (
    <ModalFuncaoContext.Provider
      value={{
        showModalFuncao,
        openModalFuncao,
        closeModalFuncao,
      }}>
      {children}
    </ModalFuncaoContext.Provider>
  );
};

export function useModalFuncao() {
  const context = useContext(ModalFuncaoContext);

  if (!context) throw new Error('useModalFuncao must be used within an Config Provider');

  return context;
}
