export class EstimativaFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getEstimativaTotais(filter) {
    return this.instance.get(`/v1/planejamentos/orcamentos/totais`, {
      params: filter || null,
    });
  }

  getEstimativaVenda(filter) {
    return this.instance.get(`/v1/estimativa-venda`, {
      params: filter || null,
    });
  }

  deleteEstimativaVenda(id) {
    return this.instance.delete(`/v1/estimativa-venda/${id}`);
  }

  putEstimativaVenda(data) {
    return this.instance.put('/v1/estimativa-venda', data);
  }

  getEstimativaAvulso(filter) {
    return this.instance.get(`/v1/estimativa-receita-avulsa`, {
      params: filter || null,
    });
  }

  deleteEstimativaAvulso(id) {
    return this.instance.delete(`/v1/estimativa-receita-avulsa/${id}`);
  }

  putEstimativaAvulso(data) {
    return this.instance.put('/v1/estimativa-receita-avulsa', data);
  }

  getEstimativaColheita(filter) {
    return this.instance.get(`/v1/estimativa-colheita`, {
      params: filter || null,
    });
  }

  deleteEstimativaColheita(id) {
    return this.instance.delete(`/v1/estimativa-colheita/${id}`);
  }

  putEstimativaColheita(data) {
    return this.instance.put('/v1/estimativa-colheita', data);
  }
}

export default (...params) => new EstimativaFactory(...params);
