import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { appWarnings } from 'utils/appWarnings';
import { useNewToast } from 'hooks/newToast';

const RegColheitaMecanicaContext = createContext({});

const success = {
  type: 'success',
};

export const RegColheitaMecanicaProvider = ({ children }) => {
  const { showToast } = useNewToast();
  const [regMdobrasColheitaMecanica, setRegMdobrasColheitaMecanica] = useState(
    [],
  );
  const [
    regMdobrasColheitaMecanicaLoading,
    setRegMdobrasColheitaMecanicaLoading,
  ] = useState(false);
  const [regMdobraColheitaLoading, setRegMdobraColheitaLoading] =
    useState(false);
  const [isRemoveRegMdobrasColheita, setIsRemoveRegMdobrasColheita] =
    useState(false);

  const getRegMdobrasColheitaMecanica = useCallback(async (activityId) => {
    try {
      setRegMdobrasColheitaMecanicaLoading(true);
      const response = await api
        .regAtividade()
        .getRegMdobrasColheitaMecanica(activityId);
      setRegMdobrasColheitaMecanica(response);
    } catch (errors) {
    } finally {
      setRegMdobrasColheitaMecanicaLoading(false);
    }
  }, []);

  const insertRegColheitaMecanica = useCallback(
    async (registroId, data) => {
      try {
        setRegMdobraColheitaLoading(true);
        const formattedData = {
          vinculos: data?.map((item) => ({
            id: item?.mechanicalHarvestingId,
            colhido: item?.colhido,
            medida: item?.medida,
            tipo: item?.tipo?.id,
            valor: item?.valor,
            maquina: item?.maquina?.id,
          })),
        };
        await api
          .regAtividade()
          .insertRegColheitaMecanica(registroId, formattedData);
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setRegMdobraColheitaLoading(false);
      }
    },
    [showToast],
  );

  const removeRegMdobrasColheita = useCallback(
    async (activityId, mechanicalHarvestingId) => {
      try {
        setIsRemoveRegMdobrasColheita(true);
        await api
          .regAtividade()
          .removeRegMdobrasColheita(activityId, mechanicalHarvestingId);
        showToast({
          ...success,
          message: 'Custo de colheita mecânica excluído com sucesso!',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setIsRemoveRegMdobrasColheita(false);
      }
    },
    [showToast],
  );

  return (
    <RegColheitaMecanicaContext.Provider
      value={{
        regMdobrasColheitaMecanica,
        regMdobrasColheitaMecanicaLoading,
        getRegMdobrasColheitaMecanica,
        insertRegColheitaMecanica,
        removeRegMdobrasColheita,
        setRegMdobraColheitaLoading,
        regMdobraColheitaLoading,
        setRegMdobrasColheitaMecanica,
        isRemoveRegMdobrasColheita,
      }}
    >
      {children}
    </RegColheitaMecanicaContext.Provider>
  );
};

export function useRegColheitaMecanica() {
  const context = useContext(RegColheitaMecanicaContext);

  if (!context)
    throw new Error('useRegAtividade must be used within an RegAtividades');

  return context;
}
