import React, { useCallback, useState, useContext, createContext } from 'react';

const ModalTalhaoContext = createContext({});

export const ModalTalhaoProvider = ({ children }) => {
  const [showModalTalhao, setShowModalTalhao] = useState(false);

  const openModalTalhao = useCallback(() => {
    setShowModalTalhao(true);
  }, []);

  const closeModalTalhao = useCallback(() => {
    setShowModalTalhao(false);
  }, []);

  return (
    <ModalTalhaoContext.Provider
      value={{
        showModalTalhao,
        openModalTalhao,
        closeModalTalhao,
      }}>
      {children}
    </ModalTalhaoContext.Provider>
  );
};

export function useTalhaoModal() {
  const context = useContext(ModalTalhaoContext);

  if (!context) throw new Error('useTalhaoModal must be used within an Config Provider');

  return context;
}
