/* eslint-disable react-hooks/exhaustive-deps */
import { useNewToast } from 'hooks/newToast';
import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { toDate } from 'utils/converters';

const TerreiroContext = createContext({});

export const TerreiroProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [terreirosLotes, setTerreirosLotes] = useState([]);
  const [terreirosLotesLoading, setTerreirosLotesLoading] = useState(false);

  const [terreiroLotes, setTerreiroLotes] = useState([]);
  const [terreiroLotesLoading, setTerreiroLotesLoading] = useState(false);

  const [terreiroLotesHistorico, setTerreiroLotesHistorico] = useState({});
  const [terreiroLotesHistoricoLoading, setTerreiroLotesHistoricoLoading] = useState(false);

  const getTerreirosLotes = useCallback(async filter => {
    try {
      setTerreirosLotesLoading(true);
      const response = await api.terreiro().getTerreirosLotes(filter);
      setTerreirosLotes(response);
      setTerreirosLotesLoading(false);
    } catch (errors) {
      setTerreirosLotesLoading(false);
    }
  }, []);

  const getTerreiroLotes = useCallback(async id => {
    try {
      setTerreiroLotesLoading(true);
      const response = await api.terreiro().getTerreiroLotes(id);
      setTerreiroLotes(response);
      setTerreiroLotesLoading(false);
    } catch (errors) {
      setTerreiroLotesLoading(false);
    }
  }, []);

  const [loteNoTerreiro, setLoteNoTerreiro] = React.useState({});
  const [loteNoTerreiroLoading, setLoteNoTerreiroLoading] = React.useState({});

  const getLoteNoTerreiro = useCallback(async idLote => {
    try {
      setLoteNoTerreiroLoading(true);
      const response = await api.terreiro().getLoteNoTerreiro(idLote);
      setLoteNoTerreiro(response);
      setLoteNoTerreiroLoading(false);
    } catch (errors) {
      setLoteNoTerreiroLoading(false);
    }
  }, []);

  const getTerreiroHistorico = useCallback(async (id, filter) => {
    try {
      setTerreiroLotesHistoricoLoading(true);
      const response = await api.terreiro().getTerreiroHistorico(id, filter);
      setTerreiroLotesHistorico(response);
      setTerreiroLotesHistoricoLoading(false);
      return response;
    } catch (errors) {
      setTerreiroLotesHistoricoLoading(false);
    }
  }, []);

  const [gerarNoTerreiroLoading, setGerarNoTerreiroLoading] = useState(false);
  const gerarNoTerreiro = useCallback(async dados => {
    const { data_entrada, data_saida, hora_entrada, hora_saida, responsavel, terreiro } = dados.dados;

    const newData = {
      terreiro: terreiro?.id,
      lote: dados.escolherLotes.map(item => ({
        id: item.id,
        codigo: item.codigo,
      })),
      data_entrada: toDate(data_entrada),
      data_saida: data_saida && toDate(data_saida),
      hora_entrada,
      hora_saida,
      responsavel: responsavel,
    };

    try {
      setGerarNoTerreiroLoading(true);
      const response = await api.terreiro().gerarNoTerreiro(newData);
      setGerarNoTerreiroLoading(false);
      showToast({
        type: 'success',
        message: 'Entrada no terreiro gerada com sucesso!',
      });
      return response;
    } catch (err) {
      showToast({
        type: 'error',
        message: 'Não foi possível gerar a entrada no terreiro!',
      });
      setGerarNoTerreiroLoading(false);
      throw new Error(err);
    }
  }, []);

  const [editarNoTerreiroLoading, setEditarNoTerreiroLoading] = useState(false);
  const editarNoTerreiro = useCallback(async (id, dados) => {
    const { data_entrada, data_saida, hora_entrada, hora_saida, responsavel, terreiro } = dados.dados;

    const newData = {
      terreiro: terreiro?.id,
      data_entrada: toDate(data_entrada),
      hora_entrada,
      data_saida: data_saida && toDate(data_saida),
      hora_saida,
      responsavel: responsavel,
    };

    try {
      setEditarNoTerreiroLoading(true);
      const response = await api.terreiro().editarNoTerreiro(id, newData);
      setEditarNoTerreiroLoading(false);
      showToast({
        type: 'success',
        message: 'Entrada no terreiro editada com sucesso!',
      });
      return response;
    } catch (err) {
      showToast({
        type: 'error',
        message: 'Não foi possível editar a entrada no terreiro!',
      });
      setEditarNoTerreiroLoading(false);
      throw new Error(err);
    }
  }, []);

  const [deleteNoTerreiroLoading, setDeleteNoTerreiroLoading] = useState(false);
  const deleteNoTerreiro = useCallback(
    async id => {
      try {
        setDeleteNoTerreiroLoading(true);
        await api.terreiro().deleteNoTerreiro(id);
        setTerreiroLotes(terreirosLotes.filter(item => item.id !== id));
        setDeleteNoTerreiroLoading(false);
        getTerreirosLotes();
        showToast({
          type: 'success',
          message: 'Processo de terreiro removido com sucesso!',
        });
      } catch (err) {
        showToast({
          type: 'error',
          message: 'Não foi possível deletar o processo de terreiro!',
        });
        setDeleteNoTerreiroLoading(false);
        throw new Error(err);
      }
    },
    [terreirosLotes]
  );

  const [historicoTalhoes, setHistoricoTalhoes] = React.useState([]);
  const [historicoTalhoesLoading, setHistoricoTalhoesLoading] = React.useState([]);

  const listHistoricoTalhoes = useCallback(
    async filters => {
      try {
        setHistoricoTalhoesLoading(true);
        const response = await api.terreiro().listHistoricoTerreiros(filters);
        setHistoricoTalhoes(response);
        setHistoricoTalhoesLoading(false);
      } catch {
        setHistoricoTalhoesLoading(false);
      }
    },
    [terreirosLotes]
  );

  return (
    <TerreiroContext.Provider
      value={{
        terreirosLotes,
        terreirosLotesLoading,
        getTerreirosLotes,
        terreiroLotes,
        terreiroLotesLoading,
        getTerreiroLotes,
        terreiroLotesHistorico,
        terreiroLotesHistoricoLoading,
        gerarNoTerreiroLoading,
        gerarNoTerreiro,
        getTerreiroHistorico,
        deleteNoTerreiroLoading,
        deleteNoTerreiro,
        editarNoTerreiro,
        editarNoTerreiroLoading,
        loteNoTerreiro,
        loteNoTerreiroLoading,
        getLoteNoTerreiro,
        listHistoricoTalhoes,
        historicoTalhoes,
        historicoTalhoesLoading,
        setLoteNoTerreiro,
      }}>
      {children}
    </TerreiroContext.Provider>
  );
};

export function useTerreiro() {
  const context = useContext(TerreiroContext);

  if (!context) throw new Error('useTerreiro must be used within an AuthProvider');

  return context;
}
