export class CadArmazemFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getArmazens(filter) {
    return this.instance.get('/v1/cad-armazens/', {
      params: filter || null,
    });
  }

  getArmazem(filter, id) {
    return this.instance.get(`/v1/cad-armazens/${id}`, {
      params: filter || null,
    });
  }

  addArmazem(data) {
    return this.instance.post(`/v1/cad-armazens/`, data);
  }

  editArmazem(id, data) {
    return this.instance.put(`/v1/cad-armazens/${id}`, data);
  }

  deleteArmazem(id) {
    return this.instance.delete(`/v1/cad-armazens/${id}`);
  }
}

export default (...params) => new CadArmazemFactory(...params);
