import { toDate } from 'utils/converters';

const formatSecador = dados => {
  const { data_entrada, data_saida, hora_entrada, hora_saida, responsavel, secador, umidade_entrada, umidade_saida } =
    dados.dados;

  const newData = {
    secador: secador?.id,
    lote: dados?.escolherLotes?.map(item => {
      return {
        id: item.idLote,
        codigo: item.codigo,
        quantidade: item.quantidade,
      };
    }),
    dataEntrada: toDate(data_entrada),
    dataSaida: data_saida && toDate(data_saida),
    horaEntrada: hora_entrada,
    horaSaida: hora_saida,
    umidadeEntrada: Number(umidade_entrada),
    umidadeSaida: Number(umidade_saida),
    responsavel: responsavel,
  };

  return newData;
};

export { formatSecador };
