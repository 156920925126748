/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useCallback,
  useContext,
  createContext,
  useEffect,
} from 'react';
import api from 'services';
import formatNumber from 'utils/formatNumber';

import { useDash } from 'hooks/dashboard';
import { useFazenda } from 'hooks/providers/cadastros/fazendas';
import { useNewToast } from 'hooks/newToast';
import { useAuth } from 'hooks/auth';
import { appWarnings } from 'utils/appWarnings';
import { resetRegAtividade } from 'utils/appLocalStorage';

const talhoesDefault = {
  content: [],
  totais: {},
};

const success = {
  message: '',
  type: 'success',
};

const TalhaoContext = createContext({});

export const TalhaoProvider = ({ children }) => {
  const { isAuth } = useAuth();
  const { showToast } = useNewToast();

  const { changeTab } = useDash();

  const [talhoes, setTalhoes] = useState(talhoesDefault);
  const [talhoesReduced, setTalhoesReduced] = useState([]);
  const [talhoesReducedLoading, setTalhoesReducedLoading] = useState(false);
  const [talhoesReducedCoords, setTalhoesReducedCoords] = useState([]);

  const [talhoesLoading, setTalhoesLoading] = useState(true);
  const [talhao, setTalhao] = useState({});
  const [talhaoLoading, setTalhaoLoading] = useState(false);
  const [talhaoRemoveLoading, setTalhaoRemoveLoading] = useState(false);

  const [atividadesByTalhao, setAtividadesByTalhao] = useState({});
  const [atividadesByTalhaoLoading, setAtividadesByTalhaoLoading] =
    useState(false);

  const [insumosTalhao, setInsumosTalhao] = useState([]);
  const [insumosTalhaoLoading, setInsumosTalhaoLoading] = useState(false);

  const [mdobrasTalhao, setMdobrasTalhao] = useState([]);
  const [colheitaTalhao, setColheitaTalhao] = useState([]);
  const [mdobrasTalhaoLoading, setMdobrasTalhaoLoading] = useState(false);
  const [colheitaTalhaoLoading, setColheitaTalhaoLoading] = useState(false);

  const [caldasTalhao, setCaldasTalhao] = useState([]);
  const [caldasTalhaoLoading, setCaldasTalhaoLoading] = useState(false);

  const [insertTalhaoLoading, setInsertTalhaoLoading] = useState(false);

  const [relRegAtividades, setRelRegAtividades] = useState([]);
  const [relRegAtividadesLoading, setRelRegAtividadesLoading] = useState(false);
  const [isCoordLoading, setIsCoordLoading] = useState(false);

  const { fazendas, setFazendaAtiva } = useFazenda();

  const down = useCallback(() => {
    setTalhoes(talhoesDefault);
    setTalhoesReduced([]);
    setInsumosTalhao([]);
    setMdobrasTalhao([]);
    setCaldasTalhao([]);
    setAtividadesByTalhao([]);
  }, []);

  const getTalhoesReduced = useCallback(async (filters) => {
    try {
      setTalhoesReducedLoading(true);
      const response = await api
        .talhao()
        .getTalhoes({ ...filters, reduced: 1 });
      setTalhoesReduced(response);
      return response;
    } catch (err) {
    } finally {
      setTalhoesReducedLoading(false);
    }
  }, []);

  const getTalhoes = useCallback(async (filter) => {
    try {
      setTalhoesLoading(true);
      const response = await api.talhao().getTalhoes(filter);
      if (!filter || !filter.reduced) {
        setTalhoes(response);
      } else {
        setTalhoesReducedCoords(response);
      }
    } catch (errors) {
    } finally {
      setTalhoesLoading(false);
    }
  }, []);

  const getTalhao = useCallback(async (id, filter) => {
    try {
      setTalhaoLoading(true);
      const response = await api.talhao().getTalhao(id, filter);
      setTalhao(response);

      setTalhaoLoading(false);
    } catch (errors) {
      setTalhaoLoading(false);
    }
  }, []);

  const insertTalhao = useCallback(
    async (data) => {
      const {
        nome,
        numero,
        fazenda,
        safras,
        produto,
        dataPlantio,
        variedade,
        espX,
        espY,
        areaHa,
        plantasContadas,
        obs,
        coords,
        coordsZoom,
      } = data.sobre;

      const newSafras = safras.map((safra) => safra.id);

      const newData = {
        nome,
        numero,
        safras: newSafras,
        fazenda: fazenda?.id,
        produto: produto?.id,
        dataPlantio,
        variedade,
        espX,
        espY,
        areaHa: areaHa || 0,
        plantasContadas: formatNumber(plantasContadas || 0),
        obs,
        coords,
        coordsZoom,
      };

      try {
        setInsertTalhaoLoading(true);
        const response = await api.talhao().insertTalhao(newData);
        setTalhoes((old) => ({ ...old, content: [response] }));
        getTalhoesReduced({ safras: 1 });
        changeTab(2);
        setInsertTalhaoLoading(false);
        resetRegAtividade();
        showToast({ ...success, message: 'Talhão adicionado com sucesso!' });
      } catch (err) {
        setInsertTalhaoLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error('trazendo error');
      }
    },
    [getTalhoesReduced],
  );

  const insertTalhaoTutorial = useCallback(async (data, safra, fazenda) => {
    try {
      setInsertTalhaoLoading(true);
      const { nome, numero } = data;
      const newData = {
        nome: nome?.toUpperCase(),
        numero,
        fazenda: fazenda,
        safras: [safra],
        produto: 100,
      };
      const response = await api.talhao().insertTalhao(newData);
      setTalhoes((old) => ({ ...old, content: [response] }));
      resetRegAtividade();
      setInsertTalhaoLoading(false);
      showToast({ ...success, message: 'Talhão adicionado com sucesso!' });
    } catch (err) {
      setInsertTalhaoLoading(false);
      showToast(appWarnings.Danger(err));
      throw new Error('trazendo error');
    }
  }, []);

  const updateTalhao = useCallback(async (id, data) => {
    const {
      nome,
      numero,
      fazenda,
      safras,
      produto,
      dataPlantio,
      variedade,
      espX,
      espY,
      areaHa,
      plantasContadas,
      obs,
      coords,
      coordsZoom,
    } = data.sobre;

    setInsertTalhaoLoading(true);

    const newSafras = safras.map((safra) => safra.id);

    const newData = {
      nome,
      numero,
      safras: newSafras,
      fazenda: fazenda?.id,
      produto: produto?.id,
      dataPlantio,
      variedade,
      espX,
      espY,
      areaHa,
      plantasContadas,
      obs,
      coords,
      coordsZoom,
    };

    resetRegAtividade();

    try {
      setTalhaoLoading(true);
      await api.talhao().editTalhao(id, newData);
      const tempFazenda = fazendas.find((item) => item.id === fazenda?.id);
      setFazendaAtiva(tempFazenda);
      setTalhaoLoading(false);
      setInsertTalhaoLoading(false);
      getTalhoes({ fazenda: fazenda?.id });
      showToast({ ...success, message: 'Talhão editado com sucesso!' });
    } catch (err) {
      setTalhaoLoading(false);
      setInsertTalhaoLoading(false);
      showToast(appWarnings.Danger(err));
      throw new Error('trazendo error');
    }
  });

  const removeTalhao = useCallback(async (id) => {
    try {
      setTalhaoRemoveLoading(true);
      await api.talhao().removeTalhao(id);
      resetRegAtividade();
      setTalhoes((old) => ({
        ...old,
        content: old.content?.filter((item) => item.id !== id),
      }));
      setTalhoesReduced((old) => [...old?.filter((item) => item.id !== id)]);
      setTalhaoRemoveLoading(false);
      showToast({ ...success, message: 'Talhão removido com sucesso!' });
    } catch (err) {
      setTalhaoRemoveLoading(false);
      showToast(appWarnings.Danger(err));
      throw new Error();
    }
  }, []);

  const getInsumosByTalhao = useCallback(async (id, filter) => {
    try {
      setInsumosTalhaoLoading(true);
      const response = await api.talhao().getInsumosByTalhao(id, filter);
      setInsumosTalhao(response);
      setInsumosTalhaoLoading(false);
    } catch (err) {
      setInsumosTalhaoLoading(false);
    }
  }, []);

  const getMdobrasByTalhao = useCallback(async (id, filter) => {
    try {
      setColheitaTalhaoLoading(true);
      const response = await api.talhao().getMdoByTalhao(id, filter);
      setMdobrasTalhao(response);
    } catch (err) {
    } finally {
      setColheitaTalhaoLoading(false);
    }
  }, []);

  const getColheitaByTalhao = useCallback(async (id, filter) => {
    try {
      setMdobrasTalhaoLoading(true);
      const response = await api.talhao().getColheitaByTalhao(id, filter);
      setColheitaTalhao(response);
      setMdobrasTalhaoLoading(false);
    } catch (err) {
      setMdobrasTalhaoLoading(false);
    }
  }, []);

  const getCaldasByTalhao = useCallback(async (id, filter) => {
    try {
      setCaldasTalhaoLoading(true);
      const response = await api.talhao().getCaldasByTalhao(id, filter);

      setCaldasTalhao(response);
      setCaldasTalhaoLoading(false);
    } catch (err) {
      setCaldasTalhaoLoading(false);
    }
  }, []);

  const getAtividadesByTalhao = useCallback(async (id, filter) => {
    try {
      setAtividadesByTalhaoLoading(true);
      const response = await api.talhao().getAtividadesByTalhao(id, filter);
      setAtividadesByTalhao(response);
      setAtividadesByTalhaoLoading(false);
    } catch (_) {
      setAtividadesByTalhaoLoading(false);
    }
  }, []);

  const addCoordLand = useCallback(async (id, data) => {
    try {
      setIsCoordLoading(true);
      const coord = {
        coords: data,
      };
      await api.talhao().addCoordTalhoes(id, coord);
      showToast({
        type: 'success',
        message: 'Coordenadas adicionadas com sucesso!',
      });
    } catch (err) {
      showToast(appWarnings.Danger(err));
      throw new Error();
    } finally {
      setIsCoordLoading(false);
    }
  }, []);

  const editCoordLand = useCallback(async (id, data) => {
    try {
      setIsCoordLoading(true);
      const coord = {
        coords: data,
      };
      await api.talhao().editCoordTalhoes(id, coord);
      showToast({
        type: 'success',
        message: 'Coordenadas editadas com sucesso!',
      });
    } catch (err) {
      showToast(appWarnings.Danger(err));
      throw new Error();
    } finally {
      setIsCoordLoading(true);
    }
  }, []);

  const delCoordTalhoes = useCallback(async ({ id, filter }) => {
    try {
      await api.talhao().delCoordTalhoes(id);
      showToast({
        type: 'success',
        message: 'Removido com sucesso!',
      });
      getTalhoes(filter);
    } catch (err) {
      showToast({
        type: 'error',
        message: 'Não foi possível remover!',
      });
    }
  }, []);

  const relRegAtividadesByAtividade = useCallback(async (id, filter) => {
    try {
      setRelRegAtividadesLoading(true);
      const response = await api
        .talhao()
        .relRegAtividadesByAtividade(id, filter);

      setRelRegAtividades(response);

      setRelRegAtividadesLoading(false);
    } catch {
      setRelRegAtividadesLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isAuth) {
      down();
    } else {
      getTalhoesReduced({ safras: 2, gerais: 1 });
    }
  }, [isAuth, getTalhoesReduced]);

  return (
    <TalhaoContext.Provider
      value={{
        delCoordTalhoes,
        talhoesReduced,
        getTalhoes,
        talhoes,
        talhoesReducedCoords,
        talhoesLoading,
        getTalhao,
        talhao,
        talhaoLoading,
        getAtividadesByTalhao,
        atividadesByTalhao,
        atividadesByTalhaoLoading,
        insertTalhao,
        insertTalhaoTutorial,
        updateTalhao,
        insertTalhaoLoading,
        removeTalhao,
        getInsumosByTalhao,
        insumosTalhao,
        insumosTalhaoLoading,
        getMdobrasByTalhao,
        mdobrasTalhao,
        mdobrasTalhaoLoading,
        getCaldasByTalhao,
        caldasTalhao,
        caldasTalhaoLoading,
        talhaoRemoveLoading,
        getTalhoesReduced,
        talhoesReducedLoading,
        relRegAtividadesByAtividade,
        relRegAtividades,
        relRegAtividadesLoading,
        setRelRegAtividades,
        setTalhao,
        setTalhoesReducedCoords,
        getColheitaByTalhao,
        colheitaTalhao,
        colheitaTalhaoLoading,
        addCoordLand,
        editCoordLand,
        isCoordLoading,
      }}
    >
      {children}
    </TalhaoContext.Provider>
  );
};

export function useTalhao() {
  const context = useContext(TalhaoContext);

  if (!context)
    throw new Error('useTalhao must be used within an AuthProvider');

  return context;
}
