export class RelAnualFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getRelDre(filter) {
    return this.instance.get('/v1/relatorios/dre/', {
      params: filter || null,
    });
  }
}

export default (...params) => new RelAnualFactory(...params);
