var lotesBeneficio = [
  {
    id: 1,
    codigo: 'MAR-21016',
    data_entrada: '2021-04-14',
    data_saida: '2021-04-18',
    hora_entrada: '14:00',
    hora_saida: '18:00',
    responsavel: 'LEONARDO',
    quantidade: '3000.00',
    perda: '0.00',
    medida: 'Sacas',
    medida_tara: '60.00',
    tipo: 'Boia',
    qualidade: 'RIO E RIADO',
    entradas: [
      {
        id: 1,
        codigo: 'S7-0405A',
        quantidade: '2200.00',
      },
      {
        id: 2,
        codigo: 'S8-0405B',
        quantidade: '600.00',
      },
      {
        id: 3,
        codigo: 'S9-0405C',
        quantidade: '900.00',
      },
      {
        id: 4,
        codigo: 'S4-0405D',
        quantidade: '1200.00',
      },
    ],
    quantidades: [
      {
        id: 1,
        tipo: 'Boia',
        qualidade: 'Bourbon',
        quantidade: '20.00',
      },
      {
        id: 2,
        tipo: 'Boinha',
        qualidade: 'Catuaí',
        quantidade: '56.00',
      },
      {
        id: 3,
        tipo: 'Natural',
        qualidade: 'Caturra',
        quantidade: '68.00',
      },
      {
        id: 4,
        tipo: 'Cereja',
        qualidade: 'Topázio',
        quantidade: '101.00',
      },
    ],
  },
];

export function db_escolherBeneficioList() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(lotesBeneficio);
    }, 1000);
  });
}

var beneficioList = [
  {
    id: '881',
    codigo: 'MAR-21016',
    entrada: '2021-04-14',
    hora_entrada: '14:00',
    hora_saida: '',
    responsavel: 'Jorge Miguel',
    quantidade: '2000.00',
    perda: '0.00',
    medida: 'Sacas',
    medida_tara: '60.00',
    tipo: 'Boia',
    qualidade: 'RIO E RIADO',
    entradas: [
      {
        id: 1,
        codigo: 'D1-0226A-Boia',
        quantidade: '76.00',
      },
      {
        id: 2,
        codigo: 'D1-0226A-Verde',
        quantidade: '500.00',
      },
    ],
    quantidades: [
      {
        id: 1,
        tipo: 'Boia',
        qualidade: 'Bourbon',
        quantidade: '320.00',
      },
      {
        id: 2,
        tipo: 'Boinha',
        qualidade: 'Fine Cup',
        quantidade: '450.00',
      },
    ],
  },
  {
    id: 2,
    codigo: 'MAR-20013',
    entrada: '2020-09-08',
    hora_entrada: '05:00',
    hora_saida: '04:00',
    responsavel: 'Lucas Maciel',
    quantidade: '3000.00',
    perda: '0.00',
    medida: 'Sacas',
    medida_tara: '60.00',
    tipo: 'Boia',
    qualidade: 'FINE CUP (DURO UNIFORME)',
    entradas: [
      {
        id: 1,
        codigo: 'D1-0226A-Boia',
        quantidade: '76.00',
      },
      {
        id: 2,
        codigo: 'D1-0226A-Verde',
        quantidade: '500.00',
      },
      {
        id: 3,
        codigo: 'D12-0304A-Cereja',
        quantidade: '5000.00',
      },
    ],
    quantidades: [
      {
        id: 1,
        tipo: 'Boia',
        qualidade: 'RIO E RIADO',
        quantidade: '3000.00',
      },
    ],
  },
];

export function db_loteBeneficioList() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(beneficioList);
    }, 1000);
  });
}
