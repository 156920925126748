const LOCAL_GLOBAL = '@AgrogerWeb';

const storage = {
  user: `${LOCAL_GLOBAL}:user`,
  token: `${LOCAL_GLOBAL}:token`,
  regAtividade: `${LOCAL_GLOBAL}:sobre`,
  colheita: `${LOCAL_GLOBAL}:colheita`,
  medidaPadrao: `${LOCAL_GLOBAL}:medidaPadrao`,
  regMdo: `${LOCAL_GLOBAL}:regMdo`,
  tutorial: `${LOCAL_GLOBAL}:tutorial`,
  folhaBuscarColaborador: `${LOCAL_GLOBAL}:folhaBuscarColaborador`,
  filtersFinancial: `${LOCAL_GLOBAL}:financialFilters`,
  filtersFinancialSearch: `${LOCAL_GLOBAL}:filtersFinancialSearch`,
  filtersFinancialPeriod: `${LOCAL_GLOBAL}:filtersFinancialPeriod`,
};

const resetStorages = () => {
  localStorage.removeItem(storage.user);
  localStorage.removeItem(storage.token);
  localStorage.removeItem(storage.regAtividade);
  localStorage.removeItem(storage.colheita);
  localStorage.removeItem(storage.medidaPadrao);
  localStorage.removeItem(storage.regMdo);
  localStorage.removeItem(storage.tutorial);
  localStorage.removeItem(storage.filtersFinancialSearch);
  localStorage.removeItem(storage.filtersFinancialPeriod);
  localStorage.removeItem(storage.filtersFinancial);
};

const resetRegAtividade = () => {
  localStorage.removeItem(storage.regAtividade);
};

export { LOCAL_GLOBAL, storage, resetStorages, resetRegAtividade };
