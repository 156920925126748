var loteSecadorList = [
  {
    id: 1,
    codigo: 'S1-0523A',
    entrada: '2020-05-23',
    saida: '2020-05-27',
    hora_saida: '16:00',
    hora_entrada: '12:00',
    umidade_entrada: 20,
    umidade_saida: 14,
    responsavel: 'José',
    entradas: [
      {
        id: 1,
        codigo: 'D1-0522A-Verde',
        quantidade: '1200',
      },
      {
        id: 2,
        codigo: 'D1-0522A-Boia',
        quantidade: '1500',
      },
    ],
  },
];

export function db_loteSecadorList() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(loteSecadorList);
    }, 1000);
  });
}

var escolherSecadorList = [
  {
    id: 1,
    codigo: "D1-0319A-Boinha",
    quantidade: "600.00",
  },
  {
    id: 2,
    codigo: "D1-0319A-Verde",
    quantidade: "200.00",
  },
  {
    id: 3,
    codigo: "D1-0319A-Boia",
    quantidade: "100.00",
  }
];

export function db_escolherSecadorList() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(escolherSecadorList);
    }, 1000);
  });
}

