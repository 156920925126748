export class TalhaoFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getTalhoes(filter) {
    return this.instance.get('/v1/cad-talhoes', {
      params: filter || null,
    });
  }

  insertTalhao(data) {
    return this.instance.post(`/v1/cad-talhoes/`, data);
  }

  editTalhao(id, data) {
    return this.instance.put(`/v1/cad-talhoes/${id}`, data);
  }

  removeTalhao(id) {
    return this.instance.delete(`/v1/cad-talhoes/${id}`);
  }

  getTalhao(id, filter) {
    return this.instance.get(`/v1/cad-talhoes/${id}`, {
      params: filter || null,
    });
  }

  getInsumosByTalhao(id, filter) {
    return this.instance.get(`/v1/cad-talhoes/${id}/insumos`, {
      params: filter || null,
    });
  }

  getMdoByTalhao(talhaoId, filter) {
    return this.instance.get(`/v1/cad-talhoes/${talhaoId}/mdobras`, {
      params: filter || null,
    });
  }
  getColheitaByTalhao(talhaoId, filter) {
    return this.instance.get(`/v1/cad-talhoes/${talhaoId}/colheita`, {
      params: filter || null,
    });
  }

  getCaldasByTalhao(talhaoId, filter) {
    return this.instance.get(`/v1/cad-talhoes/${talhaoId}/caldas`, {
      params: filter || null,
    });
  }
  getAtividadesByTalhao(id, filter) {
    return this.instance.get(`/v1/cad-talhoes/${id}/atividades`, {
      params: filter || null,
    });
  }
  addCoordTalhoes(id, data) {
    return this.instance.post(`/v1/cad-talhoes/${id}/coords`, data);
  }

  editCoordTalhoes(id, data) {
    return this.instance.put(`/v1/cad-talhoes/${id}/coords`, data);
  }

  delCoordTalhoes(id) {
    return this.instance.delete(`/v1/cad-talhoes/${id}/coords`);
  }

  relRegAtividadesByAtividade(id, filter) {
    return this.instance.get(`/v1/cad-talhoes/${id}/registros`, {
      params: filter || null,
    });
  }
}

export default (...params) => new TalhaoFactory(...params);
