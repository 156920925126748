export class FolhaColheitaFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getFolhasColheita(filter) {
    return this.instance.get('/v1/folha-pagamento-colheita', {
      params: filter || null,
    });
  }

  getFolhaColheita(id) {
    return this.instance.get(`/v1/folha-pagamento-colheita/${id}`);
  }

  insertFolhaColheita(data) {
    return this.instance.post(`/v1/folha-pagamento-colheita/`, data);
  }

  editFolhaColheita(id, data) {
    return this.instance.put(`v1/folha-pagamento-colheita/${id}`, data);
  }

  abrirFolhaColheita(id) {
    return this.instance.patch(`/v1/folha-pagamento-colheita/abrir/${id}`);
  }

  fecharFolhaColheita(id) {
    return this.instance.put(`/v1/folha-pagamento-colheita/trancar/${id}`);
  }

  removeFolhaColheita(id) {
    return this.instance.delete(`v1/folha-pagamento-colheita/${id}`);
  }

  getFolhaColheitaDetails(id, filter) {
    return this.instance.get(`/v1/colaborador-pagamento-colheita/${id}`, {
      params: filter || null,
    });
  }

  setPagamentoColheita(idFolha, idColaborador, data) {
    return this.instance.post(`/v1/colaborador-pagamentos/${idFolha}/${idColaborador}`, data);
  }

  editFolhaColheitaDetail(id, data, colaborador) {
    return this.instance.put(
      `/v1/colaborador-pagamento-colheita/${id}`,
      { valor: data.valor },
      {
        params:
          {
            colaborador,
          } || null,
      }
    );
  }
}

export default (...params) => new FolhaColheitaFactory(...params);
