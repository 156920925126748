import { toDate } from 'utils/converters';

const formatBeneficio = dados => {
  const { dataEntrada, perda, quantidadeTotal, switchAvulso } = dados.dados;

  const newData = {
    avulso: switchAvulso ? 1 : 0,
    dataEntrada: toDate(dataEntrada),
    escolha: dados?.escolha?.quantidadeEscolha && [
      {
        descricao: dados?.escolha?.qualidadeEscolha?.descricao,
        qualidade: dados?.escolha?.qualidadeEscolha?.id,
        quantidade: dados?.escolha?.quantidadeEscolha,
      },
    ],
    adicionais: dados.informacoesAdicionais
      ?.filter(item => !!item)
      ?.map(item => ({
        id: item.id,
        descricao: item.descricao,
        tipo: item.informacao?.descricao,
        resultado: item.resultado || '',
      })),
    lotes:
      !switchAvulso && dados?.escolherLotes?.length > 0
        ? dados.escolherLotes?.map(item => ({
            id: item.idLote || item.id,
            codigo: item.codigo,
            quantidade: Number(item.quantidade),
            ref_id: item.idLote || item.id,
          }))
        : [],
  };

  dados.dados.qualidade = dados.dados.qualidade.id || 0;
  dados.dados.tipo = dados.dados.tipo.nome || 0;
  dados.dados.quantidade = Number(quantidadeTotal || 0);
  dados.dados.perda = perda || 0;

  const tempData = { ...newData, ...dados.dados };
  return tempData;
};

export { formatBeneficio };
