import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { appWarnings } from 'utils/appWarnings';
import { useNewToast } from 'hooks/newToast';
const RegColheitaManualContext = createContext({});

const success = {
  type: 'success',
};

export const RegColheitaManualProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [regMdobrasColheita, setRegMdobrasColheita] = useState([]);
  const [regMdobrasColheitaLoading, setRegMdobrasColheitaLoading] =
    useState(false);
  const [regMdobraColheitaLoading, setRegMdobraColheitaLoading] =
    useState(false);
  const [removeRegMdobrasColheitaLoading, setRemoveRegMdobrasColheitaLoading] =
    useState(false);

  const getRegMdobrasColheita = useCallback(async (registroId) => {
    try {
      setRegMdobrasColheitaLoading(true);
      const response = await api
        .regAtividade()
        .getRegMdobrasColheita(registroId);
      setRegMdobrasColheita(response);
      setRegMdobrasColheitaLoading(false);
    } catch (errors) {
      setRegMdobrasColheitaLoading(false);
    }
  }, []);

  const insertRegColheitaManual = useCallback(
    async (registroId, data) => {
      try {
        setRegMdobraColheitaLoading(true);
        const formattedData = {
          vinculos: data?.map((item) => ({
            ...item,
            resto: item?.tipo?.id === 'L' ? item?.resto : 0,
            tipo: item?.tipo?.id,
            medida: item?.medida,
            valor: item?.valor,
            colaborador: item?.duplicate
              ? item?.glo_vinculo_id
              : item?.colaborador,
            colhido: item?.colhido,
          })),
        };
        await api.regAtividade().insertRegColheita(registroId, formattedData);
        getRegMdobrasColheita(registroId);
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setRegMdobraColheitaLoading(false);
      }
    },
    [showToast, getRegMdobrasColheita],
  );

  const removeRegMdobrasColheita = useCallback(
    async (activityId, id) => {
      try {
        setRemoveRegMdobrasColheitaLoading(true);
        await api.regAtividade().removeRegMdobrasColheita(activityId, id);
        if (id) {
          showToast({
            ...success,
            message: 'Colaborador excluído com sucesso!',
          });
        } else {
          showToast({
            ...success,
            message: 'Custo de colheita manual excluído com sucesso!',
          });
        }
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setRemoveRegMdobrasColheitaLoading(false);
      }
    },
    [showToast],
  );

  return (
    <RegColheitaManualContext.Provider
      value={{
        getRegMdobrasColheita,
        regMdobrasColheita,
        regMdobrasColheitaLoading,
        regMdobraColheitaLoading,
        removeRegMdobrasColheita,
        insertRegColheitaManual,
        setRegMdobraColheitaLoading,
        setRegMdobrasColheita,
        removeRegMdobrasColheitaLoading,
      }}
    >
      {children}
    </RegColheitaManualContext.Provider>
  );
};

export function useRegColheitaManual() {
  const context = useContext(RegColheitaManualContext);

  if (!context)
    throw new Error('useRegAtividade must be used within an RegAtividades');

  return context;
}
