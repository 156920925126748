export class PagamentoFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getPagamentos(filter) {
    return this.instance.get('/v1/cat-pagamentos', {
      params: filter,
    });
  }

  addPagamento(data) {
    return this.instance.post('/v1/cat-pagamentos', data);
  }

  editPagamento(id, data) {
    return this.instance.put(`/v1/cat-pagamentos/${id}`, data);
  }

  deletePagamento(id) {
    return this.instance.delete(`/v1/cat-pagamentos/${id}`);
  }
}

export default (...params) => new PagamentoFactory(...params);
