import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { appWarnings } from 'utils/appWarnings';
import { useNewToast } from 'hooks/newToast';

const success = {
  message: '',
  type: 'success',
};

const ApontamentoContext = createContext({});

export const ApontamentoProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [apontamentosLoading, setApontamentosLoading] = useState(false);
  const [apontamentos, setApontamentos] = useState([]);
  const [deleteApontamentoLoading, setDeleteApontamentoLoading] = React.useState(false);
  const [addApontamentoLoading, setAddApontamentosLoading] = React.useState(false);
  const [editApontamentoLoading, setEditApontamentoLoading] = React.useState(false);

  const getApontamentos = useCallback(async filter => {
    try {
      setApontamentosLoading(true);
      const response = await api.apontamento().getApontamentos(filter);
      setApontamentos(response);
    } catch (errors) {
    } finally {
      setApontamentosLoading(false);
    }
  }, []);

  const addApontamento = useCallback(
    async data => {
      try {
        setAddApontamentosLoading(true);
        const response = await api.apontamento().addApontamento(data);
        showToast({ ...success, message: 'Categoria de adiantamento adicionada com sucesso!' });
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setAddApontamentosLoading(false);
      }
    },
    [showToast]
  );

  const editApontamento = useCallback(
    async (id, data) => {
      try {
        setEditApontamentoLoading(true);
        const response = await api.apontamento().editApontamento(id, data);
        setEditApontamentoLoading(false);
        showToast({ ...success, message: 'Categoria de adiantamento editada com sucesso!' });
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setEditApontamentoLoading(false);
      }
    },
    [showToast]
  );

  const deleteApontamento = useCallback(
    async id => {
      try {
        setDeleteApontamentoLoading(true);
        await api.apontamento().deleteApontamento(id);
        showToast({ ...success, message: 'Categoria de adiantamento removida com sucesso!' });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setDeleteApontamentoLoading(false);
      }
    },
    [showToast]
  );

  return (
    <ApontamentoContext.Provider
      value={{
        apontamentos,
        apontamentosLoading,
        getApontamentos,
        addApontamento,
        addApontamentoLoading,
        editApontamento,
        editApontamentoLoading,
        deleteApontamento,
        deleteApontamentoLoading,
      }}>
      {children}
    </ApontamentoContext.Provider>
  );
};
export function useApontamento() {
  const context = useContext(ApontamentoContext);

  if (!context) throw new Error('useApontamento must be used within an AuthProvider');

  return context;
}
