import React from 'react';
import api from 'services';
// import { useNewToast } from 'hooks/newToast';

const RelInsumosContext = React.createContext({});

export const RelInsumoProvider = ({ children }) => {
  // const { showToast } = useNewToast();

  const [relInsumos, setRelInsumos] = React.useState({});
  const [relInsumosLoading, setRelInsumosLoading] = React.useState(false);

  const [insumoPorAtividades, setInsumoPorAtividades] = React.useState([]);
  const [insumoPorAtividadesLoading, setInsumoPorAtividadesLoading] = React.useState([]);

  const getRelInsumos = React.useCallback(async filter => {
    try {
      setRelInsumosLoading(true);
      const response = await api.relInsumos().getRelInsumos(filter);
      setRelInsumos(response);
      setRelInsumosLoading(false);
    } catch (errors) {
      setRelInsumosLoading(false);
    }
  }, []);

  const getRelInsumosByAtividade = React.useCallback(async (id, filter) => {
    try {
      setInsumoPorAtividadesLoading(true);
      const response = await api.relInsumos().getRelInsumosByAtividade(id, filter);
      setInsumoPorAtividades(response);
      setInsumoPorAtividadesLoading(false);
    } catch (errors) {
      setInsumoPorAtividadesLoading(false);
    }
  }, []);

  return (
    <RelInsumosContext.Provider
      value={{
        getRelInsumos,
        relInsumos,
        relInsumosLoading,
        getRelInsumosByAtividade,
        insumoPorAtividades,
        insumoPorAtividadesLoading,
      }}>
      {children}
    </RelInsumosContext.Provider>
  );
};

export function useRelInsumo() {
  const context = React.useContext(RelInsumosContext);

  if (!context) throw new Error('useRelInsumo must be used within an RelInsumoProvider');

  return context;
}
