export class RelAnualFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getRelAnual(filter) {
    return this.instance.get('/v1/relatorios/anual/cc', {
      params: filter || null,
    });
  }

  getRelAnualId(id, filter) {
    return this.instance.get(`/v1/relatorios/anual/cc/${id}`, {
      params: filter || null,
    });
  }

  getRelAnualByAtividades(id, filter) {
    return this.instance.get(`/v1/relatorios/anual/cc/${id}/atividades`, {
      params: filter || null,
    });
  }

  getRelAnualIndiretos(id, filter) {
    return this.instance.get(`/v1/relatorios/anual/cc/${id}/indiretos`, {
      params: filter || null,
    });
  }

  relInsumos(filter) {
    return this.instance.get('/v1/relatorios/anual/insumos', {
      params: filter || null,
    });
  }

  getInsumosPorTalhao(filter) {
    return this.instance.get('/v1/relatorios/anual/insumos-talhao', {
      params: filter || null,
    });
  }

  relMdo(filter) {
    return this.instance.get('/v1/relatorios/anual/mdo', {
      params: filter || null,
    });
  }

  relMdoColheita(filter) {
    return this.instance.get('/v1/relatorios/anual/mdocolheita', {
      params: filter || null,
    });
  }

  relMaquinas(filter) {
    return this.instance.get('/v1/relatorios/anual/maquinas', {
      params: filter || null,
    });
  }

  relAtividades(filter) {
    return this.instance.get('/v1/relatorios/anual/atividades', {
      params: filter || null,
    });
  }

  relDespesas(filter) {
    return this.instance.get('/v1/relatorios/anual/despesas', {
      params: filter || null,
    });
  }

  relCustos(filter) {
    return this.instance.get('/v1/relatorios/anual/custos', {
      params: filter || null,
    });
  }

  getRelAtividadesByTalhoes(cc_id, filters) {
    return this.instance.get(`/v1/relatorios/anual/cc/${cc_id}/talhoes`, {
      params: filters || null,
    });
  }

  getAnnualCosts(filters) {
    return this.instance.get(`/v1/relatorios/anual/last-months`, {
      params: filters || null,
    });
  }
}

export default (...params) => new RelAnualFactory(...params);
