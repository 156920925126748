/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import '../config';
import React from 'react';
import moment from 'moment';
import {
  addHours,
  format as Format,
  isValid,
  startOfMonth as StartOfMonth,
  endOfMonth as EndOfMonth,
  startOfYear as StartOfYear,
  endOfYear as EndOfYear,
  add,
  parse,
} from 'date-fns';

import 'moment/locale/pt-br';
import Tooltip from '@material-ui/core/Tooltip';

const toPrice = (val = null, minimum = null, maximum = null) => {
  const min = minimum || minimum === 0 ? minimum : 2;

  return new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: min,
    maximumFractionDigits: maximum || minimum || 2,
  }).format(Number(val));
};

const toPriceWhole = (val = null) => {
  return new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Number(val));
};

const toNumber = (val = '0') => {
  const valor = val.replace(/[.]/g, '').replace(/,/g, '.');
  return Number(valor || 0);
};

const toPrice2 = (val = null) =>
  new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(
    Number(val).toFixed(2),
  );

const toPriceValue = (val) =>
  new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 0 }).format(
    Number(val),
  );

const formatValor = (val) => val.toLocaleString('pt-br');

const safraConverter = (data) => {
  const inicio = data.inicio
    ? moment(data.inicio).format('YYYY')
    : moment().format('YYYY');
  const fim = data.fim
    ? moment(data.fim).format('YYYY')
    : moment().format('YYYY');
  const description = `SAFRA ${inicio}/${fim}`;
  return description;
};

const currencyRS = (value) => {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);

  return `${amount}`;
};

const toPriceInt = (value = 'R$') => {
  if (typeof value === 'number') return value;

  const queryString = value?.indexOf('R$');

  let newValue = 0;

  if (queryString >= 0) {
    const tempValue = value ? value.replace('R$', '') : 0;
    newValue =
      tempValue.length <= 6
        ? parseFloat(tempValue.replace(/,/g, '.')) || 0
        : parseFloat(tempValue.replace('.', '').replace(/,/g, '.')) || 0;
  } else {
    newValue =
      value.length <= 6
        ? parseFloat(value.replace(/,/g, '.')) || 0
        : parseFloat(value.replace('.', '').replace(/,/g, '.')) || 0;
  }

  return newValue || 0;
};

const formatNumber = (value) => {
  return value.toString().replace(/\./g, ',');
};
const formatHour = (value) => {
  return value.toString().replace(/\./g, ',');
};
const toPriceInt2 = (value) => {
  const newValue = value
    ? parseFloat(value.replace(/\./g, '').replace(/\,/g, '.'))
    : 0;
  return newValue;
};

const toPriceRS = (val = 0) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(val).toFixed(2));

const reducedDate = (date) => {
  return moment(date, '').format('DD/MM/YYYY').substr(0, 7);
};

const formatSizeUnits = (bytes) => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + ' GB';
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + ' MB';
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes > 1) {
    bytes = bytes + ' bytes';
  } else if (bytes === 1) {
    bytes = bytes + ' byte';
  } else {
    bytes = '0 bytes';
  }
  return bytes;
};

const formatDate = (dateString, _format) => {
  if (!dateString) return '';

  if (dateString.includes('/')) {
    dateString = dateString.replaceAll('/', '-');
  }

  const date_format = _format || 'date';

  const formats = {
    date: 'dd/MM/yyyy',
    dateTime: 'dd/MM/yyyy HH:mm:ss',
  }[date_format];

  if (!dateString || dateString.indexOf('0000') >= 0) {
    return format(new Date(), formats);
  }

  const isDatePTBR = dateString.indexOf('/');

  if (isDatePTBR >= 0) return dateString.substring(0, 10);

  const date = new Date(dateString);

  if (!isValid(date)) {
    return '';
  }

  const dateTimeZone = addHours(date, 3);
  const dateFormat = Format(dateTimeZone, formats);

  return dateFormat;
};

function formatDateInternacional(dateString) {
  if (!dateString) {
    return '';
  }
  const dateFormat = dateString.includes('/') ? 'yyyy/MM/dd' : 'yyyy-MM-dd';
  const parsedDate = parse(dateString, dateFormat, new Date());

  return isValid(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : '';
}

const formatToSetDate = (date) => {
  return moment(date, '').format('MM/DD/YYYY');
};

const formatDateMnY = (date) => {
  return moment(date).format('MM-YYYY');
};

const formatToDate = (date) => {
  moment.locale('pt-br');
  return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
};

const toDate = (date) => {
  if (date === 'Data inválida') return '';

  moment.locale('pt-br');
  return moment(date).format('YYYY-MM-DD');
};

const toNewDate = (data) => {
  return new Date(
    Date.parse(
      moment(data, 'YYYY-MM-DD').format('ddd MMM DD YYYY HH:mm:ss ZZ'),
    ),
  );
};

const toDateUS = (date) => {
  moment.locale('pt-br');
  return moment(date).format('YYYY/MM/DD');
};

const formatDate2 = (date) => {
  return moment.utc(date).format('DD/MM/YYYY HH:mm');
};

const formatDateHour = (dateString) => {
  if (!dateString || dateString.indexOf('0000') >= 0) {
    return format(new Date(), 'dd/MM/yyyy');
  }

  const isDatePTBR = dateString.indexOf('/');

  if (isDatePTBR >= 0) return dateString.substring(0, 10);

  const date = new Date(dateString);

  if (!isValid(date)) {
    return '';
  }

  const dateTimeZone = addHours(date, -3);
  const dateFormat = Format(dateTimeZone, 'dd/MM/yyyy HH:mm');
  return dateFormat;
};

const formatTime = (item) => {
  if (item.tipo === 'Dia') {
    return `${toPrice(item?.dias, 2, 2)} ${item.dias > 1 ? 'dias' : 'dia'}`;
  }

  const rhours = Math.floor(item.horas);
  const minutes = item?.minutos || Math.round((item.horas - rhours) * 60);

  return `${rhours}h ${minutes}m`;
};

const formatHours = (horas, minutos) => {
  let totalHoras = horas + Math.floor(minutos / 60);
  let totalMinutos = minutos % 60;

  return `${totalHoras} H ${totalMinutos} min`;
};

const formatDays = (dias) => {
  return `${toPrice(dias)} D`;
};

const formatColhido = (value, tipo, medida) => {
  const newValue = tipo === 'Kg' ? value : value * medida;

  return <span>{`${Number(newValue.toFixed(3))} ${tipo}`}</span>;
};

const formatMedida2 = (value, tipo) => {
  return <span>{`${Number(value)} ${tipo}`}</span>;
};

const formatMoneyToInput = (value) => {
  return Number(value) * 100;
};

const formatInputToHook = (value) => {
  return Number(value) / 100;
};

const currencyFormatter = (value, noCurrency) => {
  if (!Number(value)) return '';

  let queryCurrency = {
    currency: 'BRL',
  };

  queryCurrency = Object.assign(queryCurrency, { style: 'currency' });

  const amount = new Intl.NumberFormat('pt-BR', queryCurrency).format(
    value / 100,
  );
  return !noCurrency ? amount : amount.replace('R$', '');
};

const renderTooltip = (value) => (
  <Tooltip style={{ margin: 0, padding: 0 }} title={value} arrow>
    <span className="w-full p-2 overflow-hidden overflow-ellipsis whitespace-nowrap">
      {value}
    </span>
  </Tooltip>
);

function tempoPermanencia({ dataEntrada, dataSaida, horaEntrada, horaSaida }) {
  moment.locale('pt-BR');

  const start = moment(`${dataEntrada} ${horaEntrada}`);
  let end = moment();

  if (dataSaida && horaSaida) {
    end = moment(`${dataSaida} ${horaSaida}`);
  }

  const duration = moment.duration(end.diff(start));

  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  const daysFormatted = days ? `${days}D ` : '';
  const hoursFormatted = hours ? `${hours}H ` : '';
  const minutesFormatted = minutes ? `${minutes}M` : '';

  return [daysFormatted, hoursFormatted, minutesFormatted].join('');
}

const validateDate = (date) => {
  return date ? new Date(date) : new Date();
};

const startOfMonth = (dateString) => {
  const date = validateDate(dateString);
  const dateTimeZone = addHours(date, 3);
  return Format(StartOfMonth(dateTimeZone), 'yyyy-MM-dd');
};

const endOfMonth = (dateString) => {
  const date = validateDate(dateString);
  const dateTimeZone = addHours(date, 3);
  return Format(EndOfMonth(dateTimeZone), 'yyyy-MM-dd');
};

const startOfYear = (dateString) => {
  const date = validateDate(dateString);
  return Format(StartOfYear(date), 'yyyy-MM-dd');
};

const endOfYear = (dateString) => {
  const date = validateDate(dateString);
  return Format(EndOfYear(date), 'yyyy-MM-dd');
};

const dateAdd = (dateString, tipo) => {
  const date = validateDate(dateString);
  return Format(add(date, tipo), 'yyyy-MM-dd');
};

const dateMonthAdd = (dateString, value) => {
  const date = validateDate(dateString);

  return Format(StartOfMonth(add(date, { months: value })), 'yyyy-MM-dd');
};

const dateMonthSubtract = (dateString, value) => {
  const date = validateDate(dateString);

  return Format(EndOfMonth(add(date, { months: value })), 'yyyy-MM-dd');
};

const format = (dateString, value) => {
  const date = validateDate(dateString);
  return Format(date, 'yyyy-MM-dd');
};

const formatMedida = (item) => {
  return <span>{`${toPrice(item.quantidade, 3, 3)} ${item.unidade}`}</span>;
};

const dateNow = () => {
  const date = new Date();

  return Format(date, 'yyyy-MM-dd');
};

const formatCnpjCpf = (value) => {
  const cnpjCpf = value.replace(/\D/g, '');
  if (cnpjCpf?.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
  return cnpjCpf.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5',
  );
};

const isNegative = (number) => {
  return number < 0;
};

const determineColorPercentage = (percentage) => {
  if (!percentage) return '#368467';
  const formatPercentage = toNumber(percentage);
  if (formatPercentage >= 0 && formatPercentage <= 70) {
    return '#368467';
  } else if (formatPercentage > 70 && formatPercentage <= 80) {
    return '#ECCB55';
  } else {
    return '#FF4848';
  }
};

const codeHashRemover = (value = '') => {
  if (value.includes('-')) {
    return value.split('-')[1];
  } else {
    return value;
  }
};

export {
  toNewDate,
  currencyFormatter,
  toPrice,
  toPrice2,
  toPriceValue,
  reducedDate,
  formatDate,
  formatToSetDate,
  formatDate2,
  toPriceRS,
  safraConverter,
  formatTime,
  formatDateHour,
  formatToDate,
  formatHour,
  toPriceInt2,
  toDateUS,
  formatInputToHook,
  formatMedida,
  currencyRS,
  formatColhido,
  formatMedida2,
  toPriceInt,
  toDate,
  formatDateMnY,
  formatValor,
  dateNow,
  formatMoneyToInput,
  renderTooltip,
  tempoPermanencia,
  formatNumber,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  dateAdd,
  dateMonthSubtract,
  dateMonthAdd,
  format,
  toNumber,
  formatSizeUnits,
  formatCnpjCpf,
  isNegative,
  formatDateInternacional,
  determineColorPercentage,
  toPriceWhole,
  formatHours,
  formatDays,
  codeHashRemover,
};
