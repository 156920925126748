import React, {
  useState,
  useCallback,
  useContext,
  createContext,
  useEffect,
} from 'react';
import api from 'services';
import { useAuth } from 'hooks/auth';
import { useNewToast } from 'hooks/newToast';
import { appWarnings } from 'utils/appWarnings';
import { SLOGAN_AGROGER } from 'utils/variaveisGlobais';

const FazendaContext = createContext({});

export const FazendaProvider = ({ children }) => {
  const { isAuth, token } = useAuth();
  const { showToast } = useNewToast();

  const [fazendas, setFazendas] = useState([]);
  const [fazendasLoading, setFazendasLoading] = useState(true);
  const [fazenda, setFazenda] = useState({});
  const [fazendaLoading, setFazendaLoading] = useState(false);
  const [fazendaAtiva, setFazendaAtiva] = useState();
  const [fazendaPrincipal, setFazendaPrincipal] = useState({});
  const [insertFazendaLoading, setInsertFazendaLoading] = useState(false);
  const [deleteFazendaLoading, setDeleteFazendaLoading] = useState(false);
  const [isSendFarmCoordinatesLoading, setIsSendFarmCoordinatesLoading] =
    useState(false);
  const [coordinatesOfAllTheFarms, setCoordinatesOfAllTheFarms] = useState([]);
  const [
    isCoordinatesOfAllTheFarmsLoading,
    setIsCoordinatesOfAllTheFarmLoading,
  ] = useState(false);

  const down = useCallback(() => {
    setFazendas([]);
    setFazendaAtiva();
  }, []);

  const getFazendas = useCallback(async (filter) => {
    try {
      setFazendasLoading(true);
      const response = await api.fazenda().getFazendas(filter);
      const tempFazenda = response.find((item) => item.principal);
      if (tempFazenda) setFazendaPrincipal(tempFazenda);
      setFazendas(response);
      setFazendasLoading(false);
    } catch (errors) {
      setFazendasLoading(false);
    }
  }, []);

  const getFazendaAtiva = useCallback(async () => {
    try {
      setFazendasLoading(true);
      const response = await api.fazenda().getFazendaAtiva({ coords: 1 });
      setFazendaAtiva(response);
      setFazendasLoading(false);
    } catch (errors) {
      setFazendasLoading(false);
    }
  }, []);

  const getFazenda = useCallback(async (id) => {
    try {
      setFazendaLoading(true);
      const response = await api.fazenda().getFazenda(id);
      setFazenda(response);
      setFazendaLoading(false);
    } catch (errors) {
      setFazendaLoading(false);
    }
  }, []);

  const getCoordinatesOfAllTheFarms = useCallback(async () => {
    try {
      setIsCoordinatesOfAllTheFarmLoading(true);
      const response = await api.fazenda().getCoordinatesOfAllTheFarms();
      setCoordinatesOfAllTheFarms(response);
    } catch {
    } finally {
      setIsCoordinatesOfAllTheFarmLoading(true);
    }
  }, []);

  const insertFazenda = useCallback(async (data) => {
    try {
      setInsertFazendaLoading(true);
      const response = await api.fazenda().insertFazenda(data);
      setFazendas((old) => [...old, response]);
      setInsertFazendaLoading(false);
    } catch (err) {
      setInsertFazendaLoading(false);
    }
  }, []);

  const insertFazendaTutorial = useCallback(
    async (data) => {
      try {
        setInsertFazendaLoading(true);
        const response = await api
          .fazenda()
          .insertFazenda({ ...data, nome: data?.nome?.toUpperCase() });
        getFazendas();
        setFazendaAtiva(response);
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setInsertFazendaLoading(false);
      }
    },
    [getFazendas, showToast],
  );

  const sendFarmCoordinates = useCallback(
    async (data) => {
      try {
        setIsSendFarmCoordinatesLoading(true);
        const dataFormat = {
          latitude: data?.latitude,
          longitude: data?.longitude,
          fazenda: data?.id,
        };
        await api.fazenda().addFazendaCoords(dataFormat);
        getFazenda(data?.id);
        showToast({
          type: 'success',
          message: 'Coordenadas da Fazenda salva com sucesso!',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setIsSendFarmCoordinatesLoading(false);
      }
    },
    [showToast, getFazenda],
  );

  const postFazenda = useCallback(
    async (data) => {
      try {
        setInsertFazendaLoading(true);
        const {
          bairro_corrego,
          cep,
          cidade,
          cnpj_cpf,
          email,
          endereco,
          fazenda,
          ipr,
          switchAvulso,
          telefone_fixo,
          telefone_movel,
          uf,
        } = data;

        const dataFormated = {
          nome: fazenda,
          cnpj: cnpj_cpf,
          fixo: telefone_fixo,
          uf: uf?.uf,
          email: email,
          telefone: telefone_fixo,
          logradouro: endereco,
          bairro: bairro_corrego,
          cep: cep,
          cidade: cidade,
          celi: telefone_movel,
          celii: telefone_movel,
          inscricao: ipr,
          principal: switchAvulso ? 1 : 0,
        };

        await api.fazenda().insertFazenda(dataFormated);
        getFazendas({ coords: 1 });
        showToast({
          type: 'success',
          message: 'Fazenda adicionada com sucesso!',
        });
        setInsertFazendaLoading(false);
      } catch (erro) {
        showToast(appWarnings.Danger(erro));
        setInsertFazendaLoading(false);
        throw new Error(erro);
      }
    },
    [showToast, getFazendas],
  );

  const putFazenda = useCallback(
    async (data) => {
      try {
        setInsertFazendaLoading(true);
        const {
          id,
          bairro_corrego,
          cep,
          cidade,
          cnpj_cpf,
          email,
          endereco,
          fazenda,
          ipr,
          switchAvulso,
          telefone_fixo,
          telefone_movel,
          uf,
        } = data;

        const dataFormated = {
          nome: fazenda,
          cnpj: cnpj_cpf,
          fixo: telefone_fixo,
          uf: uf?.uf,
          email: email,
          telefone: telefone_fixo,
          logradouro: endereco,
          bairro: bairro_corrego,
          cep: cep,
          cidade: cidade,
          celi: telefone_movel,
          celii: telefone_movel,
          inscricao: ipr,
          principal: switchAvulso ? 1 : 0,
        };

        await api.fazenda().putFazenda(id, dataFormated);
        getFazendas();
        showToast({
          type: 'success',
          message: 'Fazenda editada com sucesso!',
        });
        setInsertFazendaLoading(false);
      } catch (erro) {
        showToast(appWarnings.Danger(erro));
        setInsertFazendaLoading(false);
        throw new Error(erro);
      }
    },
    [showToast, getFazendas],
  );

  const delFazenda = useCallback(
    async (id) => {
      try {
        setDeleteFazendaLoading(true);
        await api.fazenda().delFazenda(id);
        setFazendas((old) => old.filter((item) => item.id !== id));
        showToast({
          type: 'success',
          message: 'Fazenda removida com sucesso!',
        });
        setDeleteFazendaLoading(false);
      } catch (erro) {
        setDeleteFazendaLoading(false);
        showToast(appWarnings.Danger(erro));
        throw new Error(erro);
      }
    },
    [showToast],
  );

  useEffect(() => {
    if (!!isAuth && token !== 'initial') {
      getFazendas({ coords: 1 });
    } else {
      down();
    }
  }, [isAuth, getFazendas, down, token]);

  useEffect(() => {
    if (!fazendaAtiva) {
      if (fazendas?.length > 1) {
        setFazendaAtiva({
          id: 0,
          nome: 'TODAS AS FAZENDAS',
        });
      } else {
        setFazendaAtiva(fazendas[0]);
      }
    }
  }, [fazendas, fazendaAtiva]);

  useEffect(() => {
    if (fazendaAtiva?.nome) {
      document.title = `${
        fazendaAtiva?.nome.toUpperCase() || ''
      } | ${SLOGAN_AGROGER}`;
    }

    return () => (document.title = SLOGAN_AGROGER);
  }, [fazendaAtiva]);

  return (
    <FazendaContext.Provider
      value={{
        getFazendaAtiva,
        getFazendas,
        fazendas,
        deleteFazendaLoading,
        fazendasLoading,
        insertFazendaLoading,
        getFazenda,
        insertFazenda,
        insertFazendaTutorial,
        fazenda,
        fazendaLoading,
        fazendaAtiva,
        setFazendaPrincipal,
        setFazendaAtiva,
        sendFarmCoordinates,
        isSendFarmCoordinatesLoading,
        postFazenda,
        delFazenda,
        putFazenda,
        fazendaPrincipal,
        getCoordinatesOfAllTheFarms,
        coordinatesOfAllTheFarms,
        isCoordinatesOfAllTheFarmsLoading,
      }}
    >
      {children}
    </FazendaContext.Provider>
  );
};

export function useFazenda() {
  const context = useContext(FazendaContext);

  if (!context)
    throw new Error('useFazenda must be used within an AuthProvider');

  return context;
}
