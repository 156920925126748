export class CompraFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getCompras(filter) {
    return this.instance.get('/v1/compras', {
      params: filter || null,
    });
  }

  getCompra(id, filter) {
    return this.instance.get(`/v1/compras/${id}`, {
      params: filter || null,
    });
  }

  getProdutos(id, filter) {
    return this.instance.get(`/v1/compras/${id}/produtos`, {
      params: filter || null,
    });
  }
  addCompra(data) {
    return this.instance.post(`/v1/compras`, data);
  }

  editCompra(id, data) {
    return this.instance.put(`/v1/compras/${id}`, data);
  }

  deleteCompra(id) {
    return this.instance.delete(`/v1/compras/${id}`);
  }

  deleteProduto(id, idProduto) {
    return this.instance.delete(`/v1/compras/${id}/produtos/${idProduto}`);
  }

  finalizarCompra(id, data) {
    return this.instance.put(`/v1/compras/${id}/finalizar`, data);
  }
  abrirCompra(id) {
    return this.instance.patch(`/v1/compras/${id}/abrir`);
  }

  patchCompra(id, idProduto, status) {
    return this.instance.patch(
      `/v1/compras/${id}/produtos/${idProduto}/entregue`,
      status
    );
  }
}

export default (...params) => new CompraFactory(...params);
