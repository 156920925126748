export class AcompanhamentoFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getMeeiros(filter) {
    return this.instance.get('/v1/acompanhamentos', {
      params: filter || null,
    });
  }

  getSafristas(filter) {
    return this.instance.get('/v1/acompanhamentos/panhadores', {
      params: filter || null,
    });
  }

  getColheitaPorAtividade(filter) {
    return this.instance.get('/v1/acompanhamentos/colheita-por-atividade', {
      params: filter || null,
    });
  }

  getAcompanhamentoSemanal(filter) {
    return this.instance.get('/v1/acompanhamentos/por-semana', {
      params: filter || null,
    });
  }

  getAcompanhamentoHistorico(filter) {
    return this.instance.get('/v1/acompanhamentos/colheita-historico', {
      params: filter || null,
    });
  }

  getColhidoMensal(filter) {
    return this.instance.get('/v1/acompanhamentos/colheita-mensal', {
      params: filter || null,
    });
  }

  getColheitaPorTalhao(filter) {
    return this.instance.get('/v1/acompanhamentos/grafico-por-fazenda', {
      params: filter || null,
    });
  }
}

export default (...params) => new AcompanhamentoFactory(...params);
