/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useContext, createContext } from 'react';
import api from '../services';
import { useNewToast } from 'hooks/newToast';
import { useAuth } from './auth';

const FavoritesContext = createContext({});

export const FavoritesProvider = ({ children }) => {
  const { isAuth } = useAuth();

  const [favorites, setFavorites] = useState([]);
  const [favoritesLoading, setFavoritesLoading] = useState([]);

  const [createFavoriteLoading, setCreateFavoriteLoading] = useState(false);

  const { showToast } = useNewToast();

  const listFavorites = useCallback(async () => {
    try {
      setFavoritesLoading(true);
      const response = await api.favorites().list();
      setFavorites(response);
    } catch (err) {
    } finally {
      setFavoritesLoading(false);
    }
  }, []);

  const createFavorite = useCallback(
    async (data) => {
      try {
        setCreateFavoriteLoading(true);

        const formatData = {
          name: data?.name,
          url: data?.url,
        };
        const response = await api.favorites().create(formatData);

        setFavorites((oldFavorites) => {
          const existingIndex = oldFavorites.findIndex(
            (favorite) => favorite.url === formatData.url,
          );

          if (existingIndex !== -1) {
            return oldFavorites.filter((_, index) => index !== existingIndex);
          }

          return oldFavorites.concat(response);
        });
      } catch (err) {
      } finally {
        setCreateFavoriteLoading(false);
      }
    },
    [setCreateFavoriteLoading, setFavorites, showToast],
  );

  const isFavoriteExists = useCallback(
    (url) => {
      if (favorites.length > 0) {
        return favorites?.some((favorite) => favorite.url === url);
      }
      return false;
    },
    [favorites],
  );

  React.useEffect(() => {
    if (!!isAuth) {
      listFavorites();
    }
  }, [isAuth, listFavorites]);

  return (
    <FavoritesContext.Provider
      value={{
        listFavorites,
        favorites,
        favoritesLoading,
        createFavorite,
        createFavoriteLoading,
        isFavoriteExists,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

export function useFavorites() {
  const context = useContext(FavoritesContext);

  if (!context)
    throw new Error('useFavorites must be used within an AuthProvider');

  return context;
}
