export class InsumoFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getInsumos(filter) {
    return this.instance.get('/v1/cad-insumos', {
      params: filter || null,
    });
  }

  getInsumosPrint(filter) {
    return this.instance.get('/v1/cad-insumos', {
      params: filter || null,
    });
  }

  getInsumo(id, filter) {
    return this.instance.get(`/v1/cad-insumos/insumo/${id}`, {
      params: filter || null,
    });
  }

  insertInsumo(data) {
    return this.instance.post('/v1/cad-insumos', data);
  }

  editInsumo(data, id) {
    return this.instance.put(`/v1/cad-insumos/${id}`, data);
  }

  delInsumo(id) {
    return this.instance.delete(`/v1/cad-insumos/${id}`);
  }

  reajustarQuantEstoque(data) {
    return this.instance.post(`/v1/cad-estoque/`, data);
  }

  alterarStatusInsumo(id) {
    return this.instance.patch(`/v1/cad-insumos/${id}/alterar-status`);
  }

  listHistReajusteEstoque(insumo_id) {
    return this.instance.get(`/v1/cad-estoque/${insumo_id}/historico`);
  }
}

export default (...params) => new InsumoFactory(...params);
