/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, createContext, useCallback } from 'react';

import { db_loteSecadorList, db_escolherSecadorList } from './secador';
import { db_loteTerreiroList, db_escolherTerreiroList } from './terreiro';
import { db_loteBeneficioList, db_escolherBeneficioList } from './beneficio';
import { db_loteRebeneficioList, db_escolherRebeneficioList } from './rebeneficio';
import { db_loteArmazemList, db_escolherArmazemList } from './armazem';

const LotesContext = createContext({});

var lotesDespolpador = [
  {
    id: 1,
    data: '2021/05/03',
    codigo: 'D1-0503A',
    descrição: 'Café de Qualidade',
    responsavel: 'João Rafael',
    despolpador: 'Despolpador A',
    entradas: [
      {
        id: 1,
        nome: 'Talhão 76',
        data: '2021-05-03',
        quantidade: '165421',
      },
    ],
    saidas: [
      {
        id: 1,
        tipo: 'Boia',
        quantidade: '40',
        obs: 'Problemas na colheitadeira',
      },
      {
        id: 2,
        tipo: 'Cereja',
        quantidade: '120',
        obs: 'Tudo certo',
      },
    ],
  },
  {
    id: 2,
    data: '2022/01/14',
    codigo: 'D2-0443C',
    descrição: 'Café de Qualidade',
    responsavel: 'João Felipe',
    despolpador: 'Despolpador B',
    entradas: [
      {
        id: 1,
        nome: 'Talhão 98',
        data: '2021-05-03',
        quantidade: '100.00',
      },
    ],
    saidas: [
      {
        id: 1,
        tipo: 'Boinha',
        quantidade: '230',
        obs: 'Problemas na colheitadeira',
      },
      {
        id: 2,
        tipo: 'Natural',
        quantidade: '60',
        obs: 'Tudo certo',
      },
    ],
  },
];

function db_loteDespolpadorList() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(lotesDespolpador);
    }, 1000);
  });
}

export const LoteProvider = ({ children }) => {
  const [lotesSecadorLoading, setLotesSecadorLoading] = useState(false);
  const [lotesSecador, setLotesSecador] = useState([]);

  const [escolherSecador, setEscolherSecador] = useState([]);
  const [escolherSecadorLoading, setEscolherSecadorLoading] = useState(false);

  const getEscolherSecador = useCallback(async filter => {
    try {
      setEscolherSecadorLoading(true);
      const response = await db_escolherSecadorList(filter);
      setEscolherSecador(response);
      setEscolherSecadorLoading(false);
    } catch (errors) {
      setEscolherSecadorLoading(false);
    }
  }, []);

  const getLotesSecador = useCallback(async filter => {
    try {
      setLotesSecadorLoading(true);
      const response = await db_loteSecadorList(filter);
      setLotesSecador(response);
      setLotesSecadorLoading(false);
    } catch (errors) {
      setLotesSecadorLoading(false);
    }
  }, []);

  const [lotesDespolpadorLoading, setLotesDespolpadorLoading] = useState(false);
  const [lotesDespolpador, setLotesDespolpador] = useState([]);

  const getLotesDespolpador = useCallback(async filter => {
    try {
      setLotesDespolpadorLoading(true);
      const response = await db_loteDespolpadorList(filter);
      setLotesDespolpador(response);
      setLotesDespolpadorLoading(false);
    } catch (errors) {
      setLotesDespolpadorLoading(false);
    }
  }, []);

  const [escolherTerreiro, setEscolherTerreiro] = useState([]);
  const [escolherTerreiroLoading, setEscolherTerreiroLoading] = useState(false);

  const getEscolherTerreiro = useCallback(async filter => {
    try {
      setEscolherTerreiroLoading(true);
      const response = await db_escolherTerreiroList(filter);
      setEscolherTerreiro(response);
      setEscolherTerreiroLoading(false);
    } catch (errors) {
      setEscolherTerreiroLoading(false);
    }
  }, []);

  const [lotesTerreiroLoading, setLotesTerreiroLoading] = useState(false);
  const [lotesTerreiro, setLotesTerreiro] = useState([]);

  const getLotesTerreiro = useCallback(async filter => {
    try {
      setLotesTerreiroLoading(true);
      const response = await db_loteTerreiroList(filter);
      setLotesTerreiro(response);
      setLotesTerreiroLoading(false);
    } catch (errors) {
      setLotesTerreiroLoading(false);
    }
  }, []);

  const [escolherBeneficioLoading, setEscolherBeneficioLoading] = useState(false);
  const [escolherBeneficio, setEscolherBeneficio] = useState([]);

  const getEscolherBeneficio = useCallback(async filter => {
    try {
      setEscolherBeneficioLoading(true);
      const response = await db_escolherBeneficioList(filter);
      setEscolherBeneficio(response);
      setEscolherBeneficioLoading(false);
    } catch (errors) {
      setEscolherBeneficioLoading(false);
    }
  }, []);

  const [lotesBeneficioLoading, setLotesBeneficioLoading] = useState(false);
  const [lotesBeneficio, setLotesBeneficio] = useState([]);

  const getLotesBeneficio = useCallback(async filter => {
    try {
      setLotesBeneficioLoading(true);
      const response = await db_loteBeneficioList(filter);
      setLotesBeneficio(response);
      setLotesBeneficioLoading(false);
    } catch (errors) {
      setLotesBeneficioLoading(false);
    }
  }, []);

  const [lotesRebeneficioLoading, setLotesRebeneficioLoading] = useState(false);
  const [lotesRebeneficio, setLotesRebeneficio] = useState([]);

  const getLotesRebeneficio = useCallback(async filter => {
    try {
      setLotesRebeneficioLoading(true);
      const response = await db_loteRebeneficioList(filter);
      setLotesRebeneficio(response);
      setLotesRebeneficioLoading(false);
    } catch (errors) {
      setLotesRebeneficioLoading(false);
    }
  }, []);

  const [escolherRebeneficioLoading, setEscolherRebeneficioLoading] = useState(false);
  const [escolherRebeneficio, setEscolherRebeneficio] = useState([]);

  const getEscolherRebeneficio = useCallback(async filter => {
    try {
      setEscolherRebeneficioLoading(true);
      const response = await db_escolherRebeneficioList(filter);
      setEscolherRebeneficio(response);
      setEscolherRebeneficioLoading(false);
    } catch (errors) {
      setEscolherRebeneficioLoading(false);
    }
  }, []);

  const [lotesArmazemLoading, setLotesArmazemLoading] = useState(false);
  const [lotesArmazem, setLotesArmazem] = useState([]);

  const getLotesArmazem = useCallback(async filter => {
    try {
      setLotesArmazemLoading(true);
      const response = await db_loteArmazemList(filter);
      setLotesArmazem(response);
      setLotesArmazemLoading(false);
    } catch (errors) {
      setLotesArmazemLoading(false);
    }
  }, []);

  const [escolherArmazemLoading, setEscolherArmazemLoading] = useState(false);
  const [escolherArmazem, setEscolherArmazem] = useState([]);

  const getEscolherArmazem = useCallback(async filter => {
    try {
      setEscolherArmazemLoading(true);
      const response = await db_escolherArmazemList(filter);
      setEscolherArmazem(response);
      setEscolherArmazemLoading(false);
    } catch (errors) {
      setEscolherArmazemLoading(false);
    }
  }, []);

  return (
    <LotesContext.Provider
      value={{
        lotesSecador,
        lotesSecadorLoading,
        getLotesSecador,
        lotesDespolpador,
        lotesDespolpadorLoading,
        getLotesDespolpador,
        lotesTerreiro,
        lotesTerreiroLoading,
        getLotesTerreiro,
        getEscolherBeneficio,
        escolherBeneficio,
        escolherBeneficioLoading,
        getLotesRebeneficio,
        lotesRebeneficio,
        lotesRebeneficioLoading,
        getEscolherRebeneficio,
        escolherRebeneficio,
        escolherRebeneficioLoading,
        escolherTerreiroLoading,
        escolherTerreiro,
        getEscolherTerreiro,
        escolherSecador,
        getEscolherSecador,
        escolherSecadorLoading,
        lotesArmazemLoading,
        getLotesArmazem,
        lotesArmazem,
        escolherArmazem,
        escolherArmazemLoading,
        getEscolherArmazem,
        lotesBeneficio,
        lotesBeneficioLoading,
        getLotesBeneficio,
      }}>
      {children}
    </LotesContext.Provider>
  );
};

export function useLotes() {
  const context = useContext(LotesContext);

  if (!context) throw new Error('useLotes must be used within an ConfigProvider');

  return context;
}
