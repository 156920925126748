export class Turma {
  constructor(instance) {
    this.instance = instance;
  }

  idTurma(id) {
    return this.instance.get(`/v1/cad-turmas/${id}`, {
      params: id,
    });
  }

  getTurmas(filter) {
    return this.instance.get('/v1/cad-turmas/', {
      params: filter || null,
    });
  }

  insertTurma(data) {
    return this.instance.post('/v1/cad-turmas/', data);
  }

  editTurma(id, data) {
    return this.instance.put(`/v1/cad-turmas/${id}`, data);
  }

  updateTurma(id, data) {
    return this.instance.post(`/v1/cad-turmas/${id}`, data);
  }

  removeTurma(id) {
    return this.instance.delete(`/v1/cad-turmas/${id}`);
  }
}

export default (...params) => new Turma(...params);
