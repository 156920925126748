export class CadDespolpadorFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getDespolpadores(filter) {
    return this.instance.get('/v1/cad-despolpadores', {
      params: filter || null,
    });
  }

  getDespolpador(filter, id) {
    return this.instance.get(`/v1/cad-despolpadores/${id}`, {
      params: filter || null,
    });
  }

  addDespolpador(data) {
    return this.instance.post(`/v1/cad-despolpadores`, data);
  }

  editDespolpador(id, data) {
    return this.instance.put(`v1/cad-despolpadores/${id}`, data);
  }

  deleteDespolpador(id) {
    return this.instance.delete(`/v1/cad-despolpadores/${id}`);
  }
}

export default (...params) => new CadDespolpadorFactory(...params);
