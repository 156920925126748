import React, { useState, useContext, createContext } from 'react';

const TabsContext = createContext({});

export const TabsProvider = ({ children }) => {
  const [tabAtual, setTabAtual] = useState(0);
  const [tabMaquinas, setTabMaquinas] = useState(0);
  const [tabViewMaquinas, setTabViewMaquinas] = useState(0);
  const [tabAtividades, setTabAtividades] = useState(0);
  const [tabHome, setTabHome] = useState(0);
  const [tabRelatorios, setTabRelatorios] = useState(0);
  const [tabRelTalhoes, setTabRelTalhoes] = useState(0);
  const [tabPerfil, setTabPerfil] = useState(0);
  const [tabPlanejamento, setTabPlanejamento] = useState(0);
  const [tabPlanejamentoEstimativas, setTabPlanejamentoEstimativas] = useState(0);

  React.useEffect(() => {
    if (tabHome === undefined) {
      setTabHome(0);
    }
  }, [tabHome]);

  const changeHomeTab = React.useCallback(tab => {
    setTabHome(tab);
  }, []);

  const changeRelatoriosTab = React.useCallback(tab => {
    setTabRelatorios(tab);
  }, []);

  const changeRelatoriosTabTalhao = React.useCallback(tab => {
    setTabRelTalhoes(tab);
  }, []);

  const changePerfilTab = React.useCallback(tab => {
    setTabPerfil(tab);
  }, []);

  const changeTab = React.useCallback(tab => {
    setTabAtual(tab);
  }, []);

  const changeTabMaquinas = React.useCallback(tab => {
    setTabMaquinas(tab);
  }, []);

  const changeTabViewMaquinas = React.useCallback(tab => {
    setTabViewMaquinas(tab);
  }, []);

  const changeTabPlanejamento = React.useCallback(tab => {
    setTabPlanejamento(tab);
  }, []);

  const changeTabPlaEstimativas = React.useCallback(tab => {
    setTabPlanejamentoEstimativas(tab);
  }, []);

  const changeTabAtividades = React.useCallback(tab => {
    setTabAtividades(tab);
  }, []);

  return (
    <TabsContext.Provider
      value={{
        changeTab,
        setTabAtual,
        tabAtual,
        tabHome,
        changeHomeTab,
        setTabHome,
        changeTabMaquinas,
        tabMaquinas,
        changeRelatoriosTab,
        tabRelatorios,
        setTabRelTalhoes,
        tabRelTalhoes,
        changeRelatoriosTabTalhao,
        changeTabViewMaquinas,
        tabViewMaquinas,
        changePerfilTab,
        tabPerfil,
        setTabPerfil,
        changeTabPlanejamento,
        setTabPlanejamento,
        tabPlanejamento,
        changeTabPlaEstimativas,
        setTabPlanejamentoEstimativas,
        tabPlanejamentoEstimativas,
        changeTabAtividades,
        tabAtividades,
      }}>
      {children}
    </TabsContext.Provider>
  );
};

export function useTabs() {
  const context = useContext(TabsContext);

  if (!context) throw new Error('useTabs must be used within an ToastProvider');

  return context;
}
