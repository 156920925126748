import React, { useState, useContext, createContext, useCallback } from 'react';
import api from 'services';
import { useAuth } from 'hooks/auth';
import { useNewToast } from 'hooks/newToast';
import { toDate, toPriceInt } from 'utils/converters';
import { appWarnings } from 'utils/appWarnings';

const success = {
  type: 'success',
  message: '',
};

const colaboradoresDefault = {
  content: [],
  pagination: {},
};

const COLABORADORES = {
  content: [],
  total: 0,
};

const ColaboradoresContext = createContext({});

export const ColaboradoresProvider = ({ children }) => {
  const { isAuth } = useAuth();

  const { showToast } = useNewToast();

  const [colaboradoresReduced, setColaboradoresReduced] = useState([]);
  const [colaboradores, setColaboradores] = useState(colaboradoresDefault);

  const [colaboradoresLoading, setColaboradoresLoading] = useState(false);

  const [colaborador, setColaborador] = useState({});
  const [colaboradorLoading, setColaboradorLoading] = useState(false);

  const [addColaboradorLoading, setAddColaboradorLoading] = useState(false);
  const [deleteColaboradorLoading, setDeleteColaboradorLoading] = useState(false);
  const [editColaboradorLoading, setEditColaboradorLoading] = useState(false);
  const [historicoColaboradorLoading, setHistoricoColaboradorLoading] = useState(false);
  const [historicoColaborador, setHistoricoColaborador] = useState([]);
  const [deleteAdiantamentoLoading, setDeleteAdiantamentoLoading] = useState(false);
  const [editAdiantamentoLoading, setEditAdiantamentoLoading] = useState(false);

  const [adiantamentos, setAdiantamentos] = useState([]);
  const [adiantamentosLoading, setAdiantamentosLoading] = useState(false);

  const [saldoMeeiro, setSaldoMeeiro] = useState({});
  const [saldoMeeiroLoading, setSaldoMeeiroLoading] = useState(false);

  const [meeiroHistoricoSaidas, setMeeiroHistoricoSaidas] = useState([]);
  const [meeiroHistoricoSaidasLoading, setMeeiroHistoricoSaidasLoading] = useState(false);

  const [meeiroHistoricoEntradas, setMeeiroHistoricoEntradas] = useState([]);
  const [meeiroHistoricoEntradasLoading, setMeeiroHistoricoEntradasLoading] = useState(false);

  const [talhoesByMeeiro, setTalhoesByMeeiro] = React.useState([]);
  const [talhoesByMeeiroLoading, setTalhoesByMeeiroLoading] = React.useState(false);
  const [addAdiantamentoLoading, setAddAdiantamentoLoading] = useState(false);

  const [addDescontosAdiantamentoLoading, setAddDescontosAdiantamentoLoading] = React.useState(false);
  const [colaboradoresReducedLoading, setColaboradoresReducedLoading] = React.useState(false);
  const [colaboradoresFolhaPagamento, setColaboradoresFolhaPagamento] = useState(COLABORADORES);
  const [colaboradoresFolhaPagamentoLoading, setColaboradoresFolhaPagamentoLoading] = useState(false);

  const down = React.useCallback(() => {
    setColaboradoresReduced([]);
    setColaboradores(colaboradoresDefault);
    setColaborador({});
  }, []);

  const getColaboradores = useCallback(async filter => {
    try {
      setColaboradoresLoading(true);
      const response = await api.colaborador().getColaboradores(filter);

      if (filter?.reduced >= 2) {
        setColaboradoresReduced(response);
      } else {
        setColaboradores(response);
      }
    } catch (errors) {
    } finally {
      setColaboradoresLoading(false);
    }
  }, []);

  const listColaboradoresFolhaPagamento = useCallback(async filter => {
    try {
      setColaboradoresFolhaPagamentoLoading(true);

      if (filter.tipoBusca === 1) {
        const response = await api.colaborador().listColaboradoresFolhaPagamento(filter);
        setColaboradoresFolhaPagamento(response);
      } else {
        const content = await api.colaborador().getColaboradores({ situacao: 'ATIVO', reduced: 1 });
        setColaboradoresFolhaPagamento({ content, total: content.length });
      }
    } catch {
    } finally {
      setColaboradoresFolhaPagamentoLoading(false);
    }
  }, []);

  const getColaborador = useCallback(async id => {
    try {
      setColaboradorLoading(true);
      const response = await api.colaboradores().idColaboradores(id);
      setColaborador(response);
    } catch (errors) {
    } finally {
      setColaboradorLoading(false);
    }
  }, []);

  const addColaborador = useCallback(
    async data => {
      try {
        setAddColaboradorLoading(true);
        const newData = {
          nome: data?.nome,
          codigo: data?.codigo,
          razaoSocial: data?.razaoSocial,
          tipo: data?.tipo,
          funcao: data?.funcao?.id,
          cpf_cnpj: data?.cpf_cnpj,
          email: data?.email,
          nascimento: data?.nascimento ? toDate(data?.nascimento) : null,
          turma: data?.turma?.id,
          carteiraTrabalho: data?.carteiraTrabalho,
          celular: data?.celular,
          dataAdmissao: data?.dataAdmissao ? toDate(data?.dataAdmissao) : null,
          dataDemissao: data?.dataDemissao ? toDate(data?.dataDemissao) : null,
          dependentes: data?.dependentes,
          filhos: data?.filhos,
          meeiroPorcentagem: Number(data?.meeiroPorcentagem) / 100,
          meeiroProporcao: Number(data?.meeiroProporcao || 0),
          meeiroSaldo: data?.meeiroSaldo?.id,
          meeiroTratos: data?.meeiroTratos?.id,
          obs: data?.obs,
          cargaHorariaMensal: data?.cargaHorariaMensal,
          pisPasep: data?.pisPasep,
          salarioBase: toPriceInt(data?.salarioBase),
          situacao: data?.situacao?.id,
          tags: data?.tags,
          telefone: data?.telefone,
          valorDia: toPriceInt(data?.valorDia),
          valorHora: toPriceInt(data?.valorHora),
        };
        const response = await api.colaborador().addColaborador(newData);
        showToast({ ...success, message: 'Colaborador adicionado com sucesso' });
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setAddColaboradorLoading(false);
      }
    },
    [showToast]
  );

  const editColaborador = useCallback(
    async (data, id) => {
      try {
        setEditColaboradorLoading(true);
        const newData = {
          nome: data?.nome,
          codigo: data?.codigo,
          razaoSocial: data?.razaoSocial,
          tipo: data?.tipo,
          funcao: data?.funcao?.id,
          cpf_cnpj: data?.cpf_cnpj,
          email: data?.email,
          nascimento: data?.nascimento ? toDate(data?.nascimento) : null,
          turma: data?.turma?.id,
          carteiraTrabalho: data?.carteiraTrabalho,
          celular: data?.celular,
          dataAdmissao: data?.dataAdmissao ? toDate(data?.dataAdmissao) : null,
          dataDemissao: data?.dataDemissao ? toDate(data?.dataDemissao) : null,
          dependentes: data?.dependentes,
          filhos: data?.filhos,
          meeiroPorcentagem: Number(data?.meeiroPorcentagem) / 100,
          meeiroProporcao: Number(data?.meeiroProporcao || 0),
          meeiroSaldo: data?.meeiroSaldo?.id,
          meeiroTratos: data?.meeiroTratos?.id,
          obs: data?.obs,
          cargaHorariaMensal: data?.cargaHorariaMensal,
          pisPasep: data?.pisPasep,
          salarioBase: toPriceInt(data?.salarioBase),
          situacao: data?.situacao?.id,
          tags: data?.tags,
          telefone: data?.telefone,
          valorDia: toPriceInt(data?.valorDia),
          valorHora: toPriceInt(data?.valorHora),
        };
        const response = await api.colaborador().editColaborador(newData, id);
        showToast({ ...success, message: 'Colaborador editado com sucesso' });
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setEditColaboradorLoading(false);
      }
    },
    [showToast]
  );

  const deleteColaborador = useCallback(
    async id => {
      try {
        setDeleteColaboradorLoading(true);
        await api.colaborador().deleteColaborador(id);
        showToast({ ...success, message: 'Colaborador removido com sucesso!' });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setDeleteColaboradorLoading(false);
      }
    },
    [showToast]
  );

  const getHistoricoColaborador = useCallback(async (id, filter) => {
    try {
      const format_filter = {
        ...filter,
        atividade: filter?.atividade?.id,
      };

      setHistoricoColaboradorLoading(true);
      const response = await api.colaborador().getHistoricoColaborador(id, format_filter);
      setHistoricoColaborador(response);
    } catch {
    } finally {
      setHistoricoColaboradorLoading(false);
    }
  }, []);

  const getAdiantamentos = useCallback(async filter => {
    try {
      setAdiantamentosLoading(true);
      const response = await api.colaborador().getAdiantamentos(filter);

      setAdiantamentos(response);
    } catch {
    } finally {
      setAdiantamentosLoading(false);
    }
  }, []);

  const addAdiantamento = useCallback(
    async dados => {
      try {
        setAddAdiantamentoLoading(true);
        const { data, valor, categoria } = dados;
        const newData = {
          ...dados,
          data: toDate(data),
          valor: toPriceInt(valor),
          categoria: categoria?.id,
        };
        const response = await api.colaborador().addAdiantamento(newData);
        showToast({ ...success, message: 'Adiantamento adicionado com sucesso!' });
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setAddAdiantamentoLoading(false);
      }
    },
    [showToast]
  );

  const editAdiantamento = useCallback(
    async (dados, idAdiantamento) => {
      try {
        setEditAdiantamentoLoading(true);
        const { data, valor, categoria } = dados;
        const newData = {
          ...dados,
          data: toDate(data),
          valor: toPriceInt(valor),
          categoria: categoria?.id,
        };
        const response = await api.colaborador().editAdiantamento(newData, idAdiantamento);
        showToast({ ...success, message: 'Adiantamento editado com sucesso!' });
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setEditAdiantamentoLoading(false);
      }
    },
    [showToast]
  );

  const deleteAdiantamento = useCallback(
    async (id, filter) => {
      try {
        setDeleteAdiantamentoLoading(true);
        await api.colaborador().deleteAdiantamento(id, filter);
        showToast({ ...success, message: 'Adiantamento removido com sucesso!' });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setDeleteAdiantamentoLoading(false);
      }
    },
    [showToast]
  );

  const getColaboradoresReduced = useCallback(async filter => {
    try {
      setColaboradoresReducedLoading(true);
      const response = await api.colaborador().getColaboradores({ ...filter, reduced: 1 });
      setColaboradoresReduced(response);
    } catch {
    } finally {
      setColaboradoresReducedLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (!isAuth) {
      down();
    }
  }, [isAuth, down]);

  const addColaboradorReduzido = useCallback(
    async data => {
      try {
        setAddColaboradorLoading(true);
        const { nome, razaoSocial, tipo, funcao, codigo } = data;
        const newData = {
          nome,
          razaoSocial,
          tipo,
          funcao: funcao?.id,
          codigo,
        };
        const response = await api.colaborador().addColaborador(newData);
        showToast({ ...success, message: 'Colaborador adicionado com sucesso' });

        await getColaboradores({ reduced: 2, situacao: 'ATIVO' });
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setAddColaboradorLoading(false);
      }
    },
    [showToast, getColaboradores]
  );

  const editColaboradorReduzido = useCallback(
    async (data, id) => {
      try {
        setEditColaboradorLoading(true);
        const { nome, razaoSocial, tipo, funcao, codigo } = data;
        const newData = {
          nome,
          razaoSocial,
          tipo,
          funcao: funcao?.id,
          codigo,
        };
        const response = await api.colaborador().editColaborador(newData, id);
        showToast({ ...success, message: 'Colaborador editado com sucesso' });
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setEditColaboradorLoading(false);
      }
    },
    [showToast]
  );

  const getSaldoMeeiro = useCallback(async (id, filter) => {
    try {
      setSaldoMeeiroLoading(true);
      const response = await api.colaborador().getSaldoMeeiro(id, filter);
      setSaldoMeeiro(response);
    } catch {
    } finally {
      setSaldoMeeiroLoading(false);
    }
  }, []);

  const getMeeiroHistoricoEntradas = useCallback(async (id, filter) => {
    try {
      setMeeiroHistoricoEntradasLoading(true);
      const response = await api.colaborador().getMeeiroHistoricoEntradas(id, filter);
      setMeeiroHistoricoEntradas(response);
    } catch (errors) {
    } finally {
      setMeeiroHistoricoEntradasLoading(false);
    }
  }, []);

  const getMeeiroHistoricoSaidas = useCallback(async (id, filter) => {
    try {
      setMeeiroHistoricoSaidasLoading(true);
      const response = await api.colaborador().getMeeiroHistoricoSaidas(id, filter);
      setMeeiroHistoricoSaidas(response);
    } catch (errors) {
    } finally {
      setMeeiroHistoricoSaidasLoading(false);
    }
  }, []);

  const geTalhoesByMeeiro = useCallback(async (id, filter) => {
    try {
      setTalhoesByMeeiroLoading(true);
      const response = await api.colaborador().geTalhoesByMeeiro(id, filter);
      setTalhoesByMeeiro(response);
    } catch (errors) {
    } finally {
      setTalhoesByMeeiroLoading(false);
    }
  }, []);

  const addDescontosAdiantamento = useCallback(async data => {
    try {
      setAddDescontosAdiantamentoLoading(true);
      await api.colaborador().addDescontosAdiantamento(data);
    } catch (errors) {
    } finally {
      setAddDescontosAdiantamentoLoading(false);
    }
  }, []);

  return (
    <ColaboradoresContext.Provider
      value={{
        geTalhoesByMeeiro,
        talhoesByMeeiro,
        talhoesByMeeiroLoading,
        saldoMeeiro,
        saldoMeeiroLoading,
        getSaldoMeeiro,
        meeiroHistoricoSaidas,
        meeiroHistoricoSaidasLoading,
        getMeeiroHistoricoSaidas,
        meeiroHistoricoEntradas,
        meeiroHistoricoEntradasLoading,
        getMeeiroHistoricoEntradas,
        getColaboradores,
        addColaboradorReduzido,
        editColaboradorReduzido,
        getColaborador,
        colaboradorLoading,
        colaborador,
        colaboradores,
        colaboradoresLoading,
        colaboradoresReduced,
        getHistoricoColaborador,
        historicoColaboradorLoading,
        historicoColaborador,
        getAdiantamentos,
        adiantamentos,
        addColaborador,
        addColaboradorLoading,
        editColaboradorLoading,
        editColaborador,
        deleteColaboradorLoading,
        deleteColaborador,
        adiantamentosLoading,
        addAdiantamentoLoading,
        addAdiantamento,
        editAdiantamento,
        editAdiantamentoLoading,
        deleteAdiantamentoLoading,
        deleteAdiantamento,
        getColaboradoresReduced,
        addDescontosAdiantamento,
        addDescontosAdiantamentoLoading,
        colaboradoresReducedLoading,
        listColaboradoresFolhaPagamento,
        colaboradoresFolhaPagamento,
        colaboradoresFolhaPagamentoLoading,
      }}>
      {children}
    </ColaboradoresContext.Provider>
  );
};

export function useColaborador() {
  const context = useContext(ColaboradoresContext);

  if (!context) throw new Error('useMenu must be used within an ToastProvider');

  return context;
}
