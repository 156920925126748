import React from 'react';
import logo from 'assets/logo.svg';

const SuspenseLoading = () => {
  return (
    <div className="absolute flex items-center justify-center w-full h-full bg-green-new fade-in-text">
      <img src={logo} alt="AgrogerLogo" width="350" height="120" />
    </div>
  );
};

export { SuspenseLoading };
