export class AtividadeFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getAtividades(filter) {
    return this.instance.get('/v1/cat-atividades', {
      params: filter || null,
    });
  }
  getAtividade(id) {
    return this.instance.get(`/v1/cat-atividades/${id}`);
  }

  addAtividade(data) {
    return this.instance.post('/v1/cat-atividades', data);
  }

  editAtividade(id, data) {
    return this.instance.put(`/v1/cat-atividades/${id}`, data);
  }

  deleteAtividade(id, activityToMove) {
    return this.instance.delete(`/v1/cat-atividades/${id}?activity_to_move=${activityToMove}`);
  }
}

export default (...params) => new AtividadeFactory(...params);

