import React from 'react';

import { RegistrosProvider } from '../controles/registros/';
import { FolhaColheitaProvider } from './folhaColheita';
import { FolhaMensalistaProvider } from './folhaMensalista';
import { VendasProvider } from './vendas';
import { ComprasProvider } from './compras';
import RastreabilidadeProvider from '../controles/rastreabilidade/index';

const ControleProvider = ({ children }) => (
  <RegistrosProvider>
    <FolhaColheitaProvider>
      <FolhaMensalistaProvider>
        <VendasProvider>
          <ComprasProvider>
            <RastreabilidadeProvider>{children}</RastreabilidadeProvider>
          </ComprasProvider>
        </VendasProvider>
      </FolhaMensalistaProvider>
    </FolhaColheitaProvider>
  </RegistrosProvider>
);

export { ControleProvider };
