export class RelColaboradoresFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getServicos(filter) {
    return this.instance.get('/v1/relatorios/servicos', {
      params: filter || null,
    });
  }
}

export default (...params) => new RelColaboradoresFactory(...params);
