export class ParametrosContaFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getParametrosConta() {
    return this.instance.get('/v1/parametros');
  }

  insertParametrosConta(data) {
    return this.instance.post(`/v1/parametros/`, data);
  }

  insertParameterByYear(id, data) {
    return this.instance.post(`/v1/parametros/${id}`, data);
  }

  getParameterByYear(id) {
    return this.instance.get(`/v1/parametros/${id}`);
  }
}

export default (...params) => new ParametrosContaFactory(...params);
