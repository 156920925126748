export class SignIn {
  constructor(instance) {
    this.instance = instance;
  }

  verificarCredenciais({ login, password, modo }) {
    return this.instance.post('/session', { login, password, modo });
  }

  verificarToken() {
    return this.instance.get('/session');
  }

  forgotPassword({ email }) {
    return this.instance.post('/password/forgot', { email });
  }

  resetPassword({ senha, token }) {
    return this.instance.post('/password/reset', { senha, token });
  }

  updateLogin({ login, senha, email }) {
    return this.instance.put('/v1/perfil/migration-version', { senha, login, email });
  }

  showUser() {
    return this.instance.get('/v1/perfil/');
  }

  updateMyUser(dados) {
    return this.instance.put('/v1/perfil/', dados);
  }

  updatePassword({ senha_atual, senha_nova }) {
    return this.instance.put('/v1/perfil/password', { senha_atual, senha_nova });
  }

  listFaturas(filters) {
    return this.instance.get('/adm/licenses', {
      params: filters || null,
    });
  }

  lastFatura() {
    return this.instance.get('/adm/licenses/ultima-fatura');
  }
}

export default (...params) => new SignIn(...params);
