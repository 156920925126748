export class ReciboFactory {
  constructor(instance) {
    this.instance = instance;
  }

  listRecibos(filter) {
    return this.instance.get('/v1/recibos', {
      params: filter || null,
    });
  }

  showRecibo(id) {
    return this.instance.get(`/v1/recibos/${id}`);
  }

  insertRecibo(data) {
    return this.instance.post('/v1/recibos', data);
  }

  updateRecibo(id, data) {
    return this.instance.put(`/v1/recibos/${id}`, data);
  }

  removeRecibo(id) {
    return this.instance.delete(`/v1/recibos/${id}`);
  }
}

export default (...params) => new ReciboFactory(...params);
