import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { useNewToast } from 'hooks/newToast';
import { appWarnings } from 'utils/appWarnings';
import { formatBeneficio } from 'utils/formaters/rastreabilidade/beneficio';

const successful = {
  type: 'success',
  message: 'Benefício gerado com sucesso!',
};

const BeneficioContext = createContext({});

export const BeneficioProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [beneficios, setBeneficios] = useState([]);
  const [beneficiosLoading, setBeneficiosLoading] = useState(false);
  const [gerarBeneficioLoading, setGerarBeneficioLoading] = useState(false);
  const [editBeneficioLoading, setEditBeneficioLoading] = useState(false);
  const [beneficio, setBeneficio] = useState({});
  const [beneficioLoading, setBeneficioLoading] = useState(false);
  const [deleteAdicionalBeneficioLoading, setDeleteAdicionalBeneficioLoading] = useState(false);
  const [deleteBeneficioLoading, setDeleteBeneficioLoading] = useState(false);
  const [deleteLoteBeneficioLoading, setDeleteLoteBeneficioLoading] = useState(false);

  const getBeneficios = useCallback(async filter => {
    try {
      setBeneficiosLoading(true);
      const response = await api.beneficio().getBeneficios(filter);
      setBeneficios(response);
      setBeneficiosLoading(false);
    } catch (err) {
      setBeneficiosLoading(false);
    }
  }, []);

  const getBeneficio = useCallback(async id => {
    try {
      setBeneficioLoading(true);
      const response = await api.beneficio().getBeneficio(id);
      setBeneficio(response);
      setBeneficioLoading(false);
    } catch (errors) {
      setBeneficioLoading(false);
    }
  }, []);

  const resetBeneficio = useCallback(() => {
    setBeneficio({});
  }, []);

  const insertBeneficio = useCallback(
    async dados => {
      const tempData = formatBeneficio(dados);

      try {
        setGerarBeneficioLoading(true);
        // const tempData = formatBeneficio(dados);
        const response = await api.beneficio().gerarBeneficio(tempData);
        setGerarBeneficioLoading(false);
        showToast(successful);
        return response;
      } catch (err) {
        setGerarBeneficioLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast]
  );

  const updateBeneficio = useCallback(
    async (id, dados) => {
      try {
        setEditBeneficioLoading(true);
        const tempData = formatBeneficio(dados);

        const response = await api.beneficio().editBeneficio(id, tempData);
        showToast({ ...successful, message: 'Benefício editado com sucesso!' });
        setEditBeneficioLoading(false);
        return response;
      } catch (err) {
        setEditBeneficioLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast]
  );

  const deleteBeneficio = useCallback(
    async codigo => {
      try {
        setDeleteBeneficioLoading(true);
        await api.beneficio().deleteBeneficio(codigo);
        setBeneficios(beneficios.filter(item => item.id !== codigo));
        setDeleteBeneficioLoading(false);
        getBeneficios();
        showToast({ ...successful, message: 'Processo de Benefício removido com sucesso!' });
      } catch (err) {
        setDeleteBeneficioLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast, beneficios, getBeneficios]
  );

  const deleteAdicional = useCallback(
    async (idBen, idAdd) => {
      try {
        setDeleteAdicionalBeneficioLoading(true);
        await api.beneficio().deleteAdicional(idBen, idAdd);
        setDeleteAdicionalBeneficioLoading(false);
        showToast({ ...successful, message: 'Adicional deletado com sucesso!' });
      } catch (err) {
        setDeleteAdicionalBeneficioLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast]
  );

  const deleteLote = useCallback(
    async (idBen, idLote) => {
      try {
        setDeleteLoteBeneficioLoading(true);
        await api.beneficio().deleteLote(idBen, idLote);
        setDeleteLoteBeneficioLoading(false);
        showToast({ ...successful, message: 'Lote deletado com sucesso!' });
      } catch (err) {
        setDeleteLoteBeneficioLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast]
  );

  return (
    <BeneficioContext.Provider
      value={{
        deleteLoteBeneficioLoading,
        deleteLote,
        deleteAdicional,
        deleteAdicionalBeneficioLoading,
        gerarBeneficioLoading,
        insertBeneficio,
        editBeneficioLoading,
        updateBeneficio,
        deleteBeneficioLoading,
        deleteBeneficio,
        beneficiosLoading,
        beneficios,
        beneficio,
        beneficioLoading,
        getBeneficio,
        getBeneficios,
        resetBeneficio,
      }}>
      {children}
    </BeneficioContext.Provider>
  );
};

export function useBeneficio() {
  const context = useContext(BeneficioContext);

  if (!context) throw new Error('useBeneficio must be used within an AuthProvider');

  return context;
}
