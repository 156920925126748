export class MaquinasFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getMaquinas(filter) {
    return this.instance.get('/v1/cad-maquinas', {
      params: filter || null,
    });
  }

  getHistoricoUtilizacao(id, filter) {
    return this.instance.get(`/v1/cad-maquinas/${id}/historico`, {
      params: filter || null,
    });
  }

  getConsumo(id, filter) {
    return this.instance.get(`/v1/cad-maquinas/${id}/consumo`, {
      params: filter || null,
    });
  }

  getMaquina(id, filter) {
    return this.instance.get(`/v1/cad-maquinas/${id}`, {
      params: filter || null,
    });
  }

  addMaquina(data) {
    return this.instance.post('/v1/cad-maquinas', data);
  }

  reajusteMaquina(id, data) {
    return this.instance.post(
      `/v1/cad-maquinas/${id}/reajuste-valor-hora`,
      data,
    );
  }

  resumoCustoMaquina(id, filter) {
    return this.instance.get(`/v1/cad-maquinas/${id}/resumo-custo`, {
      params: filter || null,
    });
  }

  editMaquina(id, data) {
    return this.instance.put(`/v1/cad-maquinas/${id}`, data);
  }

  deleteMaquina(id) {
    return this.instance.delete(`/v1/cad-maquinas/${id}`);
  }

  deleteFileMachine(id) {
    return this.instance.delete(`/v1/cad-maquinas/${id}/file`);
  }
}

export default (...params) => new MaquinasFactory(...params);
