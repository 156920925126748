export class Logs {
  constructor(instance) {
    this.instance = instance;
  }

  getLogs(filters) {
    delete filters?.initial;

    return this.instance.get('/v1/logs', {
      params: filters || null,
    });
  }
}

export default (...params) => new Logs(...params);
