export class FluxoFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getFluxos(filter) {
    return this.instance.get('/v1/fluxos', {
      params: filter || null,
    });
  }

  getFluxo(id) {
    return this.instance.get(`/v1/fluxos/${id}`);
  }

  addFluxo(data) {
    return this.instance.post('/v1/fluxos', data);
  }

  editFluxo(data, id) {
    return this.instance.put(`/v1/fluxos/${id}`, data);
  }

  getMovimentacoes(id) {
    return this.instance.get(`/v1/fluxos/${id}/12meses`);
  }

  deleteFluxo({ id, fluxo_to_move }) {
    return this.instance.delete(`/v1/fluxos/${id}`, { params: { fluxo_to_move } });
  }

  mostrarSaldo(data) {
    return this.instance.post(`/v1/fluxos/saldo/mostrar`, data);
  }

  showMovimentacao(fluxo_id, id) {
    return this.instance.get(`/v1/fluxos/${fluxo_id}/movimentacao/${id}`);
  }

  createMovimentacao(fluxo_id, data) {
    return this.instance.post(`/v1/fluxos/${fluxo_id}/movimentacao`, data);
  }

  updateMovimentacao(fluxo_id, id, data) {
    return this.instance.put(`/v1/fluxos/${fluxo_id}/movimentacao/${id}`, data);
  }

  removeMovimentacao(fluxo_id, id) {
    return this.instance.delete(`/v1/fluxos/${fluxo_id}/movimentacao/${id}`);
  }

  updateStatusPagamento(fluxo_id, id, status) {
    return this.instance.patch(`/v1/fluxos/${fluxo_id}/movimentacao/${id}`, { status });
  }
}

export default (...params) => new FluxoFactory(...params);
