export class ConfigGeraisFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getBancos(filter) {
    return this.instance.get('/v1/global/bancos', {
      params: filter || null,
    });
  }
}

export default (...params) => new ConfigGeraisFactory(...params);
