export class FazendaFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getFazendas(filter) {
    return this.instance.get('/v1/fazendas', {
      params: filter || null,
    });
  }

  getFazendaAtiva() {
    return this.instance.get('/v1/fazendas/fazenda/principal');
  }

  putFazenda(id, data) {
    return this.instance.put(`/v1/fazendas/${id}`, data);
  }

  delFazenda(id) {
    return this.instance.delete(`/v1/fazendas/${id}`);
  }

  insertFazenda(data) {
    return this.instance.post('/v1/fazendas', data);
  }

  getFazenda(id, data) {
    return this.instance.get(`/v1/fazendas/${id}`, {
      params: { coords: 1 } || null,
    });
  }

  getCoordinatesOfAllTheFarms() {
    return this.instance.get(`/v1/fazendas/coords`);
  }

  addFazendaCoords(data) {
    return this.instance.post('/v1/fazendas-coords', data);
  }
}

export default (...params) => new FazendaFactory(...params);
