import React, { useState } from 'react';
import api from 'services';

const RelatoriosSafras = React.createContext({});

export const RelatoriosSafrasProvider = ({ children }) => {
  const [custosSafras, setCustosSafras] = useState([]);
  const [custosSafrasLoading, setCustosSafrasLoading] = useState(false);

  const [custosSafrasById, setCustosSafrasById] = useState([]);
  const [custosSafrasByIdLoading, setCustosSafrasByIdLoading] = useState(false);

  const [colhidoSafras, setColhidoSafras] = useState([]);
  const [colhidoSafrasLoading, setColhidoSafrasLoading] = useState(false);

  const [colhidoSafrasById, setColhidoSafrasById] = useState([]);
  const [colhidoSafrasByIdLoading, setColhidoSafrasByIdLoading] = useState(false);

  const [atividadesTalhoesSafra, setAtividadesTalhoesSafra] = useState([]);
  const [atividadesTalhoesSafraLoading, setAtividadesTalhoesSafraLoading] = useState(false);

  const [relAtividades, setRelAtividades] = useState({ content: [] });
  const [relAtividadesLoading, setRelAtividadesLoading] = useState(false);

  const getCustosTalhoesSafra = React.useCallback(async filter => {
    try {
      setCustosSafrasLoading(true);
      const response = await api.relatoriosSafras().getCustosTalhoesSafra(filter);
      setCustosSafras(response);
      setCustosSafrasLoading(false);
    } catch {
      setCustosSafrasLoading(false);
    }
  }, []);

  const getCustosTalhoesSafraById = React.useCallback(async (id, filter) => {
    try {
      setCustosSafrasByIdLoading(true);
      const response = await api.relatoriosSafras().getCustosTalhoesSafraById(id, filter);
      setCustosSafrasById(response);
      setCustosSafrasByIdLoading(false);
    } catch {
      setCustosSafrasByIdLoading(false);
    }
  }, []);

  const getColhidoTalhoesSafra = React.useCallback(async filter => {
    try {
      setColhidoSafrasLoading(true);
      const response = await api.relatoriosSafras().getColhidoTalhoesSafra(filter);
      setColhidoSafras(response);
      setColhidoSafrasLoading(false);
    } catch {
      setColhidoSafrasLoading(false);
    }
  }, []);

  const getColhidoTalhoesSafraById = React.useCallback(async (id, filter) => {
    try {
      setColhidoSafrasByIdLoading(true);
      const response = await api.relatoriosSafras().getColhidoTalhoesSafraById(id, filter);
      setColhidoSafrasById(response);
      setColhidoSafrasByIdLoading(false);
    } catch {
      setColhidoSafrasByIdLoading(false);
    }
  }, []);

  const getAtividadesTalhoesSafra = React.useCallback(async filter => {
    try {
      setAtividadesTalhoesSafraLoading(true);
      const response = await api.relatoriosSafras().getAtividadesTalhoesSafra(filter);
      setAtividadesTalhoesSafra(response);
      setAtividadesTalhoesSafraLoading(false);
    } catch {
      setAtividadesTalhoesSafraLoading(false);
    }
  }, []);

  const getRelAtividades = React.useCallback(async filter => {
    try {
      setRelAtividadesLoading(true);
      const response = await api.relatoriosSafras().getRelAtividades(filter);
      setRelAtividades(response);
    } catch {
    } finally {
      setRelAtividadesLoading(false);
    }
  }, []);

  return (
    <RelatoriosSafras.Provider
      value={{
        getCustosTalhoesSafra,
        custosSafras,
        custosSafrasLoading,
        getCustosTalhoesSafraById,
        custosSafrasById,
        custosSafrasByIdLoading,
        getColhidoTalhoesSafra,
        colhidoSafras,
        colhidoSafrasLoading,
        getColhidoTalhoesSafraById,
        colhidoSafrasById,
        colhidoSafrasByIdLoading,
        getAtividadesTalhoesSafra,
        atividadesTalhoesSafra,
        atividadesTalhoesSafraLoading,
        getRelAtividades,
        setRelAtividades,
        relAtividades,
        relAtividadesLoading,
      }}>
      {children}
    </RelatoriosSafras.Provider>
  );
};

export function useRelatoriosSafras() {
  const context = React.useContext(RelatoriosSafras);

  if (!context) throw new Error('useRelInsumo must be used within an RelatoriosSafrasProvider');

  return context;
}
