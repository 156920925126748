import { useNewToast } from 'hooks/newToast';
import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { formatSecador } from 'utils/formaters/rastreabilidade/secador';
import { appWarnings } from 'utils/appWarnings';

const success = {
  type: 'success',
};

const SecadorContext = createContext({});

export const SecadorProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [lotesSecador, setLotesSecador] = useState([]);
  const [lotesSecadorLoading, setLotesSecadorLoading] = useState(false);

  const [secadorLotesHistorico, setSecadorLotesHistorico] = useState([]);
  const [secadorLotesHistoricoLoading, setSecadorLotesHistoricoLoading] =
    useState(false);

  const [secagem, setSecagem] = useState({});
  const [secagemLoading, setSecagemLoading] = useState(false);

  const [gerarNoSecadorLoading, setGerarNoSecadorLoading] = useState(false);
  const [deleteNoSecadorLoading, setDeleteNoSecadorLoading] = useState(false);
  const [editarNoSecadorLoading, setEditarNoSecadorLoading] = useState(false);

  const getLotesSecador = useCallback(async (filter) => {
    try {
      setLotesSecadorLoading(true);
      const response = await api.secador().getLotesSecador(filter);
      setLotesSecador(response);
      setLotesSecadorLoading(false);
    } catch (errors) {
      setLotesSecadorLoading(false);
    }
  }, []);

  const getSecagem = useCallback(async (id, tipo) => {
    try {
      setSecagemLoading(true);

      let response = {};

      if (tipo === 'secagem') {
        response = await api.secador().getSecagem(id);
      } else {
        response = await api.secador().getRessecagem(id);
      }

      setSecagem(response);
      setSecagemLoading(false);
      return response;
    } finally {
      setSecagemLoading(false);
    }
  }, []);

  const getLotesSecadorHistorico = useCallback(async (filters) => {
    try {
      setSecadorLotesHistoricoLoading(true);
      const response = await api.secador().getLotesSecadorHistorico(filters);
      setSecadorLotesHistorico(response.content);
      setSecadorLotesHistoricoLoading(false);
    } catch (errors) {
      setSecadorLotesHistoricoLoading(false);
    }
  }, []);

  const gerarNoSecador = useCallback(
    async (dados) => {
      const newData = formatSecador(dados);

      try {
        setGerarNoSecadorLoading(true);
        const response = await api.secador().gerarNoSecador(newData);
        setGerarNoSecadorLoading(false);
        showToast(success);
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        setGerarNoSecadorLoading(false);
        throw new Error(err);
      }
    },
    [showToast],
  );

  const editarNoSecador = useCallback(
    async (id, dados, tipo = 'secagem') => {
      const newData = formatSecador(dados);

      try {
        setEditarNoSecadorLoading(true);

        const apiFunctions = {
          secagem: {
            apiFunction: (id, data) => api.secador().editarNoSecador(id, data),
            successMessage: 'Entrada no secador editada com sucesso!',
          },
          ressecagem: {
            apiFunction: (id, data) => api.secador().updateRessecagem(id, data),
            successMessage: 'Entrada na ressecagem editada com sucesso!',
          },
        };

        const { apiFunction, successMessage } = apiFunctions[tipo];

        const response = await apiFunction(id, newData);

        setEditarNoSecadorLoading(false);
        showToast({ ...success, message: successMessage });
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        setEditarNoSecadorLoading(false);
        throw new Error(err);
      }
    },
    [showToast],
  );

  const deleteNoSecador = useCallback(
    async (id, tipo) => {
      try {
        setDeleteNoSecadorLoading(true);

        if (tipo === 'secagem') {
          await api.secador().deleteNoSecador(id);
        } else {
          await api.secador().deleteRessecagem(id);
        }

        setDeleteNoSecadorLoading(false);
        getLotesSecador();
        showToast({
          ...success,
          message: 'Processo de secagem removido com sucesso!',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        setDeleteNoSecadorLoading(false);
      }
    },
    [showToast, getLotesSecador],
  );

  return (
    <SecadorContext.Provider
      value={{
        getLotesSecador,
        lotesSecador,
        deleteNoSecador,
        deleteNoSecadorLoading,
        gerarNoSecador,
        gerarNoSecadorLoading,
        getLotesSecadorHistorico,
        lotesSecadorLoading,
        secadorLotesHistorico,
        secadorLotesHistoricoLoading,
        editarNoSecadorLoading,
        editarNoSecador,
        getSecagem,
        secagem,
        secagemLoading,
      }}
    >
      {children}
    </SecadorContext.Provider>
  );
};

export function useSecador() {
  const context = useContext(SecadorContext);

  if (!context)
    throw new Error('useSecador must be used within an AuthProvider');

  return context;
}
