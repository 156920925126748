import React, { useCallback, useState, useContext, createContext } from 'react';
import api from 'services';
import { toDate, dateNow, toPriceInt } from 'utils/converters';
import { useNewToast } from 'hooks/newToast';
import { appWarnings } from 'utils/appWarnings';

const RegMaquinaContext = createContext({});

const success = {
  type: 'success',
};

export const RegMaquinasProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [regMaquinas, setRegMaquinas] = useState([]);
  const [regMaquinasLoading, setRegMaquinasLoading] = useState(false);
  const [regMaquina, setRegMaquina] = useState({});
  const [regMaquinaLoading, setRegMaquinaLoading] = useState(false);
  const [isRemoveRegMaquinaLoading, setIsRemoveRegMaquinaLoading] =
    useState(false);

  const getRegMaquinas = useCallback(async (activityId) => {
    try {
      setRegMaquinasLoading(true);
      const response = await api.regAtividade().getRegMaquinas(activityId);
      setRegMaquinas(response);
    } catch (errors) {
    } finally {
      setRegMaquinasLoading(false);
    }
  }, []);

  const getRegMaquina = useCallback(async (registroId, id) => {
    try {
      setRegMaquinaLoading(true);
      const response = await api.regAtividade().getRegMaquina(registroId, id);
      setRegMaquina(response);
      setRegMaquinaLoading(false);
    } catch (errors) {
      setRegMaquinaLoading(false);
    }
  }, []);

  const insertRegMaquina = useCallback(
    async (registroId, data) => {
      try {
        setRegMaquinaLoading(true);
        const formattedData = {
          maquinas: data?.map((item) => ({
            id: item?.machineId,
            maquina: item?.maquina?.id,
            implemento: item?.implemento?.id || 0,
            km_inicio: item?.km_inicio,
            km_fim: item?.km_final,
            dateStart: toDate(item?.data) || dateNow(),
            dateEnd: toDate(item?.data) || dateNow(),
            valor: toPriceInt(item?.valor),
          })),
        };
        await api.regAtividade().insertRegMaquina(registroId, formattedData);
        getRegMaquinas(registroId);
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setRegMaquinaLoading(false);
      }
    },
    [showToast, getRegMaquinas],
  );

  const removeRegMaquina = useCallback(
    async (activityId, machineId) => {
      try {
        setIsRemoveRegMaquinaLoading(true);
        await api.regAtividade().removeRegMaquina(activityId, machineId);
        showToast({
          ...success,
          message: 'Trabalho de máquina excluído com sucesso!',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setIsRemoveRegMaquinaLoading(false);
      }
    },
    [showToast],
  );

  return (
    <RegMaquinaContext.Provider
      value={{
        getRegMaquinas,
        regMaquinas,
        regMaquinasLoading,
        regMaquina,
        regMaquinaLoading,
        getRegMaquina,
        removeRegMaquina,
        insertRegMaquina,
        setRegMaquinaLoading,
        setRegMaquinas,
        isRemoveRegMaquinaLoading,
      }}
    >
      {children}
    </RegMaquinaContext.Provider>
  );
};

export function useRegMaquina() {
  const context = useContext(RegMaquinaContext);

  if (!context)
    throw new Error('useRegAtividade must be used within an RegAtividades');

  return context;
}
