export class RelIndiretosFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getRelIndiretos(filter) {
    return this.instance.get('/v1/relatorios/indiretos', {
      params: filter || null,
    });
  }
  getRelIndiretosFazendas(safraId, filter) {
    return this.instance.get(`/v1/relatorios/indiretos/${safraId}/fazendas`, {
      params: filter || null,
    });
  }

  downloadRelIndireto(data) {
    return this.instance.post(`/v1/relatorios/indiretos`, data, {
      responseType: 'blob',
    });
  }
}

export default (...params) => new RelIndiretosFactory(...params);
