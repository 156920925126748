export class DespolpadorFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getDespolpagens(filter) {
    return this.instance.get('v1/rastreabilidade/despolpagem', {
      params: filter || null,
    });
  }

  getLotesDespolpagens(filter) {
    return this.instance.get(`/v1/rastreabilidade/despolpagem/lotes`, {
      params: filter || null,
    });
  }

  getLoteDespolpagem(id) {
    return this.instance.get(`/v1/rastreabilidade/despolpagem/lotes/${id}`);
  }

  getSaidasDespolpagens(id, filter) {
    return this.instance.get(`v1/rastreabilidade/despolpagem/saidas/${id}`, {
      params: filter || null,
    });
  }

  deleteDespolpagem(codigo) {
    return this.instance.delete(`v1/rastreabilidade/despolpagem/${codigo}`);
  }

  gerarNoDespolpador(data) {
    return this.instance.post(`v1/rastreabilidade/despolpagem`, data);
  }

  editarNoDespolpador(id, data) {
    return this.instance.put(`v1/rastreabilidade/despolpagem/${id}`, data);
  }

  removeSaidaDespolpagem(id) {
    return this.instance.delete(`v1/rastreabilidade/despolpagem/saida/${id}`);
  }

  removeColhidoDespolpagem(id) {
    return this.instance.delete(`v1/rastreabilidade/despolpagem/colhido/${id}`);
  }
}

export default (...params) => new DespolpadorFactory(...params);
