export class FornecedoresFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getFornecedores(filter) {
    return this.instance.get('/v1/cad-fornecedores/', {
      params: filter || null,
    });
  }

  idFornecedores(id) {
    return this.instance.get(`/v1/cad-fornecedores/${id}`);
  }

  addFornecedor(data) {
    return this.instance.post(`/v1/cad-fornecedores/`, data);
  }

  editFornecedor(id, data) {
    return this.instance.put(`/v1/cad-fornecedores/${id}`, data);
  }

  deleteFornecedor(id) {
    return this.instance.delete(`/v1/cad-fornecedores/${id}`);
  }
}

export default (...params) => new FornecedoresFactory(...params);
