import { useNewToast } from 'hooks/newToast';
import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { appWarnings } from 'utils/appWarnings';
import moment from 'moment';

const success = {
  type: 'success',
  message: 'Venda editada com sucesso!',
};

const error = {
  type: 'error',
  message: 'Venda editada com sucesso!',
};

const LIST_VENDAS = {
  pagination: undefined,
  content: [],
};

const VendasContext = createContext({});

export const VendasProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [vendas, setVendas] = useState(LIST_VENDAS);
  const [vendasList, setVendasList] = useState(LIST_VENDAS);
  const [vendasListLoading, setVendasListLoading] = useState({});

  const [vendasLoading, setVendasLoading] = useState(false);

  const [venda, setVenda] = useState([]);
  const [vendaLoading, setVendaLoading] = useState(false);

  const [addVendaLoading, setAddVendaLoading] = useState(false);
  const [editVendaLoading, setEditVendaLoading] = React.useState(false);
  const [deleteVendaLoading, setDeleteVendaLoading] = useState(false);

  const [itensPorVenda, setItensPorVenda] = useState([]);
  const [itensPorVendaLoading, setItensPorVendaLoading] = useState(false);

  const [lotesVendas, setLotesVendas] = React.useState([]);
  const [lotesVendasLoading, setLotesVendasLoading] = React.useState(false);

  const [removeLotesVendasLoading, setRemoveLotesVendasLoading] = useState(false);

  const [controleEntregasLoading, setControleEntregasLoading] = useState(false);
  const [controleEntregas, setControleEntregas] = useState([]);

  const [historicoEntregasLoading, setHistoricoEntregasLoading] = useState(false);
  const [historicoEntregas, setHistoricoEntregas] = useState([]);

  const [addHistoricoLoading, setAddHistoricoLoading] = useState(false);

  const [deleteHistoricoLoading, setDeleteHistoricoLoading] = useState(false);

  const [finalizarVendaLoading, setFinalizarVendaLoading] = useState(false);

  const [abrirVendaLoading, setAbrirVendaLoading] = useState(false);

  const getVendas = useCallback(async filter => {
    try {
      setVendasLoading(true);
      const response = await api.vendas().getVendas(filter);
      setVendas(response);
      setVendasLoading(false);
    } catch (errors) {
      setVendasLoading(false);
    }
  }, []);

  const getVendasList = useCallback(async filter => {
    try {
      setVendasListLoading(true);
      const response = await api.vendas().getVendas(filter);

      setVendasList(response);
      setVendasListLoading(false);
    } catch (errors) {
      setVendasListLoading(false);
    }
  }, []);

  const getVenda = useCallback(async id => {
    try {
      setVendaLoading(true);
      const response = await api.vendas().getVenda(id);
      setVenda(response);
      setVendaLoading(false);
    } catch (errors) {
      setVendaLoading(false);
    }
  }, []);

  const addVenda = useCallback(
    async data => {
      try {
        setAddVendaLoading(true);
        const { value, produtos } = data;

        if (!produtos || produtos.lotes?.length === 0) {
          showToast({ ...error, message: 'Lotes obrigatórios' });
        }
        const newData = {
          categoria: value.categoria?.id,
          data: value.data,
          cliente: value.cliente.id,
          notaFiscal: value.notaFiscal,
          parcelado: value.parcelamentoEntrega.id,
          obs: value.obs,
          produtos: produtos.lotes.map(item => ({ ...item, lote: item.lote.id })),
        };
        const response = await api.vendas().addVenda(newData);
        showToast({ ...success, message: 'Venda adicionada com sucesso!' });
        setAddVendaLoading(false);
        return response;
      } catch (err) {
        setAddVendaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast]
  );

  const editVenda = useCallback(
    async (id, data) => {
      try {
        setEditVendaLoading(true);
        const { value, produtos } = data;

        if (!produtos || produtos.lotes?.length === 0) {
          showToast({ ...error, message: 'Lotes obrigatórios' });
        }

        const newData = {
          descricao: 'VENDA',
          categoria: value.categoria?.id,
          data: moment(value.data).format('YYYY-MM-DD'),
          cliente: value.cliente.id,
          notaFiscal: value.notaFiscal,
          parcelado: value.parcelamentoEntrega.id,
          obs: value.obs,
          produtos: produtos.lotes.map(item => ({ ...item, lote: item.lote.id })),
        };

        const venda = await api.vendas().editVenda(id, newData);
        setEditVendaLoading(false);
        showToast({ ...success, message: 'Venda editada com sucesso!' });
        return venda;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        setEditVendaLoading(false);
      }
    },
    [showToast]
  );

  const deleteVenda = useCallback(
    async id => {
      try {
        setDeleteVendaLoading(true);
        await api.vendas().deleteVenda(id);
        setVendas(old => ({
          ...old,
          content: [...old?.content?.filter(item => item.id !== id)],
        }));
        setDeleteVendaLoading(false);
        showToast({ ...success, message: 'Venda deletada com sucesso!' });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        setDeleteVendaLoading(false);
        throw new Error();
      }
    },
    [showToast]
  );

  const getItensPorVenda = useCallback(async filter => {
    try {
      setItensPorVendaLoading(true);
      const response = await api.vendas().getItensPorVenda(filter);
      setItensPorVenda(response);
      setItensPorVendaLoading(false);
    } catch (errors) {
      setItensPorVendaLoading(false);
    }
  }, []);

  const getLotesVendas = useCallback(async filter => {
    try {
      setLotesVendasLoading(true);
      const response = await api.vendas().getLotesVendas(filter);
      setLotesVendas(response);
      setLotesVendasLoading(false);
    } catch {
      setLotesVendasLoading(false);
    }
  }, []);

  const removeLotesVendas = useCallback(
    async id => {
      try {
        setRemoveLotesVendasLoading(true);
        await api.vendas().removeLotesVendas(id);
        showToast({ ...success, message: 'Produto deletado com sucesso!' });
        setRemoveLotesVendasLoading(true);
      } catch (err) {
        setRemoveLotesVendasLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast]
  );

  const getControleEntregas = useCallback(async id => {
    try {
      setControleEntregasLoading(true);
      const response = await api.vendas().getControleEntregas(id);
      setControleEntregas(response);
      setControleEntregasLoading(false);
    } catch {
      setControleEntregasLoading(false);
    }
  }, []);

  const resetControleEntregas = useCallback(() => {
    setControleEntregas([]);
  }, []);

  const getHistoricoEntregas = useCallback(async id => {
    try {
      setHistoricoEntregasLoading(true);
      const response = await api.vendas().getHistoricoEntregas(id);
      setHistoricoEntregas(response);
      setHistoricoEntregasLoading(false);
    } catch {
      setHistoricoEntregasLoading(false);
    }
  }, []);

  const resetHistoricoEntregas = useCallback(() => {
    setControleEntregas([]);
  }, []);

  const addHistoricoEntregas = useCallback(
    async (id, data) => {
      try {
        setAddHistoricoLoading(true);
        await api.vendas().addHistoricoEntregas(id, data);
        setAddHistoricoLoading(false);
        showToast({ ...success, message: 'Histórico adicionado com sucesso!' });
      } catch (err) {
        setAddHistoricoLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error();
      }
    },
    [showToast]
  );

  const deleteHistoricoEntregas = useCallback(
    async (idVenda, idHis) => {
      try {
        setDeleteHistoricoLoading(true);
        await api.vendas().deleteHistoricoEntregas(idVenda, idHis);
        setDeleteHistoricoLoading(false);
        showToast({ ...success, message: 'Histórico deletado com sucesso!' });
      } catch (err) {
        setDeleteHistoricoLoading(false);
        showToast(appWarnings.Danger(err));

        throw new Error();
      }
    },
    [showToast]
  );

  const finalizarVenda = useCallback(
    async (id, data) => {
      try {
        setFinalizarVendaLoading(true);
        await api.vendas().finalizarVenda(id, { ...data, fluxo: 0 });
        setFinalizarVendaLoading(false);
        setVenda(old => ({ ...old, status: 'FECHADO' }));
        showToast({ ...success, message: 'Venda finalizada com sucesso!' });
      } catch (err) {
        setFinalizarVendaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error();
      }
    },
    [showToast]
  );

  const abrirVenda = useCallback(
    async id => {
      try {
        setAbrirVendaLoading(true);
        await api.vendas().abrirVenda(id);
        setAbrirVendaLoading(false);
        setVenda(old => ({ ...old, status: 'ABERTO' }));
        showToast({ ...success, message: 'Venda aberta com sucesso!' });
      } catch (err) {
        setAbrirVendaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error();
      }
    },
    [showToast]
  );

  return (
    <VendasContext.Provider
      value={{
        deleteHistoricoLoading,
        deleteHistoricoEntregas,
        vendas,
        getVendas,
        vendasLoading,
        getVenda,
        venda,
        vendaLoading,
        addVendaLoading,
        addVenda,
        editVendaLoading,
        editVenda,
        deleteVendaLoading,
        deleteVenda,
        getItensPorVenda,
        itensPorVendaLoading,
        itensPorVenda,
        getLotesVendas,
        lotesVendas,
        lotesVendasLoading,
        removeLotesVendas,
        removeLotesVendasLoading,
        controleEntregasLoading,
        getControleEntregas,
        controleEntregas,
        resetControleEntregas,
        historicoEntregas,
        historicoEntregasLoading,
        resetHistoricoEntregas,
        getHistoricoEntregas,
        addHistoricoEntregas,
        addHistoricoLoading,
        finalizarVenda,
        finalizarVendaLoading,
        abrirVenda,
        abrirVendaLoading,
        getVendasList,
        vendasList,
        vendasListLoading,
      }}>
      {children}
    </VendasContext.Provider>
  );
};

export function useVendas() {
  const context = useContext(VendasContext);

  if (!context) throw new Error('useVendas must be used within an AuthProvider');

  return context;
}
