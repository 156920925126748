/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useContext, createContext } from 'react';
import api from '../services';
import { storage, resetStorages } from 'utils/appLocalStorage';
import { useNewToast } from 'hooks/newToast';
import { appWarnings } from 'utils/appWarnings';

const errorMessage = {
  type: 'error',
  message: 'Usuário ou senha inválidos, verifique os seus dados!',
  autoClose: false,
};

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { showToast, dismiss } = useNewToast();

  const [loading, setLoading] = useState();
  const [errorLogin, setErrorLogin] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [forgotLoading, setForgotLoading] = React.useState(false);
  const [resetLoading, setResetLoading] = React.useState(false);
  const [isOldUser, setIsOldUser] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [statusTutorial, setStatusTutorial] = React.useState(true);
  const [plano, setPlano] = React.useState('basico');

  const [ultimaFatura, setUltimaFatura] = React.useState({});
  const [ultimaFaturaLoading, setUltimaFaturaLoading] = React.useState({});

  const [data, setData] = useState({
    token: 'initial',
    user: undefined,
  });

  const signIn = useCallback(
    async ({ login, password }) => {
      try {
        setLoading(true);
        const response = await api
          .auth()
          .verificarCredenciais({ login, password, modo: 'web' });

        const { token, user, tutorial } = response;

        if (!token) {
          setLoading(false);
          return showToast(errorMessage);
        }

        setStatusTutorial(tutorial);

        setPlano(user.plano);

        localStorage.setItem(storage.token, token);
        localStorage.setItem(storage.tutorial, tutorial);

        api.instance.defaults.headers.authorization = `Bearer ${token}`;

        setIsAuth(true);
        setIsOldUser(false);

        setData({ token, user });
        dismiss();
        setLoading(false);
      } catch (err) {
        setIsOldUser(false);
        setLoading(false);
        setIsAuth(false);
        setErrorLogin(false);

        if (err?.response?.data?.type === 'financeiro') {
          showToast(appWarnings.Danger2(err));
          throw new Error();
        }

        if (err?.response?.data?.type === 'atualizar_cadastro') {
          setIsOldUser(true);
          setData({ token: null, user: { login, senha: password } });
          throw new Error();
        }
        showToast(appWarnings.authError(err));
        throw new Error(err);
      }
    },
    [showToast],
  );

  React.useEffect(() => {
    const token = localStorage.getItem(storage.token);

    if (token) {
      api.instance.defaults.headers.authorization = `Bearer ${token}`;
      api
        .auth()
        .verificarToken()
        .then((response) => {
          setData({ token, user: response.user });
          setPlano(response.user.plano);
          setStatusTutorial(response.tutorial);
          setIsAuth(true);
        })
        .catch((err) => {
          resetStorages();
          setData({ token: null, user: {} });
          setIsAuth(false);
          if (err?.response?.data?.type === 'financeiro') {
            showToast(appWarnings.Danger2(err));
          }
        });
    } else {
      setData({ token: null, user: {} });
      setIsAuth(false);
    }
  }, []);

  const signOut = useCallback(() => {
    resetStorages();
    setData({});
    setIsAuth(false);
  }, []);

  const updateUser = useCallback(
    (user) => {
      localStorage.setItem(storage.user, JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  const forgotPassword = useCallback(async ({ email }) => {
    try {
      setForgotLoading(true);
      await api.auth().forgotPassword({ email });

      setForgotLoading(false);
    } catch (err) {
      setForgotLoading(false);
      showToast(appWarnings.Danger2(err));
      throw new Error(err);
    }
  }, []);

  const resetPassword = useCallback(async ({ senha, token }) => {
    try {
      setResetLoading(true);
      await api.auth().resetPassword({ senha, token });

      setResetLoading(false);
    } catch (err) {
      setResetLoading(false);
      showToast(appWarnings.Danger2(err));

      throw new Error(err);
    }
  }, []);

  const updateLogin = useCallback(async ({ login, senha, email }) => {
    try {
      setUpdateLoading(true);
      await api.auth().updateLogin({ login, senha, email });
      showToast({
        type: 'success',
        message: 'Login alterado com sucesso',
      });
      setIsOldUser(false);
      setUpdateLoading(false);
    } catch (err) {
      setUpdateLoading(false);
      showToast(appWarnings.Danger2(err));

      throw new Error(err);
    }
  }, []);

  const buscarUltimaFatura = useCallback(async () => {
    try {
      setUltimaFaturaLoading(true);
      const response = await api.auth().lastFatura();
      setUltimaFatura(response);
      setUltimaFaturaLoading(false);
    } catch (err) {
      setUltimaFaturaLoading(false);
      throw new Error(err);
    }
  }, []);

  React.useEffect(() => {
    if (!!isAuth) {
      buscarUltimaFatura();
    } else {
      setUltimaFatura({});
    }
  }, [isAuth, buscarUltimaFatura]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        plano,
        tutorial: statusTutorial,
        errorLogin,
        signIn,
        signOut,
        setStatusTutorial,
        updateUser,
        loading,
        isAuth,
        forgotPassword,
        forgotLoading,
        resetPassword,
        resetLoading,
        isOldUser,
        updateLogin,
        updateLoading,
        ultimaFatura,
        ultimaFaturaLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used within an AuthProvider');

  return context;
}
