import React, { useState, useCallback, useContext, createContext } from 'react';
import { uuid } from 'uuidv4';
import ToastContainer from 'components/ToastContainer';

const ToastContext = createContext({});

export const ToastProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const addToast = useCallback(({ type, title, description }) => {
    const id = uuid();

    const toast = {
      id,
      type,
      title,
      description,
    };

    setMessages(state => [...state, toast]);
  }, []);

  const removeToast = useCallback(id => {
    setMessages(state => state.filter(message => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer style={{ zIndex: 99999 }} messages={messages} />
    </ToastContext.Provider>
  );
};

export function useToast() {
  const context = useContext(ToastContext);

  if (!context) throw new Error('useToast must be used within an ToastProvider');

  return context;
}
