import React from 'react';
import api from 'services';

const RelTalhaoContext = React.createContext({});

export const RelTalhaoProvider = ({ children }) => {
  const [relTalhaoProducao, setRelTalhaoProducao] = React.useState([]);
  const [relTalhaoProducaoLoading, setRelTalhaoProducaoLoading] =
    React.useState([]);

  const getTalhoesProducao = React.useCallback(async (filter) => {
    try {
      setRelTalhaoProducaoLoading(true);
      const newFilters = {
        fazenda: filter?.fazenda?.id,
        atividade: filter?.atividade?.id,
        dateStart: filter?.dateStart === '' ? undefined : filter?.dateStart,
        dateEnd: filter?.dateEnd === '' ? undefined : filter?.dateEnd,
        intervalo: filter?.intervalo === ' - ' ? undefined : filter?.intervalo,
        custo: filter?.custo?.value,
        tipo: filter?.tipo?.value,
        talhoes: filter?.talhoes?.map((item) => item.id),
        safra: filter?.safra,
        parametroconta: filter?.parametroconta?.id,
      };

      const response = await api.relTalhoes().getTalhoesProducao(newFilters);
      setRelTalhaoProducao(response);
      setRelTalhaoProducaoLoading(false);
    } catch (errors) {
      setRelTalhaoProducaoLoading(false);
    }
  }, []);

  return (
    <RelTalhaoContext.Provider
      value={{
        getTalhoesProducao,
        relTalhaoProducao,
        relTalhaoProducaoLoading,
      }}
    >
      {children}
    </RelTalhaoContext.Provider>
  );
};

export function useRelTalhao() {
  const context = React.useContext(RelTalhaoContext);

  if (!context)
    throw new Error('useRelInsumo must be used within an RelInsumoProvider');

  return context;
}
