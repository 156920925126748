export class ServicosFactory {
  constructor(instance) {
    this.instance = instance;
  }

  listServicos(filter) {
    if (filter) delete filter.initial;

    return this.instance.get('/v1/servicos', {
      params: filter || null,
    });
  }

  showServico(id, filter) {
    return this.instance.get(`/v1/servicos/${id}`, {
      params: filter || null,
    });
  }

  insertServico(data) {
    return this.instance.post('/v1/servicos', data);
  }

  updateServico(id, data) {
    return this.instance.put(`/v1/servicos/${id}`, data);
  }

  removeServico(id) {
    return this.instance.delete(`/v1/servicos/${id}`);
  }
}

export default (...params) => new ServicosFactory(...params);
