export class ArmazemFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getLotesArmazem(filter) {
    return this.instance.get(`v1/rastreabilidade/armazem/list`, {
      params: filter || null,
    });
  }

  gerarNoArmazem(data) {
    return this.instance.post(`v1/rastreabilidade/armazem`, data);
  }

  editLoteArmazem(id, data) {
    return this.instance.put(`v1/rastreabilidade/armazem/${id}`, data);
  }

  deleteLoteArmazem(id) {
    return this.instance.delete(`v1/rastreabilidade/armazem/${id}`);
  }

  getLoteArmazem(id) {
    return this.instance.get(`/v1/rastreabilidade/armazem/list/${id}`);
  }

  getLotesArmazemHistorico(filter) {
    return this.instance.get(`v1/rastreabilidade/armazem/historico`, {
      params: filter || null,
    });
  }
}

export default (...params) => new ArmazemFactory(...params);
