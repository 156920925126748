export class EstoqueProdFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getEstoqueProd(filter) {
    return this.instance.get('/v1/estoque-producao/categorias', {
      params: filter || null,
    });
  }

  getLotesByQualidade(filter) {
    return this.instance.get('/v1/rastreabilidade/qualidade', {
      params: filter || null,
    });
  }
}

export default (...params) => new EstoqueProdFactory(...params);
