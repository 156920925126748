export class InvoiceFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getInvoices(filter) {
    return this.instance.get('/v1/invoices', {
      params: filter || null,
    });
  }

  getInvoice(id) {
    return this.instance.get(`/v1/invoices/${id}`);
  }

  checkCnpj(filter) {
    return this.instance.get(`/v1/cad-fornecedores/document`, {
      params: filter || null,
    });
  }

  sendXml(data) {
    const file = new File([`${data?.file}`], data?.name);
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return this.instance.patch(`/v1/invoices/uploads`, formData, config);
  }

  insertInvoice(data) {
    return this.instance.post(`/v1/invoices`, data);
  }

  updateInvoice(id, data) {
    return this.instance.put(`/v1/invoices/${id}`, data);
  }

  deleteInvoice(id) {
    return this.instance.delete(`/v1/invoices/${id}`);
  }

  getInvoiceService(id, filter) {
    return this.instance.get(`/v1/invoices/${id}/items`, {
      params: filter || null,
    });
  }

  deleteInvoiceService(id, idService) {
    return this.instance.delete(`/v1/invoices/${id}/items/${idService}`);
  }

  linkServiceFinancial(id, data) {
    return this.instance.post(`/v1/invoices/${id}/financial`, data);
  }

  linkServiceStock(id, data) {
    return this.instance.post(`/v1/invoices/${id}/stock`, data);
  }
}

export default (...params) => new InvoiceFactory(...params);
