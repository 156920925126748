import { useNewToast } from 'hooks/newToast';
import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { toDate } from 'utils/converters';
import { appWarnings } from 'utils/appWarnings';

const success = {
  type: 'success',
  message: '',
};

const DespolpadorContext = createContext({});

export const DespolpadorProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [despolpagens, setDespolpagens] = useState({});
  const [despolpagensLoading, setDespolpagensLoading] = useState(false);
  const [loteDespolpagem, setLoteDespolpagem] = useState({});
  const [despolpagemLoading, setDespolpagemLoading] = useState(false);

  const [lotesDespolpagens, setLotesDespolpagens] = useState([]);
  const [lotesDespolpagensTotalItems, setLotesDespolpagensTotalItems] =
    useState(0);
  const [lotesDespolpagensLoading, setLotesDespolpagensLoading] =
    useState(false);

  const [saidasDespolpagens, setSaidasDespolpagens] = useState({});
  const [saidasDespolpagensLoading, setSaidasDespolpagensLoading] =
    useState(false);
  const [gerarNoDespolpadorLoading, setGerarNoDespolpadorLoading] =
    useState(false);
  const [editarNoDespolpadorLoading, setEditarNoDespolpadorLoading] =
    useState(false);
  const [deleteDespolpagemLoading, setDeleteDespolpagemLoading] =
    useState(false);
  const [deleteSaidaDespolpagemLoading, setDeleteSaidaDespolpagemLoading] =
    useState(false);
  const [deleteTahaoDespolpagemLoading, setDeleteTahaoDespolpagemLoading] =
    useState(false);

  const getDespolpagens = useCallback(async (filter) => {
    try {
      setDespolpagensLoading(true);
      const response = await api.despolpador().getDespolpagens(filter);
      setDespolpagens(response);
      setDespolpagensLoading(false);
    } catch (errors) {
      setDespolpagensLoading(false);
    }
  }, []);

  const getDespolpagem = useCallback(async (id) => {
    try {
      setDespolpagemLoading(true);
      const response = await api.despolpador().getLoteDespolpagem(id);
      setLoteDespolpagem(response);
      setDespolpagemLoading(false);
    } catch (err) {
      setDespolpagemLoading(false);
    }
  }, []);

  const getLotesDespolpagens = useCallback(async (filter) => {
    try {
      setLotesDespolpagensLoading(true);
      const response = await api.despolpador().getLotesDespolpagens(filter);
      setLotesDespolpagens(response?.content || []);
      setLotesDespolpagensTotalItems(Number(response.total || 0));
      setLotesDespolpagensLoading(false);
    } catch (errors) {
      setLotesDespolpagensLoading(false);
      setLotesDespolpagens([]);
    }
  }, []);

  const getSaidasDespolpagens = useCallback(async (filter) => {
    try {
      setSaidasDespolpagensLoading(true);
      const response = await api.despolpador().getSaidasDespolpagens(filter);
      setSaidasDespolpagens(response);
      setSaidasDespolpagensLoading(false);
    } catch (errors) {
      setSaidasDespolpagensLoading(false);
    }
  }, []);

  const gerarNoDespolpador = useCallback(
    async (dados) => {
      try {
        setGerarNoDespolpadorLoading(true);
        const { despolpador, data, responsavel, obs } = dados.dados;
        const { arrayCafe, arrayTalhoes } = dados;

        const newData = {
          despolpador: despolpador?.id,
          data: toDate(data),
          descricao: obs,
          responsavel,
          talhao: arrayTalhoes
            ?.filter((item) => !!item)
            ?.map((item) => ({
              talhao: item.talhao.id,
              data: toDate(item.colheita),
              quantidade: item.colhido,
            })),
          saida:
            despolpador?.tipo !== 'descascador'
              ? arrayCafe
                  .filter((item) => !!item)
                  .slice(0, 1)
                  .map((item) => ({
                    tipo: item.tipo.nome,
                    quantidade: item.quantidade,
                  }))
              : arrayCafe
                  .filter((item) => !!item)
                  .map((item) => ({
                    tipo: item.tipo.nome,
                    quantidade: item.quantidade,
                  })),
        };
        const response = await api.despolpador().gerarNoDespolpador(newData);
        showToast({
          ...success,
          message: 'Entrada no descascador gerada com sucesso!',
        });
        setGerarNoDespolpadorLoading(false);
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        setGerarNoDespolpadorLoading(false);
        throw new Error(err);
      }
    },
    [showToast],
  );

  const editarNoDespolpador = useCallback(
    async (id, dados) => {
      try {
        setEditarNoDespolpadorLoading(true);
        const { despolpador, data, responsavel, obs } = dados.dados;
        const { arrayCafe, arrayTalhoes } = dados;
        const newData = {
          despolpador: despolpador?.id,
          data: toDate(data),
          descricao: obs,
          responsavel,
          talhao: arrayTalhoes
            ?.filter((item) => !!item)
            ?.map((item) => ({
              id: item.idTalhao,
              talhao: item.talhao.id,
              data: toDate(item.colheita),
              quantidade: item.colhido,
            })),
          saida: arrayCafe
            .filter((item) => !!item)
            ?.map((item) => ({
              id: item.idLote,
              tipo: item.tipo.nome,
              quantidade: item.quantidade,
            })),
        };
        const response = await api
          .despolpador()
          .editarNoDespolpador(id, newData);
        showToast({
          ...success,
          message: 'Entrada no descascador editada com sucesso!',
        });
        setEditarNoDespolpadorLoading(false);
        return response;
      } catch (err) {
        setEditarNoDespolpadorLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast],
  );

  const deleteDespolpagem = useCallback(
    async (id) => {
      try {
        setDeleteDespolpagemLoading(true);
        await api.despolpador().deleteDespolpagem(id);
        setLotesDespolpagens((old) => old.filter((item) => item?.id !== id));
        showToast({
          ...success,
          message: 'Descascagem removida com sucesso!',
        });
        setDeleteDespolpagemLoading(false);
      } catch (err) {
        setDeleteDespolpagemLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast],
  );

  const deleteSaidaDespolpagem = useCallback(
    async (id) => {
      try {
        setDeleteSaidaDespolpagemLoading(true);
        await api.despolpador().removeSaidaDespolpagem(id);
        setLoteDespolpagem({
          ...loteDespolpagem,
          saidas: loteDespolpagem.saidas.filter((item) => item.id !== id),
        });
        showToast({ ...success, message: 'Saída removida com sucesso' });
        setDeleteSaidaDespolpagemLoading(false);
      } catch (error) {
        showToast(appWarnings.Danger(error));
        setDeleteSaidaDespolpagemLoading(false);
        throw new Error(error);
      }
    },
    [loteDespolpagem, showToast],
  );

  const deleteTahaoDespolpagem = useCallback(
    async (id) => {
      try {
        setDeleteTahaoDespolpagemLoading(true);
        await api.despolpador().removeColhidoDespolpagem(id);
        setLoteDespolpagem({
          ...loteDespolpagem,
          entradas: loteDespolpagem.entradas.filter((item) => item.id !== id),
        });
        showToast({ ...success, message: 'Entrada removida com sucesso' });
        setDeleteTahaoDespolpagemLoading(false);
      } catch (error) {
        showToast(appWarnings.Danger(error));
        setDeleteTahaoDespolpagemLoading(false);
        throw new Error(error);
      }
    },
    [loteDespolpagem, showToast],
  );

  return (
    <DespolpadorContext.Provider
      value={{
        getDespolpagens,
        getLotesDespolpagens,
        getSaidasDespolpagens,
        despolpagens,
        deleteSaidaDespolpagem,
        deleteSaidaDespolpagemLoading,
        deleteTahaoDespolpagemLoading,
        deleteTahaoDespolpagem,
        lotesDespolpagens,
        saidasDespolpagens,
        deleteDespolpagem,
        despolpagensLoading,
        lotesDespolpagensLoading,
        saidasDespolpagensLoading,
        deleteDespolpagemLoading,
        gerarNoDespolpador,
        gerarNoDespolpadorLoading,
        editarNoDespolpador,
        editarNoDespolpadorLoading,
        getDespolpagem,
        despolpagemLoading,
        loteDespolpagem,
        lotesDespolpagensTotalItems,
      }}
    >
      {children}
    </DespolpadorContext.Provider>
  );
};

export function useDespolpador() {
  const context = useContext(DespolpadorContext);

  if (!context)
    throw new Error('useDespolpador must be used within an AuthProvider');

  return context;
}
