import { useNewToast } from 'hooks/newToast';
import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { toDate } from 'utils/converters';
import { appWarnings } from 'utils/appWarnings';
const RebeneficioContext = createContext({});

export const RebeneficioProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [rebeneficios, setRebeneficios] = useState([]);
  const [rebeneficioLoading, setRebeneficioLoading] = useState(false);
  const [rebeneficiosLoading, setRebeneficiosLoading] = useState(false);
  const [rebeneficio, setRebeneficio] = useState({});
  const [deleteRebeneficioLoading, setDeleteRebeneficioLoading] = useState(false);
  const [gerarNoRebeneficioLoading, setGerarNoRebeneficioLoading] = useState(false);
  const [editNoRebeneficioLoading, setEditNoRebeneficioLoading] = useState(false);
  const [deleteAdicionalLoading, setDeleteAdicionalLoading] = useState(false);
  const [deleteEscolhaLoading, setDeleteEscolhaLoading] = useState(false);

  const getRebeneficios = useCallback(async filter => {
    try {
      setRebeneficiosLoading(true);
      const response = await api.rebeneficio().getRebeneficios(filter);

      if (response) setRebeneficios(response);
      setRebeneficiosLoading(false);
    } catch (errors) {
      setRebeneficiosLoading(false);
    }
  }, []);

  const getRebeneficio = useCallback(async id => {
    try {
      setRebeneficioLoading(true);
      const response = await api.rebeneficio().getRebeneficio(id);
      setRebeneficio(response);
      setRebeneficioLoading(false);
    } catch (errors) {
      setRebeneficioLoading(false);
    }
  }, []);

  const resetRebeneficio = useCallback(() => {
    setRebeneficio({});
  }, []);

  const gerarNoRebeneficio = useCallback(
    async dados => {
      try {
        setGerarNoRebeneficioLoading(true);
        const { data_entrada, data_saida, hora_entrada, hora_saida, responsavel, medida, medidaTara } = dados.dados;

        const newData = {
          lotes:
            dados.escolherLotes &&
            dados.escolherLotes
              ?.filter(item => !!item)
              ?.map(item => ({
                id: item.idLote,
                codigo: item.codigo,
                quantidade: Number(item.quantidade),
              })),
          adicionais:
            dados.informacoesAdicionais &&
            dados.informacoesAdicionais
              .filter(item => !!item)
              ?.map(item => ({
                descricao: item.descricao,
                tipo: item.informacao.descricao,
                resultado: item.resultado,
              })),
          escolha:
            dados.saidaCafe &&
            dados.saidaCafe
              .filter(item => !!item)
              ?.map(item => ({
                tipo: item?.tipo_cafe?.nome || '',
                qualidade: item.classificacao.id,
                quantidade: item.qtd_cafe,
              })),
          dataEntrada: toDate(data_entrada),
          horaEntrada: hora_entrada,
          dataSaida: data_saida && toDate(data_saida),
          horaSaida: hora_saida,
          medida,
          medidaTara,
          responsavel,
        };

        const response = await api.rebeneficio().gerarRebeneficio(newData);
        setGerarNoRebeneficioLoading(false);
        showToast({
          type: 'success',
          message: 'Entrada no Rebenefício gerada com sucesso!',
        });
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        setGerarNoRebeneficioLoading(false);
        throw new Error(err);
      }
    },
    [showToast]
  );

  const editNoRebeneficio = useCallback(
    async (id, dados) => {
      try {
        setEditNoRebeneficioLoading(true);
        const { data_entrada, data_saida, hora_entrada, hora_saida, responsavel, medida, medidaTara } = dados.dados;

        const newData = {
          lotes:
            dados.escolherLotes &&
            dados.escolherLotes
              ?.filter(item => item?.idLote)
              ?.map(item => ({
                id: item.idLote,
                ref_id: item.ref_id,
                codigo: item.codigo,
                quantidade: Number(item.quantidade),
              })),
          adicionais:
            dados.informacoesAdicionais &&
            dados.informacoesAdicionais
              .filter(item => !!item)
              ?.map(item => ({
                id: item.idDefault,
                descricao: item.descricao,
                tipo: item.informacao.descricao,
                resultado: item.resultado,
              })),
          escolha:
            dados.saidaCafe &&
            dados.saidaCafe
              .filter(item => !!item)
              ?.map(item => ({
                id: item.idDefault,
                tipo: item?.tipo_cafe?.nome || '',
                qualidade: item.classificacao.id,
                quantidade: item.qtd_cafe,
              })),
          dataEntrada: toDate(data_entrada),
          horaEntrada: hora_entrada,
          dataSaida: data_saida && toDate(data_saida),
          horaSaida: hora_saida,
          medida,
          medidaTara,
          responsavel,
        };

        const response = await api.rebeneficio().editRebeneficio(id, newData);
        setEditNoRebeneficioLoading(false);
        showToast({
          type: 'success',
          message: 'Entrada no Rebenefício editada com sucesso!',
        });
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        setEditNoRebeneficioLoading(false);
        throw new Error(err);
      }
    },
    [showToast]
  );

  const deleteRebeneficio = useCallback(
    async codigo => {
      try {
        setDeleteRebeneficioLoading(true);
        await api.rebeneficio().deleteRebeneficio(codigo);
        setRebeneficios(rebeneficios.filter(item => item.id !== codigo));
        setDeleteRebeneficioLoading(false);
        getRebeneficios();
        showToast({
          type: 'success',
          message: 'Processo de Rebenefício removido com sucesso!',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));

        setDeleteRebeneficioLoading(false);
      }
    },
    [rebeneficios, showToast, getRebeneficios]
  );

  const deleteEscolha = useCallback(
    async (idRebeneficio, id) => {
      try {
        setDeleteEscolhaLoading(true);
        await api.rebeneficio().deleteEscolha(idRebeneficio, id);
        setRebeneficio({
          ...rebeneficio,
          quantidades: rebeneficio.quantidades.filter(item => item.id !== id),
        });
        setDeleteEscolhaLoading(false);
        showToast({
          type: 'success',
          message: 'Escolha deletada com sucesso!',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        setDeleteEscolhaLoading(false);
        throw new Error(err);
      }
    },
    [showToast, rebeneficio]
  );

  const deleteAdicional = useCallback(
    async (idRebeneficio, id) => {
      try {
        setDeleteAdicionalLoading(true);
        await api.rebeneficio().deleteAdicional(idRebeneficio, id);
        setRebeneficio({
          ...rebeneficio,
          adicional: rebeneficio.adicional.filter(item => item.id !== id),
        });
        setDeleteAdicionalLoading(false);
        showToast({
          type: 'success',
          message: 'Informação adicional deletada com sucesso!',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        setDeleteAdicionalLoading(false);
        throw new Error(err);
      }
    },
    [showToast, rebeneficio]
  );

  return (
    <RebeneficioContext.Provider
      value={{
        gerarNoRebeneficioLoading,
        gerarNoRebeneficio,
        editNoRebeneficioLoading,
        editNoRebeneficio,
        deleteRebeneficioLoading,
        deleteRebeneficio,
        rebeneficiosLoading,
        rebeneficios,
        rebeneficio,
        rebeneficioLoading,
        getRebeneficio,
        getRebeneficios,
        resetRebeneficio,
        deleteEscolha,
        deleteAdicional,
        deleteAdicionalLoading,
        deleteEscolhaLoading,
      }}>
      {children}
    </RebeneficioContext.Provider>
  );
};

export function useRebeneficio() {
  const context = useContext(RebeneficioContext);

  if (!context) throw new Error('useRebeneficio must be used within an AuthProvider');

  return context;
}
