import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { toPriceInt } from 'utils/converters';
import { useNewToast } from 'hooks/newToast';
import { appWarnings } from 'utils/appWarnings';

const FolhaMensalistaContext = createContext({});

const folhaList = [
  {
    id: 'mensalista',
    descricao: 'Mão de Obra',
  },
  {
    id: 'colheita',
    descricao: 'Colheita',
  },
];

const pagamentoList = [
  {
    id: 1,
    descricao: 'Pagamento Único',
  },
  {
    id: 2,
    descricao: 'Pagamento Parcelado',
  },
];

export const FolhaMensalistaProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [folhaDetails, setFolhaDetails] = useState([]);
  const [folhaDetailsLoading, setFolhaDetailsLoading] = useState(false);

  const [folhasMensalista, setFolhasMensalista] = useState([]);
  const [folhasMensalistaLoading, setFolhasMensalistaLoading] = useState(false);
  const [folhaMensalista, setFolhaMensalista] = useState({});
  const [folhaMensalistaLoading, setFolhaMensalistaLoading] = useState(false);

  const [insertFolhaLoading, setInsertFolhaLoading] = useState(false);
  const [changeFolhaLoading, setChangeFolhaLoading] = useState(false);
  const [deleteFolhaLoading, setDeleteFolhaLoading] = useState(false);
  const [fecharFolhaMensalistaLoading, setFecharFolhaMensalistaLoading] = useState(false);
  const [abrirFolhaMensalistaLoading, setAbrirFolhaMensalistaLoading] = useState(false);
  const [setPagamentoMensalistaLoading, setSetPagamentoMensalistaLoading] = useState(false);

  const [folhasMensalistaLimite, setFolhasMensalistaLimite] = useState({ content: [], pagination: {} });
  const [folhasMensalistaLimiteLoading, setFolhasMensalistaLimiteLoading] = useState(false);

  const getFolhasMensalista = useCallback(async filter => {
    try {
      setFolhasMensalistaLoading(true);
      const response = await api.folhaMensalista().getFolhasMensalista(filter);
      setFolhasMensalista(response);
      setFolhasMensalistaLoading(false);
    } catch (errors) {
      setFolhasMensalistaLoading(false);
    }
  }, []);

  const getFolhasMensalistaLimit = useCallback(async filter => {
    try {
      setFolhasMensalistaLimiteLoading(true);
      const response = await api.folhaMensalista().getFolhasMensalista(filter);
      setFolhasMensalistaLimite(response);
      setFolhasMensalistaLimiteLoading(false);
    } catch (errors) {
      setFolhasMensalistaLimiteLoading(false);
    }
  }, []);

  const getFolhaMensalista = useCallback(async id => {
    try {
      setFolhaMensalistaLoading(true);
      const response = await api.folhaMensalista().getFolhaMensalista(id);
      setFolhaMensalista(response);
      setFolhaMensalistaLoading(false);
    } catch (errors) {
      setFolhaMensalistaLoading(false);
    }
  }, []);

  const insertFolha = useCallback(
    async data => {
      try {
        setInsertFolhaLoading(true);
        const { descricao, periodo, fluxo, data: date, categoria } = data.sobre;
        const dataInicio = periodo?.substr(0, 10);
        const dataFim = periodo?.substr(13, 21);
        const selectedColabs = data.colaboradores.map(colaborador => colaborador.id);
        const talhao = {
          filtrar_talhoes: data?.talhao?.filtrar_talhoes?.id || 0,
          talhoes: Array.isArray(data?.talhao?.talhoes) ? data?.talhao?.talhoes.map(item => Number(item.id)) : [],
        };

        const newData = {
          descricao,
          inicio: dataInicio,
          colaboradores: selectedColabs,
          fim: dataFim,
          data: date,
          fluxo: fluxo?.id,
          categoria: categoria?.id,
          talhao,
        };
        const response = await api.folhaMensalista().insertFolha(newData);
        setInsertFolhaLoading(false);
        showToast({
          type: 'success',
          message: 'Folha adicionada com sucesso!',
        });
        return response;
      } catch (err) {
        setInsertFolhaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast]
  );

  const changeFolha = useCallback(
    async (id, data) => {
      try {
        setChangeFolhaLoading(true);

        const { descricao, periodo, fluxo, conta, pagamento, data: date, categoria } = data.sobre;

        const dataInicio = periodo?.substr(0, 10);
        const dataFim = periodo?.substr(13, 21);
        const selectedColabs = data.colaboradores.map(colaborador => colaborador.id);
        const talhao = {
          filtrar_talhoes: data?.talhao?.filtrar_talhoes?.id || 0,
          talhoes: Array.isArray(data?.talhao?.talhoes) ? data?.talhao?.talhoes.map(item => Number(item.id)) : [],
        };

        const newData = {
          descricao,
          colaboradores: selectedColabs,
          inicio: dataInicio,
          fim: dataFim,
          data: date,
          fazenda: 13,
          conta: conta?.id,
          fluxo: fluxo?.id,
          categoria: categoria?.id,
          pagamento: pagamento?.id,
          talhao,
        };
        await api.folhaMensalista().editFolha(id, newData);
        getFolhaMensalista(id);
        showToast({
          type: 'success',
          message: 'Folha editada com sucesso!',
        });
        setChangeFolhaLoading(false);
      } catch (err) {
        setChangeFolhaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast, getFolhaMensalista]
  );

  const removeFolha = useCallback(
    async id => {
      try {
        setDeleteFolhaLoading(true);
        await api.folhaMensalista().removeFolha(id);
        setFolhasMensalista(folhasMensalista.filter(item => item.id !== id));
        setDeleteFolhaLoading(false);
        showToast({
          type: 'success',
          message: 'Folha deletada com sucesso!',
        });
      } catch (err) {
        setDeleteFolhaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [folhasMensalista, showToast]
  );

  const getFolhaDetails = useCallback(async (id, filter) => {
    try {
      setFolhaDetailsLoading(true);
      const response = await api.folhaMensalista().getFolhaDetails(id, filter);
      setFolhaDetails(response);
      setFolhaDetailsLoading(false);
    } catch (errors) {
      setFolhaDetailsLoading(false);
    }
  }, []);

  const editFolhaDetail = useCallback(
    async (id, data, colaborador) => {
      try {
        setFolhaDetailsLoading(true);
        const { valor } = data;

        const newData = {
          valor: toPriceInt(valor),
        };
        await api.folhaMensalista().editFolhaDetail(id, newData, colaborador);
        getFolhaDetails(id);
        setFolhaDetailsLoading(false);
      } catch (err) {
        setFolhaDetailsLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast, getFolhaDetails]
  );

  const setPagamentoMensalista = useCallback(
    async (idFolha, idColaborador, data) => {
      try {
        setSetPagamentoMensalistaLoading(true);
        const { valor } = data;
        const newData = {
          valor: toPriceInt(valor),
        };
        await api.folhaMensalista().setPagamentoMensalista(idFolha, idColaborador, newData);
        showToast({
          type: 'success',
          message: 'Valor reajustando com sucesso',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setSetPagamentoMensalistaLoading(false);
      }
    },
    [showToast]
  );

  const resetFolhaMensalista = useCallback(() => {
    setFolhaMensalista({});
  }, []);

  const resetFolhaMensalistaDetail = useCallback(() => {
    setFolhaDetails([]);
  }, []);

  const abrirFolhaMensalista = useCallback(
    async id => {
      try {
        setAbrirFolhaMensalistaLoading(true);
        await api.folhaMensalista().abrirFolhaMensalista(id);
        await getFolhaMensalista(id);
        await getFolhaDetails(id);
        showToast({
          type: 'success',
          message: 'Folha aberta com sucesso!',
        });
        setAbrirFolhaMensalistaLoading(false);
      } catch (err) {
        setAbrirFolhaMensalistaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast, getFolhaMensalista, getFolhaDetails]
  );

  const fecharFolhaMensalista = useCallback(
    async (id, data) => {
      try {
        setFecharFolhaMensalistaLoading(true);
        const { categoria, rateio } = data;

        const newData = {
          categoria: categoria?.id,
          rateio: rateio?.id,
        };

        await api.folhaMensalista().fecharFolhaMensalista(id, newData);
        await getFolhaMensalista(id);
        await getFolhaDetails(id);
        showToast({
          type: 'success',
          message: 'Folha fechada com sucesso!',
        });
        setFecharFolhaMensalistaLoading(false);
      } catch (err) {
        setFecharFolhaMensalistaLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast, getFolhaMensalista, getFolhaDetails]
  );

  return (
    <FolhaMensalistaContext.Provider
      value={{
        getFolhaMensalista,
        getFolhasMensalista,
        getFolhaDetails,
        editFolhaDetail,
        changeFolha,
        insertFolha,
        removeFolha,
        resetFolhaMensalista,
        resetFolhaMensalistaDetail,
        abrirFolhaMensalista,
        abrirFolhaMensalistaLoading,
        fecharFolhaMensalista,
        fecharFolhaMensalistaLoading,
        folhaMensalista,
        folhaDetails,
        folhasMensalista,
        folhaDetailsLoading,
        insertFolhaLoading,
        changeFolhaLoading,
        deleteFolhaLoading,
        folhasMensalistaLoading,
        folhaMensalistaLoading,
        pagamentoList,
        folhaList,
        setPagamentoMensalista,
        setPagamentoMensalistaLoading,
        getFolhasMensalistaLimit,
        folhasMensalistaLimite,
        folhasMensalistaLimiteLoading,
      }}>
      {children}
    </FolhaMensalistaContext.Provider>
  );
};

export function useFolhaMensalista() {
  const context = useContext(FolhaMensalistaContext);

  if (!context) throw new Error('useFolhaMensalista must be used within an AuthProvider');

  return context;
}
