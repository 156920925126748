export class ImplementoFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getImplementos(filter) {
    return this.instance.get('/v1/cad-implementos', {
      params: filter || null,
    });
  }
  getImplemento(id, filter) {
    return this.instance.get(`/v1/cad-implementos/${id}`, {
      params: filter || null,
    });
  }
  addImplemento(data) {
    return this.instance.post('/v1/cad-implementos', data);
  }

  editImplemento(id, data) {
    return this.instance.put(`/v1/cad-implementos/${id}`, data);
  }

  deleteImplemento(id) {
    return this.instance.delete(`/v1/cad-implementos/${id}`);
  }

  deleteFileImplemento(id) {
    return this.instance.delete(`/v1/cad-implementos/${id}/file`);
  }

  getHistoricoUtilizacao(id, filter) {
    return this.instance.get(`/v1/cad-implementos/${id}/historico`, {
      params: filter || null,
    });
  }
}

export default (...params) => new ImplementoFactory(...params);
