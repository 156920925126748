export class ManutencaoFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getManutencoes(id, filter) {
    return this.instance.get(`/v1/cad-maquinas/${id}/manutencao`, {
      params: filter || null,
    });
  }

  getManutencao(id, idManutencao, filter) {
    return this.instance.get(
      `/v1/cad-maquinas/${id}/manutencao/${idManutencao}`,
      {
        params: filter || null,
      },
    );
  }

  addManutencao(id, data) {
    return this.instance.post(`/v1/cad-maquinas/${id}/manutencao`, data);
  }

  editManutencao(id, idManutencao, data) {
    return this.instance.put(
      `/v1/cad-maquinas/${id}/manutencao/${idManutencao}`,
      data,
    );
  }

  deleteManutencao(id, idManutencao) {
    return this.instance.delete(
      `/v1/cad-maquinas/${id}/manutencao/${idManutencao}`,
    );
  }

  getManutencoesImplementos(id, filter) {
    if ('initial' in filter) {
      delete filter.initial;
    }

    if ('intervalo' in filter) {
      delete filter.intervalo;
    }

    return this.instance.get(`/v1/cad-implementos/${id}/manutencao`, {
      params: filter || null,
    });
  }

  getManutencaoImplemento(id, idManutencao, filter) {
    return this.instance.get(
      `/v1/cad-implementos/${id}/manutencao/${idManutencao}`,
      {
        params: filter || null,
      },
    );
  }

  addManutencaoImplemento(id, data) {
    return this.instance.post(`/v1/cad-implementos/${id}/manutencao`, data);
  }

  updateManutencaoImplemento(id, idManutencao, data) {
    return this.instance.put(
      `/v1/cad-implementos/${id}/manutencao/${idManutencao}`,
      data,
    );
  }

  deleteManutencaoImplemento(implementoId, idManutencao) {
    return this.instance.delete(
      `/v1/cad-implementos/${implementoId}/manutencao/${idManutencao}`,
    );
  }
}

export default (...params) => new ManutencaoFactory(...params);
