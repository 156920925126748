var lotesRebeneficio = [
  {
    id: 1,
    codigo: 'RMAR-21002',
    data_entrada: '2021-04-22',
    data_saida: '2021-04-24',
    hora_entrada: '08:00',
    hora_saida: '10:00',
    responsavel: 'João Felipe',
    medida: 'Sacas',
    medida_tara: '60',
    entradas: [
      {
        id: 1,
        codigo: 'MAR-20005',
        quantidade: '5000',
      },
      {
        id: 2,
        codigo: 'MAR-20011',
        quantidade: '400',
      },
    ],
    quantidades: [
      {
        id: 1,
        tipo: 'Natural',
        qualidade: 'BEBIDA MOLE',
        quantidade: '200',
      },
      {
        id: 2,
        tipo: 'Verde',
        qualidade: 'BEBIDA MOLE',
        quantidade: '1000',
      },
      {
        id: 3,
        tipo: 'Boia',
        qualidade: 'FINE CUP (DURO UNIFORME)',
        quantidade: '500',
      },
      {
        id: 4,
        tipo: 'Cereja',
        qualidade: 'GRAND FINO',
        quantidade: '1000',
      },
    ],
  },
  {
    id: 2,
    codigo: 'RMAR-21001',
    data_entrada: '2020-02-20',
    data_saida: '2021-04-22',
    hora_entrada: '07:00',
    hora_saida: '12:00',
    responsavel: 'João Felipe',
    medida: 'Sacas',
    medida_tara: '60',
    entradas: [
      {
        id: 1,
        codigo: 'MAR-20005',
        quantidade: '5000.00',
      },
      {
        id: 2,
        codigo: 'MAR-20011',
        quantidade: '400.00',
      },
    ],
    quantidades: [
      {
        id: 1,
        tipo: 'Natural',
        qualidade: 'BEBIDA MOLE',
        quantidade: '200',
      },
      {
        id: 2,
        tipo: 'Verde',
        qualidade: 'BEBIDA MOLE',
        quantidade: '1000',
      },
      {
        id: 3,
        tipo: 'Boia',
        qualidade: 'FINE CUP (DURO UNIFORME)',
        quantidade: '500',
      },
      {
        id: 4,
        tipo: 'Cereja',
        qualidade: 'GRAND FINO',
        quantidade: '1000',
      },
    ],
  },
];

export function db_loteRebeneficioList() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(lotesRebeneficio);
    }, 1000);
  });
}

var escolherRebeneficio = [
  {
    id: 1,
    codigo: 'MAR-20002',
    trava_codigo: 'M',
    quantidade: '500.00',
  },
  {
    id: 2,
    codigo: 'MAR-20003',
    trava_codigo: 'M',
    quantidade: '100.00',
  },
  {
    id: 3,
    codigo: 'MAR-20004',
    trava_codigo: 'M',
    quantidade: '666.00',
  },
  {
    id: 4,
    codigo: 'MAR-20006',
    trava_codigo: 'M',
    quantidade: '100.00',
  },
  {
    id: 5,
    codigo: 'MAR-20007',
    trava_codigo: 'M',
    quantidade: '109.00',
  },
  {
    id: 6,
    codigo: 'MAR-20008',
    trava_codigo: 'M',
    quantidade: '100.00',
  },
  {
    id: 7,
    codigo: 'MAR-20009',
    trava_codigo: 'M',
    quantidade: '1000.00',
  },
  {
    id: 8,
    codigo: 'MAR-20010',
    trava_codigo: 'M',
    quantidade: '2839.00',
  },
  {
    id: 9,
    codigo: 'MAR-20012',
    trava_codigo: 'M',
    quantidade: '555.00',
  },
  {
    id: 10,
    codigo: 'MAR-20013',
    trava_codigo: 'M',
    quantidade: '6000.00',
  },
];

export function db_escolherRebeneficioList() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(escolherRebeneficio);
    }, 1000);
  });
}
