export class BeneficioFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getBeneficios(filter) {
    return this.instance.get(`v1/rastreabilidade/beneficio`, {
      params: filter || null,
    });
  }

  getBeneficio(id, filter) {
    return this.instance.get(`v1/rastreabilidade/beneficio/${id}`, {
      params: filter || null,
    });
  }

  gerarBeneficio(data) {
    return this.instance.post(`v1/rastreabilidade/beneficio`, data);
  }

  editBeneficio(id, data) {
    return this.instance.put(`v1/rastreabilidade/beneficio/${id}`, data);
  }

  deleteBeneficio(id) {
    return this.instance.delete(`v1/rastreabilidade/beneficio/${id}`);
  }

  deleteAdicional(idBen, idAdd) {
    return this.instance.delete(
      `v1/rastreabilidade/beneficio/${idBen}/adicionais/${idAdd}`
    );
  }

  deleteLote(idBen, idLote) {
    return this.instance.delete(`v1/rastreabilidade/beneficio/${idBen}/lotes/${idLote}`);
  }
}

export default (...params) => new BeneficioFactory(...params);
