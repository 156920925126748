export class EstoqueFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getEstoques(filter) {
    delete filter.initial;

    return this.instance.get('/v1/cat-estoque', {
      params: filter,
    });
  }

  addEstoque(data) {
    return this.instance.post('/v1/cat-estoque', data);
  }

  editEstoque(id, data) {
    return this.instance.put(`/v1/cat-estoque/${id}`, data);
  }

  deleteEstoque(id) {
    return this.instance.delete(`/v1/cat-estoque/${id}`);
  }
}

export default (...params) => new EstoqueFactory(...params);
