export class Favorites {
  constructor(instance) {
    this.instance = instance;
  }

  list(filters) {
    return this.instance.get('/v1/favorites', {
      params: filters || null,
    });
  }

  create(data) {
    return this.instance.post('/v1/favorites/', data);
  }
}

export default (...params) => new Favorites(...params);
