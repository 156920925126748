import React, { useCallback, useState, useContext, createContext } from 'react';

const ModalColaboradorContext = createContext({});

export const ModalColaboradorProvider = ({ children }) => {
  const [show, setShow] = useState(false);

  const showModal = useCallback(() => {
    setShow(true);
  }, []);

  const closeModal = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <ModalColaboradorContext.Provider
      value={{
        show,
        showModal,
        setShow,
        closeModal,
      }}>
      {children}
    </ModalColaboradorContext.Provider>
  );
};

export function useModalColaborador() {
  const context = useContext(ModalColaboradorContext);

  if (!context)
    throw new Error('useModalColaborador must be used within an Config Provider');

  return context;
}
