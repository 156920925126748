var lotesArmazem = [
  {
    id: 1,
    glo_pilha_id: 50,
    codigo: 'MAR-20010',
    entrada: '2020-06-11',
    saida: '',
    umidade_entrada: '12.00',
    umidade_saida: '0.00',
    responsavel: 'Pedro',
    quantidade: '2839.00',
  },
  {
    id: 2,
    glo_pilha_id: 50,
    codigo: 'MAR-20009',
    entrada: '2020-04-03',
    saida: '',
    umidade_entrada: '14.00',
    umidade_saida: '10.00',
    responsavel: 'Pedro',
    quantidade: '1000.00',
  },
];

export function db_loteArmazemList() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(lotesArmazem);
    }, 1000);
  });
}

var escolherArmazemList = [
  {
    id: 2,
    codigo: "MAR-20001",
    quantidade: "1000.00"
  },
  {
    id: 3,
    codigo: "MAR-20002",
    quantidade: "500.00"
  },
  {
    id: 4,
    codigo: "MAR-20003",
    quantidade: "100.00"
  },
  {
    id: 5,
    codigo: "MAR-20004",
    quantidade: "666.00"
  },
  {
    id: 6,
    codigo: "MAR-20005",
    quantidade: "5000.00"
  },
  {
    id: 7,
    codigo: "MAR-20006",
    quantidade: "100.00"
  },
  {
    id: 8,
    codigo: "MAR-20007",
    quantidade: "109.00"
  },
  {
    id: 9,
    codigo: "MAR-20008",
    quantidade: "100.00"
  },
  {
    id: 10,
    codigo: "MAR-20011",
    trava_codigo: "M",
    quantidade: "400.00"
  },
  {
    id: 11,
    codigo: "MAR-20012",
    quantidade: "555.00"
  },
  {
    id: 12,
    codigo: "MAR-20013",
    quantidade: "6000.00"
  },
  {
    id: 13,
    codigo: "MAR-20014",
    quantidade: "1200.00"
  },
  {
    id: 14,
    codigo: "MAR-20015",
    quantidade: "1500.00"
  },
  {
    id: 15,
    codigo: "MAR-21016",
    quantidade: "3000.00"
  }
];

export function db_escolherArmazemList() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(escolherArmazemList);
    }, 1000);
  });
}

