import React from 'react';

import { RegistroAtividadeProvider } from './registroAtividades';
import { RegColheitaManualProvider } from './registroColheitaManual';
import { RegColheitaMecanicaProvider } from './registroColheitaMecanica';
import { RegExtrasProvider } from './registroExtras';
import { RegInsumosProvider } from './registroInsumos';
import { RegCaldaProvider } from './registroCaldas';
import { RegMaquinasProvider } from './registroMaquinas';
import { RegMdobrasProvider } from './registroMdobra';

const RegistrosProvider = ({ children }) => (
  <RegColheitaManualProvider>
    <RegColheitaMecanicaProvider>
      <RegExtrasProvider>
        <RegInsumosProvider>
          <RegCaldaProvider>
            <RegMaquinasProvider>
              <RegMdobrasProvider>
                <RegistroAtividadeProvider>{children}</RegistroAtividadeProvider>
              </RegMdobrasProvider>
            </RegMaquinasProvider>
          </RegCaldaProvider>
        </RegInsumosProvider>
      </RegExtrasProvider>
    </RegColheitaMecanicaProvider>
  </RegColheitaManualProvider>
);

export { RegistrosProvider };
