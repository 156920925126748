export const codigos = [
  {
    codigo: 1,
  },
  {
    codigo: 2,
  },
  {
    codigo: 3,
  },
  {
    codigo: 4,
  },
  {
    codigo: 5,
  },
  {
    codigo: 6,
  },
  {
    codigo: 7,
  },
  {
    codigo: 8,
  },
  {
    codigo: 9,
  },
  {
    codigo: 10,
  },
  {
    codigo: 11,
  },
  {
    codigo: 12,
  },
  {
    codigo: 13,
  },
  {
    codigo: 14,
  },
  {
    codigo: 15,
  },
  {
    codigo: 16,
  },
  {
    codigo: 17,
  },
  {
    codigo: 18,
  },
  {
    codigo: 19,
  },
  {
    codigo: 20,
  },
];

const tipoConta = [
  {
    id: 'CORRENTE',
    descricao: 'Corrente',
  },
  {
    id: 'POUPANCA',
    descricao: 'Poupança',
  },
];

const SLOGAN_AGROGER = 'Agroger - O software do cafeicultor';

export { tipoConta, SLOGAN_AGROGER };
