export class FileFactory {
  constructor(instance) {
    this.instance = instance;
  }

  sendFile(data) {
    const formData = new FormData();
    formData.append('file', data?.file);
    formData.append('description', data?.description);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return this.instance.post(`/v1/files/`, formData, config);
  }

  deleteFile(data) {
    return this.instance.post(`/v1/files/remove`, data);
  }

  downloadFile(data) {
    return this.instance.post(`/v1/files/download`, data, { responseType: 'blob' });
  }
}

export default (...params) => new FileFactory(...params);
