import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { toDate, dateNow } from 'utils/converters';
import { useRegColheitaManual } from 'hooks/providers/controles/registros/registroColheitaManual';
import { useRegColheitaMecanica } from 'hooks/providers/controles/registros/registroColheitaMecanica';
import { useRegMaquina } from 'hooks/providers/controles/registros/registroMaquinas';
import { useRegInsumo } from 'hooks/providers/controles/registros/registroInsumos';
import { useRegMdobra } from 'hooks/providers/controles/registros/registroMdobra';
import { useRegExtra } from 'hooks/providers/controles/registros/registroExtras';
import { useRegCalda } from 'hooks/providers/controles/registros/registroCaldas';
import { useNewToast } from 'hooks/newToast';
import { appWarnings } from 'utils/appWarnings';

const success = {
  type: 'success',
  autoClose: 3000,
};

const LIST = {
  content: [],
  pagination: undefined,
};

const RegAtividadeContext = createContext({});

export const RegistroAtividadeProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const { insertRegColheitaManual } = useRegColheitaManual();
  const { insertRegColheitaMecanica } = useRegColheitaMecanica();
  const { insertRegExtra } = useRegExtra();
  const { insertRegInsumos } = useRegInsumo();
  const { insertRegCaldas } = useRegCalda();
  const { insertRegMaquina } = useRegMaquina();
  const { insertRegMaodeobra } = useRegMdobra();

  const [regAtividades, setRegAtividades] = useState(LIST);
  const [regAtividadesLoading, setRegAtividadesLoading] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [regAtividade, setRegAtividade] = useState({});
  const [regAtividadeLoading, setRegAtividadeLoading] = useState(false);

  const [regAtividadesPorTalhao, setRegAtividadesPorTalhao] = useState();
  const [regAtividadesPorTalhaoLoading, setRegAtividadesPorTalhaoLoading] =
    useState(false);
  const [insertAtividadeLoading, setInsertAtividadeLoading] = useState(false);
  const [insertDuplicateAtividadeLoading, setInsertDuplicateAtividadeLoading] =
    useState(false);

  const [regAtividadesFiltered, setRegAtividadesFiltered] = useState({
    content: [],
  });

  const [regAtividadesFilteredLoading, setRegAtividadesFilteredLoading] =
    useState(false);
  const [
    modifyRegAtividadesFilteredLoading,
    setModifyRegAtividadesFilteredLoading,
  ] = useState(false);

  const getRegAtividadeByTalhao = useCallback(async (filter) => {
    try {
      const filters = {
        ...filter,
        talhao: filter?.talhao?.id,
        fazenda: filter?.fazenda || filter?.fazenda?.id,
        atividade: filter?.atividade?.id,
      };

      setRegAtividadesPorTalhaoLoading(true);
      const response = await api
        .regAtividade()
        .getRegAtividadeByTalhao(filters);

      setRegAtividadesPorTalhao(response);
      setRegAtividadesPorTalhaoLoading(false);
    } catch (errors) {
      setRegAtividadesPorTalhaoLoading(false);
    }
  }, []);

  const getRegAtividades = useCallback(async (filter) => {
    try {
      const filters = {
        ...filter,
        talhao: filter?.talhao?.id,
        fazenda: filter?.fazenda || filter?.fazenda?.id,
        atividade: filter?.atividade?.id,
      };

      setRegAtividadesLoading(true);
      const response = await api.regAtividade().getRegAtividades(filters);

      setRegAtividades({
        content: response?.content || [],
        pagination: response?.pagination || undefined,
      });
    } finally {
      setRegAtividadesLoading(false);
    }
  }, []);

  const getRegAtividadesFiltered = useCallback(async (filter) => {
    try {
      const filters = {
        safra: filter?.safra,
        dateStart: filter?.dateStart,
        dateEnd: filter?.dateEnd,
        modo: filter?.modo?.id,
        talhao: filter?.talhao?.id,
      };

      setRegAtividadesFilteredLoading(true);
      const response = await api
        .regAtividade()
        .getRegAtividadesFiltered(filters);

      setRegAtividadesFiltered(response);
    } finally {
      setRegAtividadesFilteredLoading(false);
    }
  }, []);

  const modifyRegAtividadesFiltered = useCallback(
    async (data) => {
      try {
        const dataFormated = {
          safra: data?.safra,
          dateStart: data?.dateStart,
          dateEnd: data?.dateEnd,
          modo: data?.modo?.id,
          talhao: data?.talhao?.id,
          newValue: data?.newValue,
        };

        setModifyRegAtividadesFilteredLoading(true);
        await api.regAtividade().modifyRegAtividadesFiltered(dataFormated);

        showToast({
          ...success,
          message:
            'Alteração dos valores de registo de colheita feito com sucesso.',
        });
      } catch {
        showToast(
          appWarnings.Danger(
            'Ocorreu um erro ao tentar alterar os registros, tente novamente mais tarde!',
          ),
        );
      } finally {
        setModifyRegAtividadesFilteredLoading(false);
      }
    },
    [showToast],
  );

  const getRegAtividade = useCallback(async (id) => {
    try {
      if (!id) return false;
      setRegAtividadeLoading(true);
      const response = await api.regAtividade().getRegAtividade(id);
      setRegAtividade(response);
      setRegAtividadeLoading(false);
      return response;
    } catch (errors) {
      setRegAtividadeLoading(false);
      return false;
    }
  }, []);

  const insertAndUpdateRegAtividade = useCallback(
    async (id, data) => {
      try {
        setInsertAtividadeLoading(true);
        const {
          about,
          edit,
          extra,
          sprayMixture,
          inputs,
          mechanicalHarvesting,
          machine,
          labor,
          manualHarvest,
        } = data;

        const talhao =
          about?.talhao?.nome === 'GASTOS GERAIS' ? 0 : about?.talhao?.id;
        const fazenda =
          about?.talhao?.nome === 'GASTOS GERAIS'
            ? about?.talhao?.id
            : about?.talhao?.gloFazendaId;

        const activityLogData = {
          id,
          safra: about?.safra?.id,
          talhao,
          fazenda,
          atividade: about?.atividade?.id,
          data: about?.data ? toDate(about?.data) : dateNow(),
          obs: about?.obs,
        };

        const response = await api
          .regAtividade()
          .insertRegAtividade(activityLogData);
        setRegAtividade(response);
        const registroId = response?.id;

        if (extra?.length > 0 && !edit?.extra) {
          await insertRegExtra(registroId, extra);
        }
        if (sprayMixture?.length > 0 && !edit?.sprayMixture)
          await insertRegCaldas(registroId, sprayMixture);
        if (inputs?.length > 0 && !edit?.inputs) {
          await insertRegInsumos(registroId, inputs);
        }
        if (mechanicalHarvesting?.length > 0 && !edit?.mechanicalHarvesting) {
          await insertRegColheitaMecanica(registroId, mechanicalHarvesting);
        }
        if (machine?.length > 0 && !edit?.machine) {
          await insertRegMaquina(registroId, machine);
        }
        if (labor?.length > 0 && !edit?.labor) {
          await insertRegMaodeobra(registroId, labor);
        }
        if (manualHarvest?.length > 0 && !edit?.manualHarvest) {
          await insertRegColheitaManual(registroId, manualHarvest);
        }
        if (id) {
          showToast({
            ...success,
            message: 'Registro de atividade editado com sucesso!',
          });
        } else {
          showToast({
            ...success,
            message: 'Registro de atividade adicionado com sucesso!',
          });
        }
        return response;
      } catch (err) {
        showToast(appWarnings.Danger('Erro ao adicionar atividade.'));
        throw new Error(err);
      } finally {
        setInsertAtividadeLoading(false);
      }
    },
    [
      showToast,
      insertRegMaodeobra,
      insertRegColheitaManual,
      insertRegColheitaMecanica,
      insertRegInsumos,
      insertRegCaldas,
      insertRegMaquina,
      insertRegExtra,
    ],
  );

  const removeRegistro = useCallback(
    async (registro, talhao) => {
      try {
        setDeleteLoading(true);
        await api.regAtividade().removeRegistro(registro);

        setRegAtividades((old) => ({
          ...old,
          content: old.content.filter((item) => item.id !== registro),
        }));

        setRegAtividadesPorTalhao((old) => {
          const existsTalhao = old && old.content && old.content[talhao];
          if (!existsTalhao) return old;
          return {
            ...old,
            content: {
              ...old.content,
              [talhao]: old.content[talhao]
                .map((item) => {
                  const atividade = item?.atividades.find(
                    (tempItem) => tempItem.id === registro,
                  );

                  if (
                    item.atividades &&
                    item.atividades.length > 0 &&
                    atividade
                  ) {
                    return {
                      ...item,
                      atividades: item.atividades.filter(
                        (ativ) => ativ.id !== registro,
                      ),
                    };
                  }

                  if (item.id === registro && item?.atividades.length > 0) {
                    return {
                      ...item,
                      ...item.atividades[0],
                      atividades: item.atividades.slice(
                        1,
                        item.atividades.length,
                      ),
                    };
                  }

                  if (item.id === registro) {
                    return {
                      id: null,
                    };
                  }

                  return item;
                })
                .filter((item) => item.id),
            },
          };
        });
        showToast({ ...success, message: 'Atividade removida com sucesso!' });

        setDeleteLoading(false);
      } catch (err) {
        setDeleteLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error();
      }
    },
    [showToast],
  );

  const insertDuplicateAtividade = useCallback(
    async (id, data) => {
      try {
        setInsertDuplicateAtividadeLoading(true);
        const dataDefault = {
          registro_id: id,
          safra: data.safra.id,
          talhao: data?.talhao.id,
          fazenda: data?.talhao?.gloFazendaId,
          atividade: data.atividade.id,
          data: toDate(data.data),
        };

        await api.regAtividade().insertDuplicateAtividade(dataDefault);
        showToast({ ...success, message: 'Atividade duplicada com sucesso!' });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setInsertDuplicateAtividadeLoading(false);
      }
    },
    [showToast],
  );

  return (
    <RegAtividadeContext.Provider
      value={{
        getRegAtividades,
        regAtividades,
        regAtividadesLoading,
        getRegAtividade,
        regAtividade,
        regAtividadeLoading,
        getRegAtividadeByTalhao,
        regAtividadesPorTalhao,
        regAtividadesPorTalhaoLoading,
        insertAndUpdateRegAtividade,
        setRegAtividade,
        removeRegistro,
        deleteLoading,
        insertAtividadeLoading,
        insertDuplicateAtividade,
        insertDuplicateAtividadeLoading,
        getRegAtividadesFiltered,
        regAtividadesFiltered,
        regAtividadesFilteredLoading,
        modifyRegAtividadesFiltered,
        modifyRegAtividadesFilteredLoading,
      }}
    >
      {children}
    </RegAtividadeContext.Provider>
  );
};

export function useRegAtividade() {
  const context = useContext(RegAtividadeContext);

  if (!context)
    throw new Error('useRegAtividade must be used within an RegAtividades');

  return context;
}
