export class RelFinanceiroFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getRelMensal(data, filter) {
    delete filter?.intervalo;
    return this.instance.post('/v1/financeiro/relatorios/', data, {
      params: filter || null,
    });
  }
}

export default (...params) => new RelFinanceiroFactory(...params);
