import { useNewToast } from 'hooks/newToast';
import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { appWarnings } from 'utils/appWarnings';
import moment from 'moment';
const success = {
  type: 'success',
};

const ComprasContext = createContext({});

export const ComprasProvider = ({ children }) => {
  const { showToast } = useNewToast();
  const [compras, setCompras] = useState([]);
  const [compra, setCompra] = useState({});
  const [produtos, setProdutos] = useState([]);
  const [comprasLoading, setComprasLoading] = useState(false);
  const [produtosLoading, setProdutosLoading] = useState(false);
  const [compraLoading, setCompraLoading] = useState(false);

  const [addCompraLoading, setAddCompraLoading] = useState(false);
  const [editCompraLoading, setEditCompraLoading] = useState(false);
  const [abrirCompraLoading, setAbrirCompraLoading] = useState(false);
  const [deleteCompraLoading, setDeleteCompraLoading] = useState(false);
  const [deleteProdutoLoading, setDeleteProdutoLoading] = useState(false);
  const [finalizarCompraLoading, setFinalizarCompraLoading] = useState(false);
  const [changeStatusCompraLoading, setChangeStatusCompraLoading] = useState(false);

  const getCompras = useCallback(async filter => {
    try {
      setComprasLoading(true);
      const filterDefault = {
        ...filter,
        safra: filter?.safra?.id,
      };
      const response = await api.compras().getCompras(filterDefault);
      setCompras(response);
      setComprasLoading(false);
    } catch (errors) {
      setComprasLoading(false);
    }
  }, []);

  const getCompra = useCallback(async id => {
    try {
      setCompraLoading(true);
      const response = await api.compras().getCompra(id);
      setCompra(response);
      setCompraLoading(false);
    } catch (errors) {
      setCompraLoading(false);
    }
  }, []);

  const finalizarCompra = useCallback(
    async (id, data) => {
      try {
        setFinalizarCompraLoading(true);
        const response = await api.compras().finalizarCompra(id, data);
        setCompra(old => ({ ...old, status: 'FECHADO' }));
        showToast({ ...success, message: 'Compra finalizada com sucesso!' });
        setFinalizarCompraLoading(false);
        return response;
      } catch (errors) {
        showToast(appWarnings.Danger(errors));
        setFinalizarCompraLoading(false);
        throw new Error(errors);
      }
    },
    [showToast]
  );

  const abrirCompra = useCallback(
    async id => {
      try {
        setAbrirCompraLoading(true);
        await api.compras().abrirCompra(id);
        setCompra(old => ({ ...old, status: 'ABERTO' }));
        showToast({ ...success, message: 'Compra aberta com sucesso!' });
        setAbrirCompraLoading(false);
      } catch (err) {
        setAbrirCompraLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error();
      }
    },
    [showToast]
  );

  const changeStatusCompra = useCallback(async (id, idProduto, value) => {
    setChangeStatusCompraLoading(idProduto);
    try {
      await api.compras().patchCompra(id, idProduto, value);
      setProdutos(old => old.map(item => (item.id === idProduto ? { ...item, ...value } : item)));
      setTimeout(() => setChangeStatusCompraLoading(null), 1000);
    } catch (err) {
      setChangeStatusCompraLoading(null);
    }
  }, []);

  const addCompra = useCallback(
    async data => {
      try {
        setAddCompraLoading(true);
        const NewDados = {
          categoria: data.categoria.id,
          descricao: data.descricao,
          refId: data.tipo.id,
          data: moment(data.data).format('YYYY-MM-DD'),
          refTipo: data.refTipo || 'fornecedor',
          notafiscal: data.notaFiscal,
          status: 'ABERTO',
          obs: data.obs,
          produtos: data.produtos.map(item => ({
            descricao: item.descricao,
            entregue: item.entregue.id,
            quantidade: item.quantidade,
            valor: item.valor,
            safra: item.safra.id,
          })),
        };
        const response = await api.compras().addCompra(NewDados);
        getCompras();
        showToast({ ...success, message: 'Compra adicionada com sucesso!' });
        setAddCompraLoading(false);
        return response;
      } catch (errors) {
        showToast(appWarnings.Danger(errors));
        setAddCompraLoading(false);
        throw new Error(errors);
      }
    },
    [showToast, getCompras]
  );

  const editCompra = useCallback(
    async (id, data) => {
      try {
        setEditCompraLoading(true);
        const NewDados = {
          categoria: data.categoria.id,
          descricao: data.descricao,
          refId: data.tipo.id,
          data: moment(data.data).format('YYYY-MM-DD'),
          refTipo: data.refTipo || 'fornecedor',
          notafiscal: data.notaFiscal,
          status: data.status ? data.status : 'ABERTO',
          obs: data.obs,
          produtos: data.produtos.map(item => ({
            id: item.idProduto,
            descricao: item.descricao,
            entregue: item.entregue.id,
            quantidade: item.quantidade,
            valor: item.valor,
            safra: item.safra.id,
          })),
        };
        const response = await api.compras().editCompra(id, NewDados);
        getCompras();
        showToast({ ...success, message: 'Compra editada com sucesso!' });
        setEditCompraLoading(false);
        return response;
      } catch (errors) {
        showToast(appWarnings.Danger(errors));
        setEditCompraLoading(false);
        throw new Error(errors);
      }
    },
    [showToast, getCompras]
  );

  const deleteCompra = useCallback(
    async id => {
      try {
        setDeleteCompraLoading(true);
        const response = await api.compras().deleteCompra(id);
        getCompras();
        showToast({ ...success, message: 'Compra removida com sucesso!' });
        setDeleteCompraLoading(false);
        return response;
      } catch (errors) {
        showToast(appWarnings.Danger(errors));
        setDeleteCompraLoading(false);
        throw new Error(errors);
      }
    },
    [showToast, getCompras]
  );

  const getProdutos = useCallback(async id => {
    try {
      setProdutosLoading(true);
      const response = await api.compras().getProdutos(id);
      setProdutos(response);
      setProdutosLoading(false);
    } catch (errors) {
      setProdutosLoading(false);
    }
  }, []);

  const deleteProduto = useCallback(
    async (id, idProduto) => {
      try {
        setDeleteProdutoLoading(true);
        const response = await api.compras().deleteProduto(id, idProduto);
        setProdutos(old => [...old.filter(item => item.id !== idProduto)]);
        showToast({ ...success, message: 'Produto removido com sucesso!' });
        setDeleteProdutoLoading(false);
        return response;
      } catch (errors) {
        showToast(appWarnings.Danger(errors));
        setDeleteProdutoLoading(false);
        throw new Error(errors);
      }
    },
    [showToast]
  );

  return (
    <ComprasContext.Provider
      value={{
        getCompras,
        compras,
        abrirCompraLoading,
        abrirCompra,
        comprasLoading,
        getCompra,
        compra,
        changeStatusCompraLoading,
        changeStatusCompra,
        compraLoading,
        produtosLoading,
        addCompra,
        produtos,
        getProdutos,
        deleteProduto,
        addCompraLoading,
        editCompra,
        editCompraLoading,
        deleteProdutoLoading,
        deleteCompra,
        deleteCompraLoading,
        finalizarCompra,
        finalizarCompraLoading,
      }}>
      {children}
    </ComprasContext.Provider>
  );
};

export function useCompras() {
  const context = useContext(ComprasContext);

  if (!context) throw new Error('useCompras must be used within an AuthProvider');

  return context;
}
