import React from 'react';

import { ModalColaboradorProvider } from './modalColaborador';
import { ModalFuncaoProvider } from './modalFuncao';
import { ModalSafraProvider } from './modalSafra';
import { ModalTalhaoProvider } from './modalTalhao';

const ModaisProvider = ({ children }) => (
  <ModalColaboradorProvider>
    <ModalFuncaoProvider>
      <ModalSafraProvider>
        <ModalTalhaoProvider>{children}</ModalTalhaoProvider>
      </ModalSafraProvider>
    </ModalFuncaoProvider>
  </ModalColaboradorProvider>
);

export default ModaisProvider;
