import React, { Suspense, lazy } from 'react';

import './App.css';
import './styles/main.css';
import 'styles/global.scss';
import AppProvider from './hooks';
import GlobalStyle from './styles/index';
import { SuspenseLoading } from 'components/suspense-loading';

const Routes = lazy(() => import('./routes'));

const App = () => {
  return (
    <Suspense fallback={<SuspenseLoading />}>
      <AppProvider>
        <Routes />
      </AppProvider>
      <GlobalStyle />
    </Suspense>
  );
};

export { App };
