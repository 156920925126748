export class UsuarioFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getUsuarios(filter) {
    return this.instance.get('/v1/cat-usuarios', {
      params: filter,
    });
  }

  getUsuario(id, filter) {
    return this.instance.get(`/v1/cat-usuarios/${id}`, {
      params: filter,
    });
  }

  addUsuario(data) {
    return this.instance.post('/v1/cat-usuarios', data);
  }

  editUsuario(id, data) {
    return this.instance.put(`/v1/cat-usuarios/${id}`, data);
  }

  deleteUsuario(id) {
    return this.instance.delete(`/v1/cat-usuarios/${id}`);
  }
}

export default (...params) => new UsuarioFactory(...params);
