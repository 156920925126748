export class TutorialFactory {
  constructor(instance) {
    this.instance = instance;
  }

  sendTutorial() {
    return this.instance.patch('/users/tutorial');
  }
}

export default (...params) => new TutorialFactory(...params);
