export class CadEstoqueFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getCadEstoque(filter) {
    return this.instance.get('/v1/cad-insumos/estoque', {
      params: filter || null,
    });
  }

  getEstoqueEntradas(filter) {
    if (filter?.initial) delete filter.initial;
    delete filter.intervalo;

    return this.instance.get('/v1/cad-estoque/entrada', {
      params: filter || null,
    });
  }

  getEstoqueEntrada(id) {
    return this.instance.get(`/v1/cad-estoque/entrada/${id}`);
  }

  getProdutosPorEntrada(id, filter) {
    return this.instance.get(`/v1/cad-estoque/entrada/${id}/listProdutos`, {
      params: filter || null,
    });
  }

  getProdutosPorSaida(id, filter) {
    return this.instance.get(`/v1/cad-estoque/saida/${id}/listProdutos`, {
      params: filter || null,
    });
  }

  inserirEntrada(data) {
    return this.instance.post(`/v1/cad-estoque/entrada`, data);
  }

  editarEntrada(data, id) {
    return this.instance.put(`/v1/cad-estoque/entrada/${id}`, data);
  }

  inserirSaida(data) {
    return this.instance.post(`/v1/cad-estoque/saida`, data);
  }

  editarSaida(data, id) {
    return this.instance.put(`/v1/cad-estoque/saida/${id}`, data);
  }

  abrirEntrada(id) {
    return this.instance.patch(`/v1/cad-estoque/entrada/abrir/${id}`);
  }

  fecharEntrada(id, data) {
    return this.instance.put(`/v1/cad-estoque/entrada/finalizar/${id}`, data);
  }

  abrirSaida(id) {
    return this.instance.patch(`/v1/cad-estoque/saida/abrir/${id}`);
  }

  fecharSaida(id, data) {
    return this.instance.put(`/v1/cad-estoque/saida/finalizar/${id}`, data);
  }

  deleteProdutoEntrada(idEntrada, idProduto) {
    return this.instance.delete(
      `/v1/cad-estoque/entrada/${idEntrada}/removeProduto/${idProduto}`,
    );
  }

  deleteProdutoSaida(idSaida, idProduto) {
    return this.instance.delete(
      `/v1/cad-estoque/saida/${idSaida}/removeProduto/${idProduto}`,
    );
  }

  getEstoqueSaidas(filter) {
    delete filter.intervalo;
    return this.instance.get('/v1/cad-estoque/saida', {
      params: filter || null,
    });
  }

  getEstoqueSaida(id) {
    return this.instance.get(`/v1/cad-estoque/saida/${id}`);
  }
  deleteSaida(id) {
    return this.instance.delete(`/v1/cad-estoque/saida/${id}`);
  }

  deleteEntrada(id) {
    return this.instance.delete(`/v1/cad-estoque/entrada/${id}`);
  }

  getItensNoEstoque(filter) {
    return this.instance.get(`/v1/cad-estoque`, {
      params: filter || null,
    });
  }
}

export default (...params) => new CadEstoqueFactory(...params);
