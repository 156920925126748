export class DashboardFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getProductivity(id, filters) {
    return this.instance.get(`/v1/dashboard/${id}/produtividade`, {
      params: filters || null,
    });
  }

  getIndicators(id, filters) {
    return this.instance.get(`/v1/dashboard/${id}/indicadores`, {
      params: filters || null,
    });
  }

  getLandDashboard(id, filters) {
    return this.instance.get(`/v1/dashboard/${id}/talhoes`, {
      params: filters || null,
    });
  }
}

export default (...params) => new DashboardFactory(...params);
