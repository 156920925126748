export class ApontamentoFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getApontamentos(filter) {
    return this.instance.get('/v1/cat-apontamentos/', {
      params: filter || null,
    });
  }

  addApontamento(data) {
    return this.instance.post('/v1/cat-apontamentos', data);
  }
  
  editApontamento(id, data) {
    return this.instance.put(`/v1/cat-apontamentos/${id}`, data);
  }
  
  deleteApontamento(id) {
    return this.instance.delete(`/v1/cat-apontamentos/${id}`);
  }
}



export default (...params) => new ApontamentoFactory(...params);
