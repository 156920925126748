export class HistoricoFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getHistoricos(id, filter) {
    return this.instance.get(`/v1/cad-insumos/historico/${id}`, {
      params: filter || null,
    });
  }
}

export default (...params) => new HistoricoFactory(...params);
