import React from 'react';

import { DespolpadorProvider } from './despolpador';
import { TerreiroProvider } from './terreiro';
import { SecadorProvider } from './secador';
import { ArmazemProvider } from './armazem';
import { BeneficioProvider } from './beneficio';
import { RebeneficioProvider } from './rebeneficio';
import { LotesProvider } from './lotes';

const RastreabilidadeProvider = ({ children }) => (
  <LotesProvider>
    <DespolpadorProvider>
      <TerreiroProvider>
        <SecadorProvider>
          <BeneficioProvider>
            <RebeneficioProvider>
              <ArmazemProvider>{children}</ArmazemProvider>
            </RebeneficioProvider>
          </BeneficioProvider>
        </SecadorProvider>
      </TerreiroProvider>
    </DespolpadorProvider>
  </LotesProvider>
);

export default RastreabilidadeProvider;
