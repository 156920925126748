import React, { useState, useCallback } from 'react';
import api from 'services';
import { useNewToast } from 'hooks/newToast';
import { appWarnings } from 'utils/appWarnings';

const success = {
  type: 'success',
  message: '',
};

const cafeTipos = [
  {
    id: 1,
    nome: 'Verde',
  },
  {
    id: 2,
    nome: 'Natural',
  },
  {
    id: 3,
    nome: 'Boia',
  },
  {
    id: 4,
    nome: 'Boinha',
  },
  {
    id: 5,
    nome: 'Cereja',
  },
  {
    id: 6,
    nome: 'Cereja2',
  },
];

export const useQualidadeCafe = () => {
  const { showToast } = useNewToast();
  const [qualidadesLoading, setQualidadesLoading] = useState(false);
  const [qualidades, setQualidades] = useState([]);
  const [addQualidadeLoading, setAddQualidadeLoading] = React.useState(false);
  const [editQualidadeLoading, setEditQualidadeLoading] = React.useState(false);
  const [deleteQualidadeLoading, setDeleteQualidadeLoading] = React.useState(false);

  const getQualidades = useCallback(async filter => {
    try {
      setQualidadesLoading(true);
      const response = await api.qualidade_cafe().getQualidades(filter);
      setQualidades(response);
      return response;
    } catch (errors) {
    } finally {
      setQualidadesLoading(false);
    }
  }, []);

  const addQualidade = useCallback(
    async data => {
      try {
        setAddQualidadeLoading(true);
        const response = await api.qualidade_cafe().addQualidade(data);
        setAddQualidadeLoading(false);
        showToast({ ...success, message: 'Qualidade de café adicionada com sucesso!' });
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setAddQualidadeLoading(false);
      }
    },
    [showToast]
  );

  const editQualidade = useCallback(
    async (id, data) => {
      try {
        setEditQualidadeLoading(true);
        const response = await api.qualidade_cafe().editQualidade(id, data);
        showToast({ ...success, message: 'Qualidade de café editada com sucesso!' });
        return response;
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setEditQualidadeLoading(false);
      }
    },
    [showToast]
  );

  const deleteQualidade = useCallback(
    async id => {
      try {
        setDeleteQualidadeLoading(true);
        await api.qualidade_cafe().deleteQualidade(id);
        showToast({ ...success, message: 'Qualidade de café removida com sucesso!' });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setDeleteQualidadeLoading(false);
      }
    },
    [showToast]
  );

  return {
    cafeTipos,
    qualidades,
    qualidadesLoading,
    getQualidades,
    addQualidade,
    addQualidadeLoading,
    editQualidade,
    editQualidadeLoading,
    deleteQualidade,
    deleteQualidadeLoading,
  };
};
