export class CadPilhasFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getPilhas(filter) {
    return this.instance.get('/v1/cad-pilhas/', {
      params: filter || null,
    });
  }

  getPilha(filter, id) {
    return this.instance.get(`/v1/cad-pilhas/${id}`, {
      params: filter || null,
    });
  }

  addPilha(data) {
    return this.instance.post(`/v1/cad-pilhas/`, data);
  }

  editPilha(id, data) {
    return this.instance.put(`/v1/cad-pilhas/${id}`, data);
  }

  deletePilha(id) {
    return this.instance.delete(`/v1/cad-pilhas/${id}`);
  }
}

export default (...params) => new CadPilhasFactory(...params);
