export class CadFermentadorFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getFermentadores(filter) {
    return this.instance.get('/v1/cad-fermentadores/', {
      params: filter || null,
    });
  }

  getFermentador(filter, id) {
    return this.instance.get(`/v1/cad-armazens/${id}`, {
      params: filter || null,
    });
  }

  addFermentador(data) {
    return this.instance.post(`/v1/cad-fermentadores/`, data);
  }

  editFermentador(id, data) {
    return this.instance.put(`/v1/cad-fermentadores/${id}`, data);
  }

  deleteFermentador(id) {
    return this.instance.delete(`/v1/cad-fermentadores/${id}`);
  }
}

export default (...params) => new CadFermentadorFactory(...params);
