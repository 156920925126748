export class PlanejamentoFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getPlanejamento(filter, type) {
    return this.instance.get(`/v1/planejamentos/orcamentos/${type}`, {
      params: filter || null,
    });
  }
  getOrcamento(filter, type) {
    return this.instance.get(`/v1/planejamentos/orcamentos`, {
      params: filter || null,
    });
  }

  getGraficoVendasReceita(filter) {
    return this.instance.get(`/v1/planejamentos/orcamentos/grafico-receita`, {
      params: filter || null,
    });
  }

  getPlanejamentoRelatorio(filter) {
    return this.instance.get(`/v1/planejamentos/orcamentos/relatorio`, {
      params: filter || null,
    });
  }

  insertOrcamentos(data) {
    return this.instance.put(`/v1/planejamentos/orcamentos`, data);
  }

  getGraficoOrcamento(filters) {
    return this.instance.get('/v1/planejamentos/orcamentos/grafico', {
      params: filters || null,
    });
  }
}

export default (...params) => new PlanejamentoFactory(...params);
