export class SafraFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getSafras(filter) {
    return this.instance.get('/v1/cat-safras', {
      params: filter || null,
    });
  }

  getSafra(id) {
    return this.instance.get(`/v1/cat-safras/${id}`);
  }

  getSafraAtual() {
    return this.instance.get('/v1/cat-safras/safra/ativa');
  }

  insertSafra(data) {
    return this.instance.post(`/v1/cat-safras/`, data);
  }

  insertSafraTutorial(data) {
    return this.instance.post(`/v1/cat-safras/`, data);
  }

  editSafra(id, data) {
    return this.instance.put(`/v1/cat-safras/${id}`, data);
  }

  deleteSafra(id) {
    return this.instance.delete(`/v1/cat-safras/${id}`);
  }
}

export default (...params) => new SafraFactory(...params);
