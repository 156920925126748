/* eslint-disable no-useless-escape */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/* eslint-disable no-extend-native */

function formatNumber(value) {
  value = convertToFloatNumber(value);
  return value.formatMoney(2, '.', '');
}

var convertToFloatNumber = function (value) {
  value = value.toString();

  if (value.indexOf('.') !== -1 || value.indexOf(',') !== -1) {
    const a = value.indexOf('.');
    const b = value.indexOf(',');

    if (a > b) {
      const result = parseFloat(value.replace(/\,/gi, '.'));

      return result;
    } else {
      const result = parseFloat(value.replace(/\./gi, '').replace(/\,/gi, '.'));

      return result;
    }
  } else {
    return parseFloat(value);
  }
};

Number.prototype.formatMoney = function (c, d, t) {
  var n = this,
    c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? '.' : d,
    t = t == undefined ? ',' : t,
    s = n < 0 ? '-' : '',
    i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + '',
    j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : '')
  );
};

export default formatNumber;
