import React, { useState, useEffect, useCallback, useContext, createContext } from 'react';
import { useAuth } from 'hooks/auth';
import { useQualidadeCafe } from 'hooks/providers/configuracoes/qualidade';

const SelectQuantidadeContext = createContext({});

export const SelectQuantidadeProvider = ({ children }) => {
  const { isAuth, token } = useAuth();
  const { addQualidade, editQualidade } = useQualidadeCafe();

  const [dados, setDados] = useState({});
  const [options, setOptions] = useState([]);

  const onSubmitInsert = useCallback(
    async (data, onChange) => {
      try {
        const response = await addQualidade(data);

        if (response) {
          setDados(response);
          setOptions(old => setOptions([...old, response]));
          onChange(response);
        }
      } finally {
      }
    },
    [addQualidade]
  );

  const onSubmitUpdate = useCallback(
    async (id, data, onChange) => {
      try {
        const response = await editQualidade(id, data);

        if (response) {
          setOptions(old =>
            old.map(item =>
              Number(item.id) === Number(response.id) ? { ...item, descricao: response.descricao } : item
            )
          );
          setDados(response);
          onChange(response);
        }
      } finally {
      }
    },
    [editQualidade]
  );

  useEffect(() => {
    if (!isAuth && token === 'initial') {
      setDados([]);
    }
  }, [isAuth, token]);

  return (
    <SelectQuantidadeContext.Provider
      value={{
        onSubmitInsert,
        onSubmitUpdate,
        dados,
        setDados,
        options,
        setOptions,
      }}>
      {children}
    </SelectQuantidadeContext.Provider>
  );
};

export function useSelectQuantidade() {
  const context = useContext(SelectQuantidadeContext);

  if (!context) throw new Error('useSelectQuantidade must be used within an AuthProvider');

  return context;
}
