import React from 'react';

import { FluxoProvider } from './fluxos';
import { ReceitaDespesaProvider } from './receitasDespesas';

const FinanceiroProvider = ({ children }) => (
  <FluxoProvider>
    <ReceitaDespesaProvider>{children}</ReceitaDespesaProvider>
  </FluxoProvider>
);

export default FinanceiroProvider;
