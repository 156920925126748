import { useNewToast } from 'hooks/newToast';
import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { toDate } from 'utils/converters';
import { appWarnings } from 'utils/appWarnings';

const ArmazemContext = createContext({});

export const ArmazemProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [lotesArmazem, setLotesArmazem] = useState([]);
  const [lotesArmazemLoading, setLotesArmazemLoading] = useState(false);
  const [loteArmazem, setLoteArmazem] = useState({});
  const [loteArmazemLoading, setLoteArmazemLoading] = useState(false);
  const [armazemLotesHistorico, setArmazemLotesHistorico] = useState([]);
  const [armazemLotesHistoricoTotalItems, setArmazemLotesHistoricoTotalItems] = useState(0);
  const [armazemLotesHistoricoLoading, setArmazemLotesHistoricoLoading] = useState(false);
  const [gerarNoArmazemLoading, setGerarNoArmazemLoading] = useState(false);
  const [editNoArmazemLoading, setEditNoArmazemLoading] = useState(false);
  const [deleteArmazemLoading, setDeleteArmazemLoading] = useState(false);

  const getLotesArmazem = useCallback(async filter => {
    try {
      setLotesArmazemLoading(true);
      const response = await api.armazem().getLotesArmazem(filter);
      setLotesArmazem(response);
      setLotesArmazemLoading(false);
    } catch (errors) {
      setLotesArmazemLoading(false);
    }
  }, []);

  const getLoteArmazem = useCallback(async id => {
    try {
      setLoteArmazemLoading(true);
      const response = await api.armazem().getLoteArmazem(id);
      setLoteArmazem(response);
      setLoteArmazemLoading(false);
    } catch (errors) {
      setLoteArmazemLoading(false);
    }
  }, []);

  const getLotesArmazemHistorico = useCallback(async id => {
    try {
      setArmazemLotesHistoricoLoading(true);
      const response = await api.armazem().getLotesArmazemHistorico(id);
      setArmazemLotesHistorico(response.content);
      setArmazemLotesHistoricoTotalItems(response?.pagination?.total || 0);
      setArmazemLotesHistoricoLoading(false);
    } catch (errors) {
      setArmazemLotesHistoricoLoading(false);
    }
  }, []);

  const gerarNoArmazem = useCallback(
    async dados => {
      try {
        setGerarNoArmazemLoading(true);
        const { pilha, data_entrada, data_saida, responsavel, umidade_entrada, umidade_saida } = dados.dados;
        const newData = {
          pilha: pilha.value,
          lote: dados.lotesArmazem.map(item => {
            const splitId = item.lote.codigo.split('-');
            const codigoCafe = `${splitId[0]}-${splitId[1]}`;

            return {
              id: item.lote.id,
              codigo: codigoCafe,
            };
          }),

          dataEntrada: toDate(data_entrada),
          dataSaida: data_saida && data_saida !== 'Data inválida' ? toDate(data_saida) : null,
          umidadeEntrada: umidade_entrada,
          umidadeSaida: umidade_saida,
          responsavel: responsavel,
        };
        const response = await api.armazem().gerarNoArmazem(newData);
        setGerarNoArmazemLoading(false);
        showToast({
          type: 'success',
          message: 'Entrada no armazém gerada com sucesso!',
        });
        return response;
      } catch (err) {
        setGerarNoArmazemLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast]
  );

  const editNoArmazem = useCallback(
    async (id, dados) => {
      try {
        setEditNoArmazemLoading(true);
        const { data_entrada, data_saida, responsavel, umidade_entrada, umidade_saida, pilha } = dados.dados;

        const newData = {
          pilha: pilha.value,
          dataEntrada: toDate(data_entrada),
          dataSaida: data_saida && data_saida !== 'Data inválida' ? toDate(data_saida) : null,
          umidadeEntrada: umidade_entrada,
          umidadeSaida: umidade_saida === '' ? null : umidade_saida,
          responsavel: responsavel,
        };
        const response = await api.armazem().editLoteArmazem(id, newData);
        setEditNoArmazemLoading(false);
        showToast({
          type: 'success',
          message: 'Entrada no armazém editada com sucesso!',
        });
        return response;
      } catch (err) {
        setEditNoArmazemLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [showToast]
  );

  const deleteArmazem = useCallback(
    async codigo => {
      try {
        setDeleteArmazemLoading(true);
        await api.armazem().deleteLoteArmazem(codigo);
        setLotesArmazem(lotesArmazem.filter(item => item.id !== codigo));
        setDeleteArmazemLoading(false);
        getLotesArmazem();
        showToast({
          type: 'success',
          message: 'Processo de armazém removido com sucesso!',
        });
      } catch (err) {
        setDeleteArmazemLoading(false);
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      }
    },
    [lotesArmazem, showToast, getLotesArmazem]
  );

  return (
    <ArmazemContext.Provider
      value={{
        gerarNoArmazemLoading,
        gerarNoArmazem,
        editNoArmazemLoading,
        editNoArmazem,
        deleteArmazemLoading,
        deleteArmazem,
        lotesArmazemLoading,
        lotesArmazem,
        loteArmazem,
        loteArmazemLoading,
        getLoteArmazem,
        getLotesArmazem,
        armazemLotesHistorico,
        armazemLotesHistoricoLoading,
        getLotesArmazemHistorico,
        armazemLotesHistoricoTotalItems,
      }}>
      {children}
    </ArmazemContext.Provider>
  );
};

export function useArmazem() {
  const context = useContext(ArmazemContext);

  if (!context) throw new Error('useArmazem must be used within an AuthProvider');

  return context;
}
