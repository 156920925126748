export class ContrarosParceriaFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getContratosParcerias(filter) {
    return this.instance.get('/v1/cad-meeiro-contratos/', {
      params: filter || null,
    });
  }

  getContratoParceria(id, filter) {
    return this.instance.get(`/v1/cad-meeiro-contratos/${id}`, {
      params: filter || null,
    });
  }

  insertContratoParceria(data) {
    return this.instance.post('/v1/cad-meeiro-contratos', data);
  }

  updateContratoParceria(id, data) {
    return this.instance.put(`/v1/cad-meeiro-contratos/${id}`, data);
  }

  removeContratoParceria(id) {
    return this.instance.delete(`/v1/cad-meeiro-contratos/${id}`);
  }

  getAllContratoParceria(filters) {
    return this.instance.get(`/v1/cad-meeiros/`, {
      params: filters || null,
    });
  }
}

export default (...params) => new ContrarosParceriaFactory(...params);
