export class AbastecimentoFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getAbastecimentos(id, filter) {
    return this.instance.get(`/v1/cad-maquinas/${id}/abastecimento`, {
      params: filter || null,
    });
  }

  getAbastecimento(id, idAbastecimento, filter) {
    return this.instance.get(`/v1/cad-maquinas/${id}/abastecimento/${idAbastecimento}`, {
      params: filter || null,
    });
  }

  addAbastecimento(id, data) {
    return this.instance.post(`/v1/cad-maquinas/${id}/abastecimento`, data);
  }

  editAbastecimento(id, idAbastecimento, data) {
    return this.instance.put(
      `/v1/cad-maquinas/${id}/abastecimento/${idAbastecimento}`,
      data
    );
  }

  deleteAbastecimento(id, idAbastecimento) {
    return this.instance.delete(
      `/v1/cad-maquinas/${id}/abastecimento/${idAbastecimento}`
    );
  }
}

export default (...params) => new AbastecimentoFactory(...params);
