/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, createContext } from 'react';
import { toDate } from 'utils/converters';

import api from 'services';
import { useNewToast } from 'hooks/newToast';
import { appWarnings } from 'utils/appWarnings';
import moment from 'moment';

const LotesContext = createContext({});

export const LotesProvider = ({ children }) => {
  const { showToast } = useNewToast();

  const [escolherLotes, setEscolherLotes] = React.useState([]);
  const [lotesBeneficio, setLotesBeneficio] = React.useState([]);

  const [escolherLotesLoading, setEscolherLotesLoading] = React.useState(false);

  const [rastreioLote, setRastreioLote] = React.useState([]);
  const [rastreioLoteLoading, setRastreioLoteLoading] = React.useState([]);

  const [converterTalhaoColheitaLoading, setConverterTalhaoColheitaLoading] = React.useState(false);

  const [lotesPorTalhao, setLotesPorTalhao] = React.useState([]);
  const [lotesPorTalhaoLoading, setLotesPorTalhaoLoading] = React.useState(false);

  const [pesquisaLotes, setPesquisaLotes] = React.useState([]);
  const [pesquisaLotesLoading, setPesquisaLotesLoading] = React.useState([]);

  const getEscolherLotes = useCallback(async (filter, secagem) => {
    try {
      setEscolherLotesLoading(true);
      const response = await api.lotes().getEscolherLotes(filter);

      if (filter?.tipo === 'beneficio') {
        setLotesBeneficio(response?.map(item => ({ ...item, idLote: item.id })));
      }

      setEscolherLotes(response?.map(item => ({ ...item, idLote: item.id })));
    } catch (errors) {
    } finally {
      setEscolherLotesLoading(false);
    }
  }, []);

  const getPesquisaLotes = useCallback(async filter => {
    try {
      const data = {
        ...filter,
        data: filter.data && filter.data !== 'Data inválida' ? toDate(filter.data) : null,
        tipo: filter?.tipo?.id,
      };

      setPesquisaLotesLoading(true);
      const response = await api.lotes().getPesquisaLotes(data);
      setPesquisaLotesLoading(false);
      setPesquisaLotes(response);
      setPesquisaLotesLoading(false);
    } catch (errors) {
      setPesquisaLotesLoading(false);
    }
  }, []);

  const converterTalhaoColheita = useCallback(async data => {
    try {
      setConverterTalhaoColheitaLoading(true);
      const newData = {
        data: moment(data.data).format('YYYY-MM-DD'),
        talhoes: [
          {
            talhaoId: data?.talhao?.glo_talhao_id,
            quantidade: data?.qtd_litros,
            valor: data?.valor_medida,
            safra: data?.safra?.id,
          },
        ],
      };
      await api.lotes().converterTalhaoColheita(newData);
      setConverterTalhaoColheitaLoading(false);
      showToast({
        type: 'success',
        message: 'Histórico adicionado com sucesso',
      });
    } catch (error) {
      setConverterTalhaoColheitaLoading(false);
      showToast(appWarnings.Danger(error));
      throw new Error();
    }
  }, []);

  const getRastrearLote = useCallback(async filters => {
    try {
      const tempFilters = {
        id: filters?.id,
        lote: filters?.lote,
        safra: filters?.safra,
      };
      setRastreioLoteLoading(true);

      const response = await api.lotes().getRastrearLote(tempFilters);

      setRastreioLote(response);
      setRastreioLoteLoading(false);
    } catch (error) {
      setRastreioLoteLoading(false);
    }
  }, []);

  const [printBeneficio, setPrintBeneficio] = React.useState({});
  const [printBeneficioLoading, setPrintBeneficioLoading] = React.useState({});

  const getPrintBeneficio = useCallback(async filters => {
    try {
      const tempFilters = {
        beneficio: filters.id,
        lote: filters.lote,
        safra: filters?.safra || 0,
      };
      setPrintBeneficioLoading(true);
      const response = await api.lotes().getPrintBeneficio(tempFilters);
      setPrintBeneficio(response);
      setPrintBeneficioLoading(false);
    } catch (error) {
      setPrintBeneficioLoading(false);
    }
  }, []);

  const listLotesPorTalhao = useCallback(async filters => {
    try {
      setLotesPorTalhaoLoading(true);
      const response = await api.lotes().listLotesPorTalhao(filters);
      setLotesPorTalhao(response);
      setLotesPorTalhaoLoading(false);
    } finally {
      setLotesPorTalhaoLoading(false);
    }
  }, []);

  return (
    <LotesContext.Provider
      value={{
        converterTalhaoColheita,
        converterTalhaoColheitaLoading,
        getEscolherLotes,
        getPesquisaLotes,
        setEscolherLotes,
        pesquisaLotesLoading,
        pesquisaLotes,
        escolherLotes,
        escolherLotesLoading,
        getRastrearLote,
        rastreioLote,
        rastreioLoteLoading,
        getPrintBeneficio,
        printBeneficio,
        printBeneficioLoading,
        listLotesPorTalhao,
        lotesPorTalhao,
        lotesPorTalhaoLoading,
        lotesBeneficio,
      }}>
      {children}
    </LotesContext.Provider>
  );
};

export function useLotes() {
  const context = useContext(LotesContext);

  if (!context) throw new Error('useLotes must be used within an AuthProvider');

  return context;
}
