export class ColaboradorFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getColaboradores(filter) {
    return this.instance.get('/v1/cad-colaboradores', {
      params: filter,
    });
  }

  listColaboradoresFolhaPagamento(filter) {
    return this.instance.get('/v1/cad-colaboradores/folha-pagamento', {
      params: filter,
    });
  }

  addColaborador(data) {
    return this.instance.post(`/v1/cad-colaboradores/`, data);
  }

  editColaborador(data, id) {
    return this.instance.put(`/v1/cad-colaboradores/${id}`, data);
  }

  deleteColaborador(id) {
    return this.instance.delete(`/v1/cad-colaboradores/${id}`);
  }

  getHistoricoColaborador(id, filter) {
    return this.instance.get(`/v1/cad-colaboradores/${id}/historico`, {
      params: filter,
    });
  }

  getAdiantamentos(filter) {
    return this.instance.get('/v1/cad-adiantamentos', {
      params: filter,
    });
  }

  addAdiantamento(data) {
    return this.instance.post(`/v1/cad-adiantamentos`, data);
  }

  editAdiantamento(data, idAdiantamento) {
    return this.instance.put(`/v1/cad-adiantamentos/${idAdiantamento}`, data);
  }

  deleteAdiantamento(id, filter) {
    return this.instance.delete(`/v1/cad-adiantamentos/${id}`, filter);
  }

  getSaldoMeeiro(id, filter) {
    return this.instance.get(`/v1/cad-colaboradores/${id}/saldo-meeiro`, {
      params: filter,
    });
  }

  getMeeiroHistoricoEntradas(id, filter) {
    return this.instance.get(`/v1/cad-colaboradores/${id}/historico-entradas`, {
      params: filter,
    });
  }

  getMeeiroHistoricoSaidas(id, filter) {
    return this.instance.get(`/v1/cad-colaboradores/${id}/historico-saidas`, {
      params: filter,
    });
  }

  geTalhoesByMeeiro(id, filter) {
    return this.instance.get(`/v1/cad-colaboradores/${id}/parcerias`, {
      params: filter,
    });
  }

  addDescontosAdiantamento(data) {
    return this.instance.post(`/v1/pagamento-apontamento/`, data);
  }
}

export default (...params) => new ColaboradorFactory(...params);
