import React from 'react';

import { RelInsumoProvider } from './relInsumo';
import { RelTalhaoProvider } from './relTalhoes';
import { RelatoriosSafrasProvider } from './relatoriosSafras';

const RelatoriosProvider = ({ children }) => (
  <RelTalhaoProvider>
    <RelInsumoProvider>
      <RelatoriosSafrasProvider> {children}</RelatoriosSafrasProvider>
    </RelInsumoProvider>
  </RelTalhaoProvider>
);

export default RelatoriosProvider;
