export class ClientesFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getClientes(filter) {
    return this.instance.get('/v1/cad-clientes/', {
      params: filter || null,
    });
  }

  idCliente(id) {
    return this.instance.get(`/v1/cad-clientes/${id}`);
  }

  addCliente(data) {
    return this.instance.post(`/v1/cad-clientes/`, data);
  }

  editCliente(id, data) {
    return this.instance.put(`/v1/cad-clientes/${id}`, data);
  }

  deleteCliente(id) {
    return this.instance.delete(`/v1/cad-clientes/${id}`);
  }
}

export default (...params) => new ClientesFactory(...params);
