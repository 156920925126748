export class AgronomistFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getAgronomists(filter) {
    return this.instance.get(`/v1/agronomist`, {
      params: filter || null,
    });
  }

  getAgronomist(id) {
    return this.instance.get(`/v1/agronomist/${id}`);
  }

  insertAgronomist(data) {
    return this.instance.post(`/v1/agronomist/`, data);
  }

  updateAgronomist(id, data) {
    return this.instance.put(`/v1/agronomist/${id}`, data);
  }

  deleteAgronomist(id) {
    return this.instance.delete(`/v1/agronomist/${id}`);
  }
}

export default (...params) => new AgronomistFactory(...params);
