export class OrdemServicosFactory {
  constructor(instance) {
    this.instance = instance;
  }

  listOrdemServicos(filter) {
    delete filter.intervalo;
    delete filter.initial;

    return this.instance.get('/v1/ordem-servicos/', {
      params: filter || null,
    });
  }

  showOrdemServico(id) {
    return this.instance.get(`/v1/ordem-servicos/${id}`);
  }

  createOrdemServico(data) {
    return this.instance.post(`/v1/ordem-servicos/`, data);
  }

  updateOrdemServico(id, data) {
    return this.instance.put(`/v1/ordem-servicos/${id}`, data);
  }

  removeOrdemServico(id) {
    return this.instance.delete(`/v1/ordem-servicos/${id}`);
  }

  listOrdemServicosItens(id, filter) {
    delete filter.intervalo;
    delete filter.initial;

    return this.instance.get(`/v1/ordem-servicos/${id}/ordem-servicos-itens`, {
      params: filter || null,
    });
  }

  createOrdemServicoItens(ordemServicoId, data) {
    return this.instance.post(`/v1/ordem-servicos/${ordemServicoId}/ordem-servicos-itens`, data);
  }

  updateOrdemServicoItens(ordemServicoId, id, data) {
    return this.instance.put(`/v1/ordem-servicos/${ordemServicoId}/ordem-servicos-itens/${id}`, data);
  }

  finalizarOrdemServico(ordemServicoId, data) {
    return this.instance.put(`/v1/ordem-servicos/${ordemServicoId}/finalizar`, data);
  }

  abrirOrdemServico(ordemServicoId) {
    return this.instance.patch(`/v1/ordem-servicos/${ordemServicoId}/abrir`);
  }

  removeOrdemServicoItem(ordemServicoId, id) {
    return this.instance.delete(`/v1/ordem-servicos/${ordemServicoId}/ordem-servicos-itens/${id}`);
  }
}

export default (...params) => new OrdemServicosFactory(...params);
