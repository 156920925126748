export class InventarioFactory {
  constructor(instance) {
    this.instance = instance;
  }

  listInventarios(filter) {
    return this.instance.get('/v1/cad-inventario', {
      params: filter || null,
    });
  }

  getInventario(id) {
    return this.instance.get(`/v1/cad-inventario/${id}`);
  }

  insertInventario(data) {
    return this.instance.post('/v1/cad-inventario', data);
  }

  updateInventario(id, data) {
    return this.instance.put(`/v1/cad-inventario/${id}`, data);
  }

  removeInventario(id) {
    return this.instance.delete(`/v1/cad-inventario/${id}`);
  }

  listCatInventarios(filter) {
    return this.instance.get('/v1/cat-classes-inventario', {
      params: filter || null,
    });
  }

  showCatInventarios(id) {
    return this.instance.get(`/v1/cat-classes-inventario/${id}`);
  }

  insertCatInventarios(data) {
    return this.instance.post('/v1/cat-classes-inventario/', data);
  }

  updateCatInventarios(id, data) {
    return this.instance.put(`/v1/cat-classes-inventario/${id}`, data);
  }

  removeCatInventarios(id) {
    return this.instance.delete(`/v1/cat-classes-inventario/${id}`);
  }

  getInventarioRelatorio() {
    return this.instance.get(`/v1/cad-inventario/inventario/relatorio/`);
  }
}

export default (...params) => new InventarioFactory(...params);
