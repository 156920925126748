export class CadSecadorFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getSecadores(filter) {
    return this.instance.get('/v1/cad-secadores/', {
      params: filter || null,
    });
  }

  getSecador(filter, id) {
    return this.instance.get(`/v1/cad-secadores/${id}`, {
      params: filter || null,
    });
  }

  addSecador(data) {
    return this.instance.post(`/v1/cad-secadores/`, data);
  }

  editSecador(id, data) {
    return this.instance.put(`/v1/cad-secadores/${id}`, data);
  }

  deleteSecador(id) {
    return this.instance.delete(`/v1/cad-secadores/${id}`);
  }
}

export default (...params) => new CadSecadorFactory(...params);
