export class FinanceiroFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getReceitasDespesas(filter) {
    if (filter?.intervalo) {
      delete filter.intervalo;
    }

    return this.instance.get('v1/financeiro', {
      params: filter || null,
    });
  }

  getReceitaDespesa(id, filter) {
    return this.instance.get(`v1/financeiro/${id}`, {
      params: filter || null,
    });
  }

  getResumo(filter) {
    return this.instance.post(
      'v1/financeiro/resumo',
      {},
      {
        params: filter || null,
      },
    );
  }

  getResumoTresMeses(filter) {
    return this.instance.post(
      'v1/financeiro/resumo-tres-meses',
      {},
      {
        params: filter || null,
      },
    );
  }

  getPagamentoItem(id, filter) {
    return this.instance.get(`v1/financeiro/pagamento/${id}`, {
      params: filter || null,
    });
  }

  insertReceitaDespesa(data) {
    return this.instance.post(`v1/financeiro`, data);
  }

  editReceitaDespesa(id, data) {
    return this.instance.put(`v1/financeiro/${id}`, data);
  }

  deleteReceitaDespesa(id) {
    return this.instance.delete(`v1/financeiro/${id}`);
  }

  addPagamento(id) {
    return this.instance.post(`v1/financeiro/pagamento`, id);
  }

  updatePagamento(id, data) {
    return this.instance.put(`v1/financeiro/pagamento/${id}`, data);
  }

  changeStatus(id, data) {
    return this.instance.patch(`v1/financeiro/pagamento/${id}`, data);
  }

  deletePagamento(id) {
    return this.instance.delete(`v1/financeiro/pagamento/${id}`);
  }

  // getReceitaDespesa(id, filter) {
  //   return this.instance.get(`v1/financeiro/${id}`, {
  //     params: filter || null,
  //   });
  // }

  downloadCsvFinancial(filters) {
    return this.instance.patch(`/v1/financeiro`, filters, {
      responseType: 'blob',
    });
  }
}

export default (...params) => new FinanceiroFactory(...params);
