export class dadosCadastroFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getDadosBancarios(filter) {
    return this.instance.get('/v1/cad-dadosbancarios', {
      params: filter || null,
    });
  }

  inserirDadosBancarios(data) {
    return this.instance.post('/v1/cad-dadosbancarios/', data);
  }

  editarDadosBancarios(id, data) {
    return this.instance.put(`/v1/cad-dadosbancarios/${id}`, data);
  }

  excluirDadosBancarios(id) {
    return this.instance.delete(`/v1/cad-dadosbancarios/${id}`);
  }

  getEnderecos(filter) {
    return this.instance.get('/v1/cad-enderecos/', {
      params: filter || null,
    });
  }

  getEndereco(id, filter) {
    return this.instance.get(`/v1/cad-enderecos/${id}`, {
      params: filter || null,
    });
  }

  inserirEndereco(data) {
    return this.instance.post('/v1/cad-enderecos/', data);
  }

  editarEndereco(data, idReferencia, id) {
    return this.instance.put(`/v1/cad-enderecos/${idReferencia}`, data, id);
  }

  excluirEndereco(id) {
    return this.instance.delete(`/v1/cad-enderecos/${id}`);
  }

  getContatos(filter) {
    return this.instance.get('/v1/cad-contatos/', {
      params: filter || null,
    });
  }

  inserirContato(data) {
    return this.instance.post('/v1/cad-contatos', data);
  }

  editarContato(data, id) {
    return this.instance.put(`/v1/cad-contatos/${id}`, data);
  }

  excluirContato(id) {
    return this.instance.delete(`/v1/cad-contatos/${id}`);
  }
}

export default (...params) => new dadosCadastroFactory(...params);
