export class PermissionsFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getLisPermissions(filter) {
    return this.instance.get('/permissions', {
      params: filter || null,
    });
  }

  getLisPermissionsUser(id, filter) {
    return this.instance.get(`/users/${id}/permissions`, {
      params: filter || null,
    });
  }

  getLisPermissionsByModules(id, filter) {
    return this.instance.get(`/users/${id}/permissions-modules`, {
      params: filter || null,
    });
  }

  insertPermission(id, data) {
    return this.instance.post(`/users/${id}/permissions`, data);
  }

  updatePermission(id, data) {
    return this.instance.put(`/permissions/${id}`, data);
  }

  removePermission(id, data) {
    return this.instance.delete(`/permissions/${id}`, data);
  }
}

export default (...params) => new PermissionsFactory(...params);
