export class ColheitaFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getMecanicaDashboard(filter) {
    return this.instance.get('/v1/colheita/mecanica/dashboard', {
      params: filter || null,
    });
  }

  getCostsMecanicaByPlot(filter) {
    return this.instance.get('/v1/colheita/mecanica/plots', {
      params: filter || null,
    });
  }

  getCostsByMachice(filter) {
    return this.instance.get('/v1/colheita/mecanica/costs-by-machines', {
      params: filter || null,
    });
  }
}

export default (...params) => new ColheitaFactory(...params);
