import React, { useState, useCallback, useContext, createContext } from 'react';
import api from 'services';
import { useRegInsumo } from './registroInsumos';
import { appWarnings } from 'utils/appWarnings';
import { useNewToast } from 'hooks/newToast';

const RegCaldaContext = createContext({});

const success = {
  type: 'success',
};

export const RegCaldaProvider = ({ children }) => {
  const { showToast } = useNewToast();
  const { getRegInsumos } = useRegInsumo();

  const [regCaldas, setRegCaldas] = useState([]);
  const [regCaldasLoading, setRegCaldasLoading] = useState(false);
  const [regCalda, setRegCalda] = useState({});
  const [regCaldaLoading, setRegCaldaLoading] = useState(false);
  const [isRemoveRegCalda, setIsRemoveRegCalda] = useState(false);

  const getRegCaldas = useCallback(async (activityId) => {
    try {
      setRegCaldasLoading(true);
      const response = await api.regAtividade().getRegCaldas(activityId);
      setRegCaldas(response);
    } catch (errors) {
    } finally {
      setRegCaldasLoading(false);
    }
  }, []);

  const getRegCalda = useCallback(async (registroId, id) => {
    try {
      setRegCaldaLoading(true);
      const response = await api.regAtividade().getRegCalda(registroId, id);
      setRegCalda(response);
      setRegCaldaLoading(false);
    } catch (errors) {
      setRegCaldaLoading(false);
    }
  }, []);

  const insertRegCaldas = useCallback(
    async (registroId, data) => {
      try {
        setRegCaldaLoading(true);
        const formattedData = {
          caldas: data?.map((item) => ({
            id: item?.sprayMixtureId,
            calda: item?.calda?.id,
            quantidade: item?.quantidade,
          })),
        };
        await api.regAtividade().insertRegCalda(registroId, formattedData);
        getRegCaldas(registroId);
        getRegInsumos(registroId);
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setRegCaldaLoading(false);
      }
    },
    [showToast, getRegCaldas, getRegInsumos],
  );

  const removeRegCalda = useCallback(
    async (activityId, sprayMixtureId) => {
      try {
        setIsRemoveRegCalda(true);
        await api.regAtividade().removeRegCalda(activityId, sprayMixtureId);
        showToast({
          ...success,
          message: 'Custo de calda excluído com sucesso!',
        });
      } catch (err) {
        showToast(appWarnings.Danger(err));
        throw new Error(err);
      } finally {
        setIsRemoveRegCalda(false);
      }
    },
    [showToast],
  );

  return (
    <RegCaldaContext.Provider
      value={{
        getRegCaldas,
        regCaldas,
        regCaldasLoading,
        regCalda,
        regCaldaLoading,
        isRemoveRegCalda,
        getRegCalda,
        removeRegCalda,
        insertRegCaldas,
        setRegCaldas,
        setRegCaldaLoading,
      }}
    >
      {children}
    </RegCaldaContext.Provider>
  );
};

export function useRegCalda() {
  const context = useContext(RegCaldaContext);

  if (!context)
    throw new Error('useRegAtividade must be used within an RegAtividades');

  return context;
}
