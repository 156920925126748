export class RelInsumosFactory {
  constructor(instance) {
    this.instance = instance;
  }

  relInsumos(filter) {
    return this.instance.get('/v1/relatorios/mensal/insumos', {
      params: filter || null,
    });
  }

  getInsumosPorTalhao(filter) {
    return this.instance.get('/v1/relatorios/mensal/insumos-talhao', {
      params: filter || null,
    });
  }

  relMdo(filter) {
    return this.instance.get('/v1/relatorios/mensal/mdo', {
      params: filter || null,
    });
  }

  relMdoColheita(filter) {
    return this.instance.get('/v1/relatorios/mensal/mdocolheita', {
      params: filter || null,
    });
  }

  relMaquinas(filter) {
    return this.instance.get('/v1/relatorios/mensal/maquinas', {
      params: filter || null,
    });
  }

  relAtividades(filter) {
    return this.instance.get('/v1/relatorios/mensal/atividades', {
      params: filter || null,
    });
  }

  relDespesas(filter) {
    return this.instance.get('/v1/relatorios/mensal/despesas', {
      params: filter || null,
    });
  }

  relCustos(filter) {
    return this.instance.get('/v1/relatorios/mensal/custos', {
      params: filter || null,
    });
  }
}

export default (...params) => new RelInsumosFactory(...params);
