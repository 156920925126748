export class LotesFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getEscolherLotes(filter) {
    return this.instance.get(`/v1/rastreabilidade/lotes`, {
      params: filter || null,
    });
  }

  getPesquisaLotes(filter) {
    return this.instance.get(`/v1/rastreabilidade/lotes/pesquisar`, {
      params: filter || null,
    });
  }

  converterTalhaoColheita(data) {
    return this.instance.post('/v1/rastreabilidade', data);
  }

  getRastrearLote(filter) {
    return this.instance.get(`/v1/rastreabilidade`, {
      params: filter || null,
    });
  }

  getPrintBeneficio(filter) {
    return this.instance.get(`/v1/rastreabilidade/print`, {
      params: filter || null,
    });
  }

  getPrintRebeneficio(filter) {
    return this.instance.get(`/v1/rastreabilidade/print-rebeneficio`, {
      params: filter || null,
    });
  }

  listLotesPorTalhao(filter) {
    return this.instance.get(`/v1/rastreabilidade/lotes/por-talhao`, {
      params: filter || null,
    });
  }
}

export default (...params) => new LotesFactory(...params);
