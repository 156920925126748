export class TerreiroFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getTerreirosLotes(filter) {
    return this.instance.get('v1/rastreabilidade/terreiro', {
      params: filter || null,
    });
  }

  getLoteNoTerreiro(idLote) {
    return this.instance.get(`v1/rastreabilidade/terreiro/lote/${idLote}`);
  }

  getTerreiroHistorico(id, filter) {
    return this.instance.get(`v1/rastreabilidade/terreiro/historico/${id}`, {
      params: filter || null,
    });
  }

  gerarNoTerreiro(data) {
    return this.instance.post(`v1/rastreabilidade/terreiro`, data);
  }

  editarNoTerreiro(id, data) {
    return this.instance.put(`v1/rastreabilidade/terreiro/${id}`, data);
  }

  deleteNoTerreiro(id) {
    return this.instance.delete(`v1/rastreabilidade/terreiro/${id}`);
  }

  listHistoricoTerreiros(filter) {
    return this.instance.get(`v1/rastreabilidade/terreiro/historico/`, {
      params: filter || null,
    });
  }
}

export default (...params) => new TerreiroFactory(...params);
