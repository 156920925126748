export class SecadorFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getLotesSecador(filter) {
    return this.instance.get('v1/rastreabilidade/secagem', {
      params: filter || null,
    });
  }

  getSecagem(id) {
    return this.instance.get(`v1/rastreabilidade/secagem/${id}`);
  }

  getRessecagem(id) {
    return this.instance.get(`v1/rastreabilidade/ressecagem/${id}`);
  }

  getLotesSecadorHistorico(filter) {
    return this.instance.get(`v1/rastreabilidade/secagem/secador/historico`, {
      params: filter || null,
    });
  }

  gerarNoSecador(data) {
    return this.instance.post(`v1/rastreabilidade/secagem`, data);
  }

  editarNoSecador(id, data) {
    return this.instance.put(`v1/rastreabilidade/secagem/${id}`, data);
  }

  updateRessecagem(id, data) {
    return this.instance.put(`v1/rastreabilidade/ressecagem/${id}`, data);
  }

  deleteNoSecador(id) {
    return this.instance.delete(`v1/rastreabilidade/secagem/${id}`);
  }

  deleteRessecagem(id) {
    return this.instance.delete(`v1/rastreabilidade/ressecagem/${id}`);
  }
}

export default (...params) => new SecadorFactory(...params);
