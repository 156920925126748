const appWarnings = {
  Success: {
    type: 'success',
    title: 'Dados atualizados',
    description: '',
  },
  SuccessAdd: {
    type: 'success',
    title: 'Cadastro concluido com sucesso',
    description: '',
  },
  SuccessEdit: {
    type: 'success',
    title: 'Edição concluida com sucesso',
    description: '',
  },
  Error: {
    type: 'error',
    title: 'Erro ao buscar na API',
    description: '',
    message: '',
  },
  ErrorMessage: message => ({
    type: 'error',
    title: 'Erro ao buscar na API',
    description: message,
  }),
  Danger: error => ({
    type: 'error',
    title: 'Ops!',
    description: error?.response?.data.error || 'Ocorreu um erro',
    message: error?.response?.data.error || 'Ocorreu um erro',
  }),
  Danger2: error => {
    return {
      type: 'error',
      title: 'Ops!',
      autoClose: false,
      description: error?.response?.data.error || '',
      message: error?.response?.data.error || error?.response?.data.message || '',
    };
  },
  authError: error => {
    return {
      type: 'error',
      title: 'Ops!',
      autoClose: false,
      description: error?.response?.data.error || '',
      message:
        error?.response?.data.error ||
        error?.response?.data.message ||
        'Erro inesperado, comunique com o administrador',
    };
  },
};

const regAtividadesWarnings = {
  insertSuccess: msg => ({
    type: 'success',
    title: 'Sucesso!',
    description: `${msg} adicionado com sucesso` || '',
  }),
  updateSuccess: msg => ({
    type: 'success',
    title: 'Sucesso!',
    description: `${msg} editado com sucesso` || '',
  }),
  removeSuccess: {
    type: 'success',
    title: 'Sucesso',
    description: 'Removido com sucesso!',
  },
  removeError: {
    type: 'error',
    title: 'Ops',
    description: 'Erro ao tentar excluir!',
  },
  insertError: {
    type: 'error',
    title: 'Erro',
    description: 'Preencha todos os campos!',
  },
  Danger: error => ({
    type: 'error',
    title: 'Ops!',
    description: error?.response?.data.error || '',
  }),
};

export { appWarnings, regAtividadesWarnings };
