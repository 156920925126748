export class AtividadeFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getRegAtividades(filter) {
    return this.instance.get('/v1/reg-atividades', {
      params: filter || null,
    });
  }

  getRegAtividadesFiltered(filter) {
    return this.instance.get('/v1/reg-atividades/by-filters', {
      params: filter || null,
    });
  }

  modifyRegAtividadesFiltered(data) {
    return this.instance.put('/v1/reg-atividades/by-filters', data);
  }

  getRegAtividade(id) {
    return this.instance.get(`/v1/reg-atividades/${id}`);
  }

  getRegAtividadeByTalhao(filter) {
    return this.instance.get(`/v1/reg-atividades/por-talhao`, {
      params: filter || null,
    });
  }

  getRegInsumos(registroId) {
    return this.instance.get(`/v1/reg-atividades/${registroId}/insumos/`);
  }

  getRegInsumo(registroId, id) {
    return this.instance.get(`/v1/reg-atividades/${registroId}/insumos/${id}`);
  }

  getRegMdobras(registroId) {
    return this.instance.get(`/v1/reg-atividades/${registroId}/mdobras/`);
  }

  getRegMdobra(registroId, id) {
    return this.instance.get(`/v1/reg-atividades/${registroId}/mdobras/${id}`);
  }

  getRegMdobrasColheita(registroId) {
    return this.instance.get(
      `/v1/reg-atividades/${registroId}/mdobrascolheita/`,
      {
        params: {
          modo: 'manual',
        },
      },
    );
  }

  getRegMdobrasColheitaMecanica(registroId) {
    return this.instance.get(
      `/v1/reg-atividades/${registroId}/mdobrascolheita/`,
      {
        params: {
          modo: 'mecanica',
        },
      },
    );
  }

  getRegMdobraColheita(registroId, id) {
    return this.instance.get(
      `/v1/reg-atividades/${registroId}/mdobrascolheita/${id}`,
    );
  }

  getRegMaquinas(registroId) {
    return this.instance.get(`/v1/reg-atividades/${registroId}/maquinas/`);
  }

  getRegMaquina(registroId, id) {
    return this.instance.get(`/v1/reg-atividades/${registroId}/maquinas/${id}`);
  }

  getRegExtras(registroId) {
    return this.instance.get(`/v1/reg-atividades/${registroId}/extras/`);
  }

  getRegExtra(registroId, id) {
    return this.instance.get(`/v1/reg-atividades/${registroId}/extras/${id}`);
  }

  getRegCaldas(registroId) {
    return this.instance.get(`/v1/reg-atividades/${registroId}/caldas/`);
  }

  getRegCalda(registroId, id) {
    return this.instance.get(`/v1/reg-atividades/${registroId}/caldas/${id}`);
  }

  insertRegAtividade(data) {
    return this.instance.post(`/v1/reg-atividades/`, data);
  }

  insertDuplicateAtividade(data) {
    return this.instance.put(`/v1/reg-atividades/duplicate`, data);
  }

  insertRegMdobra(registroId, data) {
    return this.instance.post(`/v1/reg-atividades/${registroId}/mdobras`, data);
  }

  insertRegColheita(registroId, data) {
    return this.instance.post(
      `/v1/reg-atividades/${registroId}/mdobrascolheita`,
      data,
    );
  }

  insertRegColheitaMecanica(registroId, data) {
    return this.instance.post(
      `/v1/reg-atividades/${registroId}/mdobrascolheita`,
      data,
      {
        params: {
          modo: 'mecanica',
        },
      },
    );
  }

  insertRegInsumos(registroId, data) {
    return this.instance.post(`/v1/reg-atividades/${registroId}/insumos`, data);
  }

  insertRegCalda(registroId, data) {
    return this.instance.post(`/v1/reg-atividades/${registroId}/caldas`, data);
  }

  insertRegMaquina(registroId, data) {
    return this.instance.post(
      `/v1/reg-atividades/${registroId}/maquinas`,
      data,
    );
  }

  insertRegExtra(registroId, data) {
    return this.instance.post(`/v1/reg-atividades/${registroId}/extras`, data);
  }

  removeRegistro(registro) {
    return this.instance.delete(`v1/reg-atividades/${registro}`);
  }

  removeRegInsumo(activityId, inputId) {
    return this.instance.delete(
      `v1/reg-atividades/${activityId}/insumos/${inputId}`,
    );
  }

  removeRegMdobra(registro, id) {
    const queryId = id ? `mdobras/${id}` : 'mdobras';
    return this.instance.delete(`v1/reg-atividades/${registro}/${queryId}`);
  }

  removeRegMdobrasColheita(activityId, id) {
    const queryId = id ? `mdobrascolheita/${id}` : 'mdobrascolheita';
    return this.instance.delete(`v1/reg-atividades/${activityId}/${queryId}`);
  }

  removeRegMaquina(activityId, machineId) {
    return this.instance.delete(
      `v1/reg-atividades/${activityId}/maquinas/${machineId}`,
    );
  }

  removeRegCalda(activityId, sprayMixtureId) {
    return this.instance.delete(
      `v1/reg-atividades/${activityId}/caldas/${sprayMixtureId}`,
    );
  }

  removeRegExtra(activityId, extraId) {
    return this.instance.delete(
      `v1/reg-atividades/${activityId}/extras/${extraId}`,
    );
  }
}

export default (...params) => new AtividadeFactory(...params);
