export class FuncaoFactory {
  constructor(instance) {
    this.instance = instance;
  }

  getFuncoes(filter) {
    return this.instance.get('/v1/cat-funcoes/', {
      params: filter || null,
    });
  }

  addFuncao(data) {
    return this.instance.post('/v1/cat-funcoes', data);
  }
  
  editFuncao(id, data) {
    return this.instance.put(`/v1/cat-funcoes/${id}`, data);
  }
  
  deleteFuncao(id) {
    return this.instance.delete(`/v1/cat-funcoes/${id}`);
  }
}



export default (...params) => new FuncaoFactory(...params);
