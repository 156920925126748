import React, { useCallback, useState, useContext, createContext } from 'react';

const ModalSafraContext = createContext({});

export const ModalSafraProvider = ({ children }) => {
  const [showModalSafra, setShowModalSafra] = useState(false);

  const openModalSafra = useCallback(() => {
    setShowModalSafra(true);
  }, []);

  const closeModalSafra = useCallback(() => {
    setShowModalSafra(false);
  }, []);

  return (
    <ModalSafraContext.Provider
      value={{
        showModalSafra,
        openModalSafra,
        closeModalSafra,
      }}>
      {children}
    </ModalSafraContext.Provider>
  );
};

export function useSafraModal() {
  const context = useContext(ModalSafraContext);

  if (!context) throw new Error('useSafraModal must be used within an Config Provider');

  return context;
}
