import React, { useContext, createContext } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

const AnimatedToast = styled(ToastContainer)`
  .Toastify__toast--info {
    background-color: 'rgb(51, 102, 255)';
  }
  .Toastify__toast--success {
    background-color: #368467;
  }
  .Toastify__toast--warning {
    background-color: 'rgb(254, 255, 20)';
  }
  .Toastify__toast--error {
    background-color: 'rgb(255, 102, 102)';
  }
`;

const newToastContext = createContext({});

export const NewToastProvider = ({ children }) => {
  const showToast = ({ type, message, autoClose }) => {
    switch (type) {
      case 'success':
        toast.success(message, { autoClose: autoClose ? autoClose : 3000 });
        break;
      case 'warn':
        toast.warn(message, { autoClose: autoClose ? autoClose : 3000 });
        break;
      case 'error':
        toast.error(message, { autoClose: autoClose ? autoClose : 3000 });
        break;
      default:
        toast.info(message, { autoClose: autoClose ? autoClose : 3000 });
    }
  };

  const toastId = React.useRef(null);

  const dismiss = () => toast.dismiss(toastId.current);

  return (
    <newToastContext.Provider value={{ showToast, toast, dismiss }}>
      {children}
      <AnimatedToast position="top-center" />
    </newToastContext.Provider>
  );
};

export function useNewToast() {
  const context = useContext(newToastContext);

  if (!context) throw new Error('useNewToast must be used within an ToastProvider');

  return context;
}
